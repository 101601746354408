import React from "react";

import { useTranslation } from "react-i18next";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { HiOutlineHome } from "react-icons/hi2";

import {
  ComplexGrid,
  ComplexGridColumn, // ComplexGridRow,
} from "@components/data-display/ComplexGrid";
import InputLabel from "@components/data-display/InputLabel";
import Button from "@components/data-entry/Button";
import Checkbox from "@components/data-entry/Checkbox";
import MultiSelect from "@components/data-entry/MultiSelect";
import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import { useModal } from "@components/feedback/Modal";
import Accessories from "@components/icons/Accessories";
import Bags from "@components/icons/Bags";
import ReadyToWear from "@components/icons/ReadyToWear";
import Shoes from "@components/icons/Shoes";
import BottomBar from "@components/layout/BottomBar";
import { OrganizationType } from "@models/Organization";
import {
  MarketCategoryEnum,
  MarketCategoryGroupEnum,
} from "@models/types/enums";
import { GetPartnerBrands } from "@services/api/brands/GetPartnerBrands";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { marketCategoriesGrouped } from "@shared/collections/helpers";

import {
  ShowroomLiteFormData,
  toggleAllMarketCategoriesForAllBrands,
  toggleAllMarketCategoriesForBrand,
  toggleMarketCategoriesForAllBrands,
  toggleMarketCategoryForBrand,
  useShowroomLiteForm,
  validShowroomLiteFormSchema,
} from "./hook";

interface Props {
  defaultValues?: Partial<ShowroomLiteFormData>;
  onBack: () => void;
  onSubmit: (data: ShowroomLiteFormData) => void;
  disableSubmit?: boolean;
  organizationType: OrganizationType;
}

const structureLiteSchema = validShowroomLiteFormSchema
  .pick({
    brandMarketCategories: true,
  })
  .passthrough();

const columns: ComplexGridColumn<MarketCategoryEnum>[] = [
  {
    id: "WOMEN",
    label: MarketCategoryGroupEnum.WOMEN,
    subColumns: [
      {
        id: "Ready-to-wear",
        value: MarketCategoryEnum.WOMEN_READY_TO_WEAR,
        label: <ReadyToWear className="size-5" />,
      },
      {
        id: "Bags",
        value: MarketCategoryEnum.WOMEN_BAGS,
        label: <Bags className="size-5" />,
      },
      {
        id: "Shoes",
        value: MarketCategoryEnum.WOMEN_SHOES,
        label: <Shoes className="size-5" />,
      },
      {
        id: "Accessories",
        value: MarketCategoryEnum.WOMEN_ACCESSORIES,
        label: <Accessories className="size-5" />,
      },
    ],
  },
  {
    id: "MEN",
    label: MarketCategoryGroupEnum.MEN,
    subColumns: [
      {
        id: "Ready-to-wear",
        value: MarketCategoryEnum.MEN_READY_TO_WEAR,
        label: <ReadyToWear className="size-5" />,
      },
      {
        id: "Bags",
        value: MarketCategoryEnum.MEN_BAGS,
        label: <Bags className="size-5" />,
      },
      {
        id: "Shoes",
        value: MarketCategoryEnum.MEN_SHOES,
        label: <Shoes className="size-5" />,
      },
      {
        id: "Accessories",
        value: MarketCategoryEnum.MEN_ACCESSORIES,
        label: <Accessories className="size-5" />,
      },
    ],
  },
  {
    id: "CHILDREN",
    label: MarketCategoryGroupEnum.CHILDREN,
    subColumns: [
      {
        id: "Ready-to-wear",
        label: <ReadyToWear className="size-5" />,
        value: MarketCategoryEnum.CHILDREN_READY_TO_WEAR,
      },
      {
        id: "Bags",
        label: <Bags className="size-5" />,
        value: MarketCategoryEnum.CHILDREN_BAGS,
      },
      {
        id: "Shoes",
        label: <Shoes className="size-5" />,
        value: MarketCategoryEnum.CHILDREN_SHOES,
      },
      {
        id: "Accessories",
        label: <Accessories className="size-5" />,
        value: MarketCategoryEnum.CHILDREN_ACCESSORIES,
      },
    ],
  },
  {
    id: "HOME",
    label: MarketCategoryGroupEnum.HOME,
    subColumns: [
      {
        id: "Home",
        label: <HiOutlineHome className="size-5" />,
        value: MarketCategoryEnum.HOME,
      },
    ],
  },
];

// left corner checkbox renderer
// needs to be outside of the ShowroomLiteStructureForm to avoid defining components during the render
interface TopLeftCornerRendererProps {
  checked: boolean;
  onChange: () => void;
}

const TopLeftCornerRenderer = function ({
  checked,
  onChange,
}: TopLeftCornerRendererProps) {
  return (
    <>
      <Checkbox
        name="all"
        checked={checked}
        onChange={onChange}
        id="all"
        borderColor="border-gray-400"
      />
      <div className="pl-2">Brands</div>
    </>
  );
};
const renderTopLeftCorner = (checked: boolean, onChange: () => void) => (
  <TopLeftCornerRenderer checked={checked} onChange={onChange} />
);

// row header checkbox renderer
interface RowHeaderRendererProps {
  row: { id: string; label: string };
  checked: boolean;
  onChange: () => void;
}

const RowHeaderRenderer = function ({
  row,
  checked,
  onChange,
}: RowHeaderRendererProps) {
  return (
    <>
      <Checkbox
        name={row.id}
        checked={checked}
        onChange={onChange}
        id={row.id}
        borderColor="border-gray-400"
      />
      {row.label}
    </>
  );
};
const renderRowHeader = (
  row: { id: string; label: string },
  checked: boolean,
  onChange: () => void,
) => <RowHeaderRenderer row={row} checked={checked} onChange={onChange} />;

// column header checkbox renderer
interface ColumnHeaderRendererProps {
  column: {
    id: string;
    checked: boolean;
    onChange: () => void;
    label: string;
    value: MarketCategoryEnum | MarketCategoryGroupEnum;
  };
}

const ColumnHeaderRenderer = function ({
  column: { id, checked, onChange, label, value },
}: ColumnHeaderRendererProps) {
  return (
    <>
      <Checkbox
        name={`${id}-${value}`}
        checked={checked}
        onChange={onChange}
        id={`${id}-${value}`}
        borderColor="border-gray-400"
      />
      <div className="pl-2">{label}</div>
    </>
  );
};

const renderColumnHeader = (column: {
  id: string;
  checked: boolean;
  onChange: () => void;
  label: string;
  value: MarketCategoryEnum | MarketCategoryGroupEnum;
}) => <ColumnHeaderRenderer column={column} />;

// cell checkbox renderer
interface CellRendererProps {
  row: { id: string };
  column: { id: string; value: MarketCategoryEnum };
  checked: boolean;
  onChange: () => void;
}

function CellRendererComponent({
  row,
  column,
  checked,
  onChange,
}: CellRendererProps) {
  return (
    <div className="pl-2">
      <Checkbox
        name={`${row.id}-${column.value}`}
        checked={checked}
        onChange={onChange}
        id={`${row.id}-${column.value}`}
        borderColor="border-gray-400"
      />
    </div>
  );
}

const renderCell = (
  row: { id: string },
  column: { id: string; value: MarketCategoryEnum },
  checked: boolean,
  onChange: () => void,
) => (
  <CellRendererComponent
    row={row}
    column={column}
    checked={checked}
    onChange={onChange}
  />
);

export default function ShowroomLiteStructureForm({
  defaultValues,
  onBack,
  onSubmit,
  disableSubmit = false,
  organizationType,
}: Props) {
  const { t } = useTranslation();
  const modalState = useModal();

  const { organization } = useOrganizationAppContext();
  const { data: partnerBrands = [] } = GetPartnerBrands.useHook({
    organizationId: organization.id,
  });

  const { form } = useShowroomLiteForm({
    defaultValues,
    schema: structureLiteSchema,
  });

  const selectedMarketCategories = form.watch("brandMarketCategories");

  // DERIVED STATES

  const subColumnCheckedState = columns.reduce(
    (acc, column) => {
      column.subColumns.forEach((subColumn) => {
        const brandsContainingMarketCategory = selectedMarketCategories.filter(
          (brand) => brand.marketCategories.includes(subColumn.value),
        ).length;

        // Update the acc object to reflect if the subColumn is checked
        acc[subColumn.value] =
          brandsContainingMarketCategory === partnerBrands.length;
      });
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const rowCheckedState = partnerBrands.reduce(
    (acc, row) => {
      const marketCategoriesForBrand =
        selectedMarketCategories.find((brand) => brand.brandId === row.id)
          ?.marketCategories.length || 0;

      // check if the length corresponds to the total number of market categories
      const totalMarketCategories = columns.reduce(
        (total, column) => total + column.subColumns.length,
        0,
      );

      acc[row.id] = marketCategoriesForBrand === totalMarketCategories;
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const allCheckedState = partnerBrands.every((row) => rowCheckedState[row.id]);

  const handleToggleMarketCategory = (
    brandId: string,
    marketCategory: MarketCategoryEnum,
  ) => {
    form.setValue(
      "brandMarketCategories",
      toggleMarketCategoryForBrand(
        selectedMarketCategories,
        brandId,
        marketCategory,
      ),
    );
  };

  const handleToggleColumn = (marketCategories: MarketCategoryEnum[]) => {
    form.setValue(
      "brandMarketCategories",
      toggleMarketCategoriesForAllBrands(
        selectedMarketCategories,
        marketCategories,
        partnerBrands,
      ),
    );
  };

  const handleToggleRow = (brandId: string) => {
    form.setValue(
      "brandMarketCategories",
      toggleAllMarketCategoriesForBrand(
        selectedMarketCategories,
        brandId,
        columns.flatMap(
          (column) => column.subColumns.map((subColumn) => subColumn.value), // all market categories
        ),
      ),
    );
  };

  const handleToggleAll = () => {
    form.setValue(
      "brandMarketCategories",
      toggleAllMarketCategoriesForAllBrands(
        selectedMarketCategories,
        columns.flatMap(
          (column) => column.subColumns.map((subColumn) => subColumn.value), // all market categories
        ),
        partnerBrands,
      ),
    );
  };

  return (
    <>
      <div className="grow p-4 space-y-4 mt-6">
        {organizationType === "BRAND" ? (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <InputLabel
                htmlFor="showroom-name"
                error={form.formState.errors.brandMarketCategories?.message}
              >
                Select the market categories for this showroom
              </InputLabel>
              <MultiSelect
                id="marketCategories"
                placeholder={t(
                  "OrganizationProfile.collections.market-categories-placeholder",
                )}
                value={
                  selectedMarketCategories.length > 0
                    ? selectedMarketCategories[0].marketCategories.map(
                        // we take the first one because there is only one anyway
                        (category) => ({
                          label: t(`Common.market-category.${category}`),
                          value: category,
                        }),
                      )
                    : [] // Return an empty array if there are no selected market categories
                }
                onChange={(selectedOptions) => {
                  if (organization.brand?.id) {
                    form.setValue("brandMarketCategories", [
                      {
                        brandId: organization.brand.id,
                        marketCategories: selectedOptions.map(
                          (option) => option.value as MarketCategoryEnum,
                        ),
                      },
                    ]);
                  }
                }}
                options={marketCategoriesGrouped.map((group) => ({
                  label: t(`Common.market-category-group.${group.label}`),
                  options: group.options.map((option) => ({
                    label: t(`Common.market-category-group.${option.label}`),
                    value: option.value,
                  })),
                }))}
                className="min-w-80 max-w-[40rem]"
                aria-label="select market category"
              />
            </div>
          </div>
        ) : (
          <div className="w-full overflow-x-auto no-scrollbar">
            <CalloutBox type="INFORMATIVE" className="mb-4">
              Here you can select for each brand what market categories they
              will show in their showroom.
              <br />
              Here are the icons for the market categories :
              <ul className="mt-4 space-y">
                <li className="flex items-center gap-2">
                  <ReadyToWear className="size-5" /> Ready-to-wear
                </li>
                <li className="flex items-center gap-2">
                  <Bags className="size-5" /> Bags
                </li>
                <li className="flex items-center gap-2">
                  <Shoes className="size-5" /> Shoes
                </li>
                <li className="flex items-center gap-2">
                  <Accessories className="size-5" /> Accessories
                </li>
                <li className="flex items-center gap-2">
                  <HiOutlineHome className="size-5" /> Home
                </li>
              </ul>
            </CalloutBox>{" "}
            <ComplexGrid
              rows={partnerBrands.map((b) => ({
                id: b.id,
                label: b.name,
                value: b.id,
              }))}
              columns={columns}
              topLeftCornerRenderer={() =>
                renderTopLeftCorner(allCheckedState, handleToggleAll)
              }
              rowHeaderRenderer={(row) =>
                renderRowHeader(row, rowCheckedState[row.value], () =>
                  handleToggleRow(row.value),
                )
              }
              columnHeaderRenderer={(column) =>
                renderColumnHeader({
                  id: column.id,
                  checked:
                    "subColumns" in column
                      ? column.subColumns.every(
                          (sc) => subColumnCheckedState[sc.value],
                        )
                      : subColumnCheckedState[column.value],
                  onChange: () => {
                    handleToggleColumn(
                      "subColumns" in column
                        ? column.subColumns.map((sc) => sc.value)
                        : [column.value],
                    );
                  },
                  label:
                    "subColumns" in column
                      ? t(`Common.market-category-group.${column.label}`) || "" // Add fallback empty string
                      : "",
                  value:
                    "subColumns" in column
                      ? (column.label as MarketCategoryGroupEnum)
                      : (column.value as MarketCategoryEnum),
                })
              }
              cellRenderer={(row, column) =>
                renderCell(
                  row,
                  column,
                  !!selectedMarketCategories
                    .find((b) => b.brandId === row.id)
                    ?.marketCategories.includes(column.value),
                  () => handleToggleMarketCategory(row.value, column.value),
                )
              }
            />
          </div>
        )}
      </div>
      <BottomBar>
        <Button onClick={onBack}>
          <HiChevronLeft />
          {t("Common.previous")}
        </Button>
        <Button
          theme="PRIMARY"
          onClick={modalState.open}
          disabled={disableSubmit}
        >
          {t("Showroom.ShowroomStructureForm.publish-showroom")}
          <HiChevronRight />
        </Button>
      </BottomBar>

      <ConfirmModal
        show={modalState.isOpen}
        title={t("Showroom.ShowroomStructureForm.publish-showroom")}
        onConfirm={() => {
          console.log("Submitting form data:", form.getValues()); // TODO: remove once the validation is done
          return form.handleSubmit(onSubmit)();
        }}
        onCancel={modalState.close}
      >
        <div className="space-y-6">
          <p>
            {t("Showroom.ShowroomStructureForm.publish-showroom-modal-message")}
          </p>
        </div>
      </ConfirmModal>
    </>
  );
}
