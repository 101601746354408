import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { Organization } from "@models/Organization";
import { representativeSchema } from "@models/OrganizationRepresentative";
import { showroomSchema } from "@models/Showroom";
import {
  AccountAppointmentTypeList,
  MarketCategoryList,
} from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey, handleParsingError } from "@services/api/helper";

export namespace GetShowroomsEndpoint {
  export const outputSchema = z.array(
    showroomSchema.extend({
      brandMarketCategories: z.array(
        z
          .object({
            marketCategories: z.array(z.enum(MarketCategoryList)),
          })
          .extend({
            brand: brandSchema.pick({
              id: true,
              name: true,
            }),
          }),
      ),
      addressComponents: z.any(),
      collections: z.array(
        collectionSchema
          .pick({
            id: true,
            name: true,
          })
          .extend({
            brand: brandSchema.pick({
              id: true,
              name: true,
            }),
          }),
      ),
      sellers: z.array(
        representativeSchema
          .pick({
            firstName: true,
            id: true,
            languages: true,
            lastName: true,
            virtualMeetingAppLinks: true,
          })
          .extend({
            appointmentTypes: z.array(z.enum(AccountAppointmentTypeList)),
          }),
      ),
    }),
  );

  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: Organization["id"];
    ongoing?: boolean;
  }

  export function path({ organizationId, ongoing }: HookParams) {
    return `/organizations/${organizationId}/showrooms${ongoing ? "?ongoing" : ""}`;
  }

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) =>
        handleParsingError(outputSchema, r.data, "Failed to fetch showrooms"),
      );
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryFn: () => call(params),
      queryKey: getAPIQueryKey(path(params)),
    });
  }
}
