import React, { useState } from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";

import { BlueRow } from "@components/data-display/BlueRow";
import Button from "@components/data-entry/Button";
import {
  arrayFilterCombineAllOf,
  arrayFilterUndefined,
  arrayFilterUnique,
} from "@helpers/Array";
import { BuyerStatusEnum } from "@models/types/enums";
import countries from "@services/countries";

import { CampaignRow } from "../type";
import CampaignSubRow from "./CampaignSubRow";
import {
  RenderHostName,
  RenderKeyClientIcon,
  RenderModaresaLogo,
  RenderOrderDeadline,
} from "./ColumnRenderers";
import { useBuyerCrmRowHook } from "./hook";

type Props = {
  campaignRows: CampaignRow[];
};

export default function CampaignRowMulti({ campaignRows }: Props) {
  const { t } = useTranslation();

  const rowHook = useBuyerCrmRowHook({ campaignRows });

  // initialize state to track open status for each brand/agency
  const [isRowOpen, setIsRowOpen] = useState<boolean>(false);

  const toggleRow = () => {
    setIsRowOpen((prev) => !prev);
  };

  return (
    <>
      <BlueRow
        key={campaignRows[0].organization?.id}
        colsClass="grid-cols-12"
        className={`relative gap-2 px-5 py-2 ${isRowOpen ? "rounded-b-none" : ""}`}
        marginBetweenRows={isRowOpen ? "mb-0 mt-2" : "mb-2 mt-2"}
      >
        {/* Key account + Host name */}
        <div className="col-span-3 flex items-center gap-2">
          <RenderKeyClientIcon campaignRow={campaignRows[0]} />
          {/* Toggle row */}
          <Button
            theme="NONE"
            type="button"
            onClick={() => {
              toggleRow();
            }}
          >
            {isRowOpen ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
          </Button>
          {/* Host name */}
          <RenderHostName campaignRow={campaignRows[0]} />
          {campaignRows.flatMap((o) => o.brand?.name).filter(arrayFilterUnique)
            .length > 1
            ? t("BuyerAccount.crm.{{count}}-brands", {
                count: campaignRows
                  .flatMap((o) => o.brand?.name)
                  .filter(
                    arrayFilterCombineAllOf(
                      arrayFilterUnique,
                      arrayFilterUndefined,
                    ),
                  ).length,
              })
            : ""}
          <RenderModaresaLogo campaignRow={campaignRows[0]} />
        </div>
        {/* Showroom status */}
        <div className="col-span-2 flex items-center">
          {t("BuyerAccount.crm.{{count}}-booked", {
            count: campaignRows.filter(
              (org) => org.status === BuyerStatusEnum.BOOKED,
            ).length,
          })}
        </div>
        {/* Showroom seasons */}
        <div className="col-span-1 flex items-center">
          {(() => {
            const uniqueSeasonYear = Array.from(
              new Set(
                campaignRows.map(
                  (org) => `${org.showroom?.season} ${org.showroom?.year}`,
                ),
              ),
            );

            return uniqueSeasonYear.length < 2
              ? uniqueSeasonYear.toString()
              : t("BuyerAccount.crm.{{count}}-seasons", {
                  count: uniqueSeasonYear.length,
                });
          })()}
        </div>
        {/* Showroom opening days */}
        <div className="col-span-2 flex items-center">
          {(() => {
            const { openingDays: firstOpeningDays } = campaignRows[0]
              .showroom ?? { openingDays: [] };
            if (!firstOpeningDays?.length) return "-";

            const firstStartDay = new Date(firstOpeningDays[0].day);
            const firstEndDay = new Date(
              firstOpeningDays[firstOpeningDays.length - 1].day,
            );

            const allSame = campaignRows.every(({ showroom }) => {
              if (!showroom?.openingDays?.length) return false;
              const { openingDays } = showroom;
              const startDay = new Date(openingDays[0].day);
              const endDay = new Date(openingDays[openingDays.length - 1].day);
              return (
                startDay.getTime() === firstStartDay.getTime() &&
                endDay.getTime() === firstEndDay.getTime()
              );
            });

            if (allSame) {
              return `${format(firstStartDay, "MMM d")} - ${format(firstEndDay, "MMM d, yyyy")}`;
            }

            // Use a Set to track unique days
            const uniqueDays = new Set<string>();

            campaignRows.forEach(({ showroom }) => {
              showroom?.openingDays?.forEach(({ day }) => {
                uniqueDays.add(new Date(day).toDateString());
              });
            });

            // Calculate the number of unique days
            return t("BuyerAccount.crm.open-for-{{count}}-days", {
              count: uniqueDays.size,
            });
          })()}
        </div>
        {/* Showroom locations */}
        <div className="col-span-1 flex items-center">
          {(() => {
            const uniqueLocations = Array.from(
              new Set(
                campaignRows.flatMap((org) => {
                  const city = org?.showroom?.city;
                  const country =
                    countries[org?.showroom?.countryCode ?? ""]?.name;
                  if (city && country) return `${city}, ${country}`;
                  return city || country || "-";
                }),
              ),
            );

            return uniqueLocations.length < 2
              ? uniqueLocations.toString()
              : t("BuyerAccount.crm.{{count}}-locations", {
                  count: uniqueLocations.length,
                });
          })()}
        </div>
        {/* Showroom order deadlines */}
        <div className="col-span-2 flex items-center">
          {(() => {
            const uniqueOrderDeadlines = Array.from(
              new Set(
                campaignRows.flatMap(
                  (org) => org.showroom?.orderDeadline ?? [],
                ),
              ),
            );

            return uniqueOrderDeadlines.length < 2 ? (
              <RenderOrderDeadline
                campaignRow={campaignRows[0]}
                seasonalInformation={rowHook.seasonalInformation}
                onChange={(date) => {
                  rowHook.setSeasonalInformation({
                    ...rowHook.seasonalInformation,
                    orderDeadline: date.toISOString(),
                  });
                }}
              />
            ) : (
              t("BuyerAccount.crm.{{count}}-deadlines", {
                count: uniqueOrderDeadlines.length,
              })
            );
          })()}
        </div>
        {/* Showroom appointments */}
        <div className="col-span-1 flex items-center">
          {(() => {
            const totalAppointments = campaignRows.flatMap(
              (org) => org.showroom?.appointments ?? [],
            ).length;

            if (totalAppointments === 0) return "-";

            return t("BuyerAccount.crm.{{count}}-appointments", {
              count: totalAppointments,
            });
          })()}
        </div>
      </BlueRow>

      {/* Showroom subrows */}
      {isRowOpen && (
        <div>
          <div className="border">
            {campaignRows.map((campaignRow, i) => (
              <CampaignSubRow key={i} campaignRow={campaignRow} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
