import React from "react";

import { useTranslation } from "react-i18next";
import { HiLifebuoy } from "react-icons/hi2";

import Button from "@components/data-entry/Button";
import useCrisp from "@shared/helpers/crisp";

export default function CrispButton({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { trigger, isCrispOpen } = useCrisp();
  return (
    <Button
      type="button"
      onClick={trigger}
      theme="SECONDARY"
      className={`${className} w-full text-primaryElectricBlue rounded-lg p-1 group flex items-center`}
    >
      <HiLifebuoy />
      {t(isCrispOpen ? "Booking.close-crisp-support" : "Booking.crisp-support")}
    </Button>
  );
}
