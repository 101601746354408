import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { organizationSchema } from "@models/Organization";
import { seasonInformationRequestSchema } from "@models/SeasonInformationRequest";
import { showroomSchema } from "@models/Showroom";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey, handleParsingError } from "@services/api/helper";

export namespace GetBuyerBrands {
  export const outputSchema = z.array(
    brandSchema.pick({ id: true, name: true }).extend({
      seasonInformationRequests: z.array(
        seasonInformationRequestSchema.pick({
          contactId: true,
          brandId: true,
          season: true,
          year: true,
        }),
      ),
      organization: organizationSchema
        .pick({ id: true, name: true })
        .extend({
          showrooms: z.array(
            showroomSchema.pick({
              id: true,
              name: true,
              season: true,
              year: true,
              openingDays: true,
              formattedAddress: true,
            }),
          ),
        })
        .nullish(),
    }),
  );
  export type Output = z.infer<typeof outputSchema>;

  export function path() {
    return `/buyer/brands`;
  }

  export const getQueryKeys = () => getAPIQueryKey(path());

  export function call() {
    return axiosInstance
      .get<Output>(path())
      .then((r) =>
        handleParsingError(
          outputSchema,
          r.data,
          "Failed to fetch buyer brands",
        ),
      );
  }

  export function useHook() {
    return useQuery({
      queryKey: getQueryKeys(),
      queryFn: () => call(),
    });
  }
}
