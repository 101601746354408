import { setDefaultOptions } from "date-fns";
import { enUS, fr as frFR } from "date-fns/locale";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next/initReactI18next";

import en from "@app/lang/en.json";
import fr from "@app/lang/fr.json";

const resources = {
  en,
  fr,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    saveMissing: true,
    fallbackLng: "en",
    supportedLngs: ["en", "fr"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

setDefaultOptions({
  locale: i18n.language === "fr" ? frFR : enUS,
});

export default i18n;
