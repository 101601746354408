import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { Organization } from "@models/Organization";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey, handleParsingError } from "@services/api/helper";

export namespace GetPartnerBrands {
  // @todo add output schema
  export const outputSchema = z.array(
    brandSchema.pick({
      id: true,
      name: true,
      organizationId: true,
    }),
  );

  export type Output = z.infer<typeof outputSchema>;

  // @todo add hook params
  export interface HookParams {
    organizationId: Organization["id"];
  }

  export function path({ organizationId }: HookParams) {
    return `/organizations/${organizationId}/partner-brands`;
  }

  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) =>
        handleParsingError(
          outputSchema,
          r.data,
          "Failed to fetch partner brands",
        ),
      );
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getQueryKeys(params),
      queryFn: () => call(params),
    });
  }
}
