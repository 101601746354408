import { useMutation, useQueryClient } from "@tanstack/react-query";

import axiosInstance from "@services/api/config";

import { GetBuyerCrm } from "./GetBuyerCrm";

export namespace ToggleFavoriteBrand {
  interface MutationParams {
    brandId: string;
  }

  type Params = MutationParams;

  export const path = ({ brandId }: Params) =>
    `/buyer/crm/brand/${brandId}/favorite`;

  export const call = (params: Params) =>
    axiosInstance.put(path(params)).then((res) => res.data);

  export function useHook() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: call,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GetBuyerCrm.getQueryKeys(),
        });
      },
    });
  }
}
