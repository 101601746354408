import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ShowroomLiteForm from "@app/modules/showroom/ShowroomLiteForm";
import BreadCrumbItem from "@onboarding/components/BreadCrumbItem";
import { CreateShowroomLiteEndpoint } from "@services/api/showroom/create-showroom-lite";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

export default function CreateShowroomOnboarding() {
  const { organization } = useOrganizationAppContext();

  const organizationId = organization.id;

  // SHARED STATE
  const navigate = useNavigate();
  const { t } = useTranslation();

  // COMPONENT STATE
  const [submitStatus, setSubmitStatus] = useState<
    "error" | "idle" | "pending" | "success"
  >("idle");

  // MUTATIONS
  const { mutateAsync: createLiteShowroom } =
    CreateShowroomLiteEndpoint.useHook({ organizationId });

  // ACTIONS
  async function submitShowroomForm(data: CreateShowroomLiteEndpoint.Input) {
    try {
      setSubmitStatus("pending");
      await createLiteShowroom({ data });
      setSubmitStatus("success");
      navigate("/showroom");
    } catch (error) {
      toast.error(
        t("Onboarding.CreateShowroomOnboarding.create-showroom-error"),
      );
      setSubmitStatus("error");
    }
  }

  // RENDER
  return (
    <div className="h-screen px-10">
      <div className="flex justify-around pt-12 sticky top-0 right-0 bg-white z-header mb-10">
        <BreadCrumbItem
          step={0}
          currentStep={2}
          label="Organization information"
          page="create-organization"
          allowNavigation={false}
        />
        <BreadCrumbItem
          step={1}
          currentStep={2}
          label="Upload CRM"
          page="upload-crm"
          allowNavigation={false}
        />
        <BreadCrumbItem
          step={2}
          currentStep={2}
          label="Create showroom"
          page="create-showroom"
          allowNavigation={false}
        />
      </div>

      <h1 className="flex items-center gap-2 text-2xl font-bold mb-10">
        {t("Onboarding.CreateShowroomOnboarding.title")}
      </h1>
      <ShowroomLiteForm
        submitStatus={submitStatus}
        organizationType={organization.type}
        defaultValues={{}}
        onSubmit={async (v) => {
          submitShowroomForm({
            ...v,
            formattedAddress: v.address.formattedAddress,
            city: v.address.city,
            countryCode: v.address.countryCode,
            postalCode: v.address.postalCode,
          });
        }}
      />
    </div>
  );
}
