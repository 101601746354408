import React from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { BsDoorClosed } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import {
  HiMinus,
  HiOutlineBookmark,
  HiOutlineBriefcase,
  HiOutlineCalendar,
  HiOutlineClock,
  HiOutlinePencil,
  HiOutlineTrash,
  HiPlus,
} from "react-icons/hi2";
import { TbHanger, TbToolsKitchen2 } from "react-icons/tb";

import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import { DropDown } from "@components/feedback/DropdownMenu";
import { formatMinutes } from "@helpers/Date";
import { Showroom } from "@models/Showroom";
import { AppointmentTypeEnum } from "@models/types/enums";
import ShowroomRemoveSellerForm from "@sales-campaign/components/showroom/remove-seller-form";
import getShowroomOpeningPeriod from "@sales-campaign/helpers/opening-days";
import getTimezoneLabel from "@sales-campaign/helpers/timezone";
import { GetShowroomAppointments } from "@services/api/showroom/get-appointments";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

import ShowroomEditOpeningDaysForm from "../opening-days-form";
import SalesCampaignAddSellerForm from "../seller-form";

type ShowroomCardProps = {
  showroom: Showroom & {
    collections: {
      id: string;
      name: string;
      brand: {
        name: string;
      };
    }[];
    sellers: {
      id: string;
      firstName: string;
      lastName: string;
      appointmentTypes: AppointmentTypeEnum[];
    }[];
  };
  onEdit: (showroom: Showroom) => void;
  allowsFullEdit: boolean;
  onDelete?: (showroom: Showroom) => void;
  extended?: boolean;
  currentPlan: "PRO" | "LITE";
};

export default function ShowroomCard(props: ShowroomCardProps) {
  const {
    organization: { id: organizationId, type: organizationType },
  } = useOrganizationAppContext();
  const { showroom, onEdit, allowsFullEdit, onDelete, extended, currentPlan } =
    props;
  const { t } = useTranslation();

  const {
    data: showroomAppointments = [],
    status: showroomAppointmentsStatus,
  } = GetShowroomAppointments.useHook({
    organizationId,
    showroomId: showroom.id,
  });
  const showroomCanBeDeleted =
    showroomAppointmentsStatus === "success" &&
    showroomAppointments.length === 0;

  const removeSellerDrawer = useDrawer({
    backdrop: true,
    needsConfirmation: false,
  });

  const addSellerDrawer = useDrawer({
    backdrop: false,
    needsConfirmation: false,
  });

  const editOpeningDaysDrawer = useDrawer({
    backdrop: false,
    needsConfirmation: false,
  });

  const hoursFmt = "HH:mm";

  return (
    <div className="px-6 py-4 border border-primaryElectricBlue bg-white rounded-lg">
      <div className="flex justify-between px-6 py-4 rounded-t-lg bg-primaryLightElectricBlue">
        <div className="flex items-center gap-2">
          <BsDoorClosed />
          <h3 className="heading-3">{showroom.name}</h3>
        </div>
        <div className="flex">
          {allowsFullEdit && (
            <Button
              theme="ICON"
              label="edit showroom"
              type="button"
              onClick={() => onEdit(showroom)}
            >
              <HiOutlinePencil />
            </Button>
          )}
          {onDelete && (
            <Button
              renderIf={showroomCanBeDeleted}
              theme="ICON"
              label="delete showroom"
              type="button"
              onClick={() => onDelete(showroom)}
            >
              <HiOutlineTrash className="ml-3 text-statusRedDark" />
            </Button>
          )}
        </div>
      </div>

      {/* ADDRESS */}
      <div className="flex flex-col gap-4 p-6">
        <div className="flex items-center">
          <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
            <HiOutlineLocationMarker />
            {t("SalesCampaign.edit.showroom-structure.address")}:
          </div>
          <div className="w-3/4">{showroom.formattedAddress}</div>
        </div>
        {/* TIMEZONE */}
        {extended && showroom.timezone && (
          <div className="flex items-center">
            <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
              <HiOutlineClock />
              {t("SalesCampaign.edit.overview.timezone")}:
            </div>
            <div className="w-3/4">{getTimezoneLabel(showroom.timezone)}</div>
          </div>
        )}
        {/* OPENING DAYSS */}
        <div className="flex items-center">
          <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
            <HiOutlineCalendar />
            {t("SalesCampaign.edit.showroom-structure.opening-days")}:
          </div>
          <div className="flex items-center justify-between w-3/4">
            {getShowroomOpeningPeriod(showroom.openingDays)}
            {!allowsFullEdit && (
              <Button
                theme="PRIMARY"
                label="edit opening days"
                type="button"
                size="small"
                onClick={editOpeningDaysDrawer.openDrawer}
                className="min-w-[103px]"
              >
                <HiOutlinePencil className="w-4 h-4" />
                {t("SalesCampaign.edit.showroom-structure.edit-opening-days")}
              </Button>
            )}
          </div>
        </div>
        {/* OPENING HOURS */}
        <div className="flex items-center">
          <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
            <HiOutlineClock />
            {t("SalesCampaign.edit.showroom-structure.opening-hours")}:
          </div>
          <div className="w-3/4">{`${format(
            showroom.openingHour,
            hoursFmt,
          )} - ${format(showroom.closingHour, hoursFmt)}`}</div>
        </div>
        {/* LUNCH BREAK */}
        {showroom.lunchBreakStartingHour && showroom.lunchBreakEndingHour && (
          <div className="flex items-center">
            <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
              <TbToolsKitchen2 />
              {t("SalesCampaign.edit.showroom-structure.lunch-break")}:
            </div>
            <div className="w-3/4">{`${format(
              showroom.lunchBreakStartingHour,
              hoursFmt,
            )} - ${format(showroom.lunchBreakEndingHour, hoursFmt)}`}</div>
          </div>
        )}

        {/* COLLECTIONS / MARKET CATEGORIES */}
        <div className="flex items-center">
          <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
            <TbHanger />
            {currentPlan === "PRO"
              ? `${t("SalesCampaign.edit.showroom-structure.collections")}:`
              : `${t("SalesCampaign.edit.showroom-structure.market-categories")}:`}
          </div>
          {currentPlan === "PRO" && (
            <div className="w-3/4">
              {showroom.collections
                .map((collection) =>
                  organizationType === "AGENCY"
                    ? `${collection.brand.name} - ${collection.name}`
                    : collection.name,
                )
                .join(" | ")}
            </div>
          )}
          {currentPlan === "LITE" && (
            <div className="w-3/4">
              <Tooltip
                content={
                  <div className="text-left">
                    {showroom.brandMarketCategories.map((brand) => {
                      const categories = brand.marketCategories
                        .map((category) =>
                          t(`Common.market-category.${category}`),
                        )
                        .join(", ");

                      return (
                        <div key={brand.brand.id}>
                          {organizationType === "AGENCY"
                            ? `${brand.brand.name} - ${categories}`
                            : categories}
                        </div>
                      );
                    })}
                  </div>
                }
                placement="top"
              >
                <div>
                  {showroom.brandMarketCategories.slice(0, 3).map((brand) => {
                    const categories = brand.marketCategories
                      .map((category) =>
                        t(`Common.market-category.${category}`),
                      )
                      .slice(0, organizationType === "AGENCY" ? 2 : 3)
                      .join(", ");

                    const text =
                      organizationType === "AGENCY"
                        ? `${brand.brand.name} - ${categories}`
                        : categories;

                    return (
                      <div key={brand.brand.id}>
                        {text}
                        {brand.marketCategories.length >
                          (organizationType === "AGENCY" ? 2 : 4) && "..."}
                      </div>
                    );
                  })}
                  {showroom.brandMarketCategories.length > 3 && (
                    <div className="text-gray-500 italic">
                      (and {showroom.brandMarketCategories.length - 3}{" "}
                      {showroom.brandMarketCategories.length - 3 === 1
                        ? "other"
                        : "others"}
                      )
                    </div>
                  )}
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        {/* SELLERS */}
        {currentPlan === "PRO" && (
          <div className="flex items-center">
            <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
              <HiOutlineBriefcase />
              {t("SalesCampaign.edit.showroom-structure.sellers")}:
            </div>
            <div className="flex items-center justify-between w-3/4">
              {showroom.sellers.map((seller) => seller.firstName).join(" | ")}
              {!allowsFullEdit && (
                <DropDown
                  trigger={t(
                    "SalesCampaign.edit.showroom-structure.manage-sellers",
                  )}
                  items={[
                    {
                      content: (
                        <>
                          <HiPlus />
                          {t(
                            "SalesCampaign.edit.showroom-structure.add-seller",
                          )}
                        </>
                      ),
                      onSelect: addSellerDrawer.openDrawer,
                    },
                    {
                      content: (
                        <>
                          <HiMinus />
                          {t(
                            "SalesCampaign.edit.showroom-structure.remove-sellers",
                          )}
                        </>
                      ),
                      onSelect: removeSellerDrawer.openDrawer,
                    },
                  ]}
                />
              )}
            </div>
          </div>
        )}
        {/* APPOINTMENT FORMAT */}
        <div className="flex items-center">
          <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
            <HiOutlineBookmark />
            {t("SalesCampaign.edit.showroom-structure.appointments")}:
          </div>
          <div className="w-3/4">
            {showroom.appointmentFormats
              .map((appointmentFormat) =>
                t(`Common.appointment-format.${appointmentFormat}`),
              )
              .join(" | ")}
            {" | "}
            {showroom.appointmentTypes
              .map((appointmentType) =>
                t(`Common.appointment-type.${appointmentType}`),
              )
              .join(" | ")}
          </div>
        </div>
        {extended && showroom.appointmentTypesDuration.length > 0 && (
          <div className="flex items-start">
            <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
              <HiOutlineClock />
              {t("SalesCampaign.edit.showroom-structure.appointments-duration")}
              :
            </div>
            <div className="w-3/4">
              {showroom.appointmentTypesDuration.map((appointmentType) => {
                const duration = formatMinutes(appointmentType.duration);
                if (appointmentType.type === AppointmentTypeEnum.WALKTHROUGH) {
                  return (
                    <div
                      key={JSON.stringify(appointmentType)}
                    >{`${duration} (Walkthrough)`}</div>
                  );
                }
                if (
                  appointmentType.type ===
                  AppointmentTypeEnum.BUYING_APPOINTMENT
                )
                  return (
                    <div
                      key={JSON.stringify(appointmentType)}
                    >{`${duration} (${showroom.collections
                      .filter(
                        (collection) =>
                          collection.id === appointmentType.collectionId,
                      )
                      .map((collection) => collection.name)
                      .join(" | ")})`}</div>
                  );
                return null;
              })}
            </div>
          </div>
        )}
        {showroom.lastAllowedBookingDate !== null && (
          <div className="flex items-center">
            <div className="flex items-center w-1/4 gap-2 text-primaryDarkGrey">
              <HiOutlineCalendar />
              {t("SalesCampaign.edit.showroom-structure.booking-deadline")}:
            </div>
            <div className="w-3/4">
              {format(showroom.lastAllowedBookingDate, "MMM d, yyyy")}
            </div>
          </div>
        )}
      </div>
      <Drawer
        {...addSellerDrawer.props}
        name="seller-add"
        backdrop
        isScrollable={false}
        size="LARGE"
        drawerTitle={
          <h2 className="heading-2">
            {t("SalesCampaign.overview.add-seller-form.title")}
          </h2>
        }
      >
        <SalesCampaignAddSellerForm
          showroom={showroom}
          onSuccess={addSellerDrawer.closeWithoutConfirmation}
        />
      </Drawer>

      <Drawer
        {...removeSellerDrawer.props}
        name="seller-remove"
        backdrop
        isScrollable={false}
        size="LARGE"
        drawerTitle={
          <h2 className="heading-2">
            {t("SalesCampaign.overview.remove-sellers-form.title")}
          </h2>
        }
      >
        <ShowroomRemoveSellerForm
          organizationId={organizationId}
          showroom={showroom}
          onSuccess={removeSellerDrawer.closeWithoutConfirmation}
        />
      </Drawer>

      <Drawer
        {...editOpeningDaysDrawer.props}
        name="opening-days-management"
        backdrop
        isScrollable={false}
        size="LARGE"
        drawerTitle={
          <h2 className="heading-2">
            {t("SalesCampaign.overview.edit-opening-days-form.title")}
          </h2>
        }
      >
        <ShowroomEditOpeningDaysForm
          showroomId={showroom.id}
          onSuccess={editOpeningDaysDrawer.closeWithoutConfirmation}
        />
      </Drawer>
    </div>
  );
}
