import React from "react";

import { CreateShowroomLiteEndpoint } from "@services/api/showroom/create-showroom-lite";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

import ShowroomLiteForm from "./ShowroomLiteForm";
import {
  ValidShowroomLiteFormData,
  defaultFormLiteValues,
} from "./form-lite/hook";

/**
 * This component is responsible for :
 * - storing the showroom creation state in local storage
 * - submitting the form to the create endpoint
 */
export default function ShowroomCreateLITE({
  onSubmit,
}: {
  onSubmit: () => void;
}) {
  const {
    organization: { id: organizationId, type: organizationType },
  } = useOrganizationAppContext();

  const {
    mutateAsync: apiCreateShowroomLite,
    status: createShowroomLiteStatus,
  } = CreateShowroomLiteEndpoint.useHook({
    organizationId,
  });

  const handleCreateShowroom = (data: ValidShowroomLiteFormData) =>
    apiCreateShowroomLite({
      data: {
        name: data.name,
        season: data.season,
        year: data.year,
        timezone: data.timezone,
        formattedAddress: data.address.formattedAddress,
        postalCode: data.address.postalCode,
        city: data.address.city,
        countryCode: data.address.countryCode,
        addressComponents: data.address.addressComponents,
        appointmentFormats: data.appointmentFormats,
        openingDays: data.openingDays,
        openingHour: data.openingHour,
        closingHour: data.closingHour,
        orderDeadline: data.orderDeadline,
        brandMarketCategories: data.brandMarketCategories,
      },
    }).then(() => {
      onSubmit();
    });

  return (
    <ShowroomLiteForm
      defaultValues={defaultFormLiteValues}
      onSubmit={handleCreateShowroom}
      submitStatus={createShowroomLiteStatus}
      organizationType={organizationType}
    />
  );
}
