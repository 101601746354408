import React, {
  HTMLProps,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { HiDotsVertical } from "react-icons/hi";
import {
  useEventListener,
  useIsomorphicLayoutEffect,
  useOnClickOutside,
} from "usehooks-ts";

import Tag from "@components/data-display/Tag";
import Loading from "@components/feedback/Loading";
import { OrganizationPlan } from "@models/Organization";
import { User } from "@models/User";
import { addAccessControlProps } from "@shared/components/access-control";

// value representing a tab
export type TabValue = string | number;

interface UseTabsProps {
  initial?: TabValue;
}

export function useTabs({ initial }: UseTabsProps = { initial: "none" }) {
  const [tab, setTab] = useState(initial);

  return {
    tab,
    setTab,
  };
}

interface TabItem {
  label: string;
  tab: TabValue;
  count?: number;
  aclRoles?: User["role"][];
  disabled?: (step: TabValue | undefined, items: TabsProps["items"]) => boolean;
  loading?: boolean;
  plans?: OrganizationPlan[];
}

export interface TabsProps extends HTMLProps<HTMLDivElement> {
  items: TabItem[];
  isTabActive?: (
    item: TabsProps["items"][number],
    currentTab: TabValue | undefined,
  ) => boolean;
  handleClick: (item: TabsProps["items"][number]) => void;
  tab: TabValue | undefined;
  tabWidth?: number; // width of a tab in px to calculate the number of visible tabs
}

function isTabEqual(
  item: TabsProps["items"][number],
  currentTab: TabValue | undefined,
) {
  return item.tab === currentTab;
}

function TabItemComponent({
  item,
  isActive,
  handleClick,
  items,
  tab,
}: {
  item: TabsProps["items"][number];
  tab: TabsProps["tab"];
  items: TabsProps["items"];
  isActive: boolean;
  handleClick: TabsProps["handleClick"];
}) {
  const enabledClassname = "cursor-pointer";
  const activeClassname = "border-current text-primaryElectricBlue";
  const inactiveClassname = "border-transparent";
  const isLoading = !!item.loading;
  const activityClassName = isActive ? activeClassname : inactiveClassname;
  // the current tab cannot be clicked on
  const isEnabled =
    !isActive && item.disabled ? !item.disabled(tab, items) : true;
  const abilityClassName = isEnabled ? enabledClassname : "text-slate-500";
  return (
    <button
      key={`tab-${item.tab}`}
      disabled={!isEnabled}
      type="button"
      className={`relative p-4 -mb-px border-b-2 flex items-center hover:text-primaryElectricBlue/75 ${activityClassName} ${abilityClassName}`}
      onClick={() => handleClick(item)}
    >
      <span className="pr-2 flex gap-3">
        {item.label}
        {item.count !== undefined && (
          <Tag size="sm" className={`text-sm ${activityClassName}`}>
            {item.count}
          </Tag>
        )}
      </span>
      {isLoading && <Loading type="button" />}
    </button>
  );
}

function TabMenuComponent({
  item,
  isActive,
  handleClick,
  items,
  tab,
}: {
  item: TabsProps["items"][number];
  tab: TabsProps["tab"];
  items: TabsProps["items"];
  isActive: boolean;
  handleClick: TabsProps["handleClick"];
}) {
  const isEnabled =
    !isActive && item.disabled ? !item.disabled(tab, items) : true;

  return (
    <li className={isActive ? "bg-primaryLightestGrey" : ""}>
      <button
        type="button"
        disabled={!isEnabled}
        onClick={() => handleClick(item)}
        className="p-4"
      >
        {item.label}
      </button>
    </li>
  );
}

const TabItemWithAccessControl = addAccessControlProps(TabItemComponent);
const TabMenuWithAccessControl = addAccessControlProps(TabMenuComponent);

export default function Tabs({
  tab,
  items,
  handleClick,
  className,
  isTabActive = isTabEqual,
  tabWidth = 175,
  ...rest
}: TabsProps) {
  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);

  const [visibleTabs, setVisibleTabs] = useState(items);
  const [showDropdown, setShowDropdown] = useState(false);

  const calculateVisibleTabs = useCallback(() => {
    const tabsContainer = tabsContainerRef.current;
    const containerWidth = tabsContainer?.clientWidth || 0;
    const buttonWidth = 56; // minimal width of the dropdown container

    if (containerWidth > 0) {
      const visibleItems: TabItem[] = [];
      let remainingSpace = containerWidth - buttonWidth;

      items.forEach((item) => {
        if (tabWidth && remainingSpace - tabWidth >= 0) {
          visibleItems.push(item);
          remainingSpace -= tabWidth;
        }
      });
      setVisibleTabs(visibleItems);
    } else {
      setVisibleTabs(items);
    }
  }, [items]);

  // set visible tabs on mount
  useIsomorphicLayoutEffect(() => {
    calculateVisibleTabs();
  }, [items]);
  // reset visible tabs on window resize
  useEventListener("resize", calculateVisibleTabs);

  useOnClickOutside(menuContainerRef, () => {
    setShowDropdown(false);
  });

  const hiddenTabs = useMemo(
    () =>
      items.filter((item) => !visibleTabs.map((t) => t.tab).includes(item.tab)),
    [visibleTabs, items],
  );

  return (
    <nav className="relative" ref={tabsContainerRef} {...rest}>
      <div className={`flex cursor-default justify-start ${className}`}>
        {visibleTabs.map((item) => {
          const isActive = isTabActive(item, tab);
          return (
            <TabItemWithAccessControl
              key={`tab-item-${item.tab}`}
              aclRoles={item.aclRoles}
              item={item}
              tab={tab}
              items={items}
              isActive={isActive}
              handleClick={handleClick}
            />
          );
        })}

        {visibleTabs.length < items.length && (
          <div className="relative" ref={menuContainerRef}>
            <button
              type="button"
              className="p-4"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <HiDotsVertical className="w-6 h-6 fill-grey" />
            </button>
            {showDropdown && (
              <div className="absolute top-14 right-0 w-max">
                <ul className="bg-white">
                  {hiddenTabs.map((item) => {
                    const isActive = isTabActive(item, tab);
                    return (
                      <TabMenuWithAccessControl
                        key={`tab-menu-${item.tab}`}
                        aclRoles={item.aclRoles}
                        item={item}
                        tab={tab}
                        items={items}
                        isActive={isActive}
                        handleClick={() => {
                          setShowDropdown(false);
                          handleClick(item);
                        }}
                      />
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}
