import React from "react";

import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FaRegHourglassHalf } from "react-icons/fa6";
import {
  HiOutlineCalendar,
  HiOutlineLocationMarker,
  HiOutlineUsers,
  HiOutlineVideoCamera,
} from "react-icons/hi";

import { ShowroomSeller } from "@models/OrganizationRepresentative";
import { Showroom } from "@models/Showroom";
import getShowroomOpeningPeriod from "@sales-campaign/helpers/opening-days";
import { GetOrderDeadline } from "@services/api/order-deadline/GetOrderDeadline";

interface ShowroomsListProps {
  showrooms: NonEmptyArray<
    Showroom & {
      collections: { brand: { id: string } }[];
      sellers: ShowroomSeller[];
    }
  >;
}

function ShowroomsList(props: ShowroomsListProps) {
  const { showrooms } = props;
  const { t } = useTranslation();

  // extract season and year (assuming they are the same across all showrooms)
  const commonSeason = showrooms[0].season;
  const commonYear = showrooms[0].year;

  // create a set of unique brand IDs across all showrooms
  const uniqueBrandIds = new Set<string>();
  showrooms.forEach((showroom) => {
    showroom.collections.forEach((collection) => {
      uniqueBrandIds.add(collection.brand.id);
    });
  });

  // create a query array with the unique brand IDs and the common season and year
  const queryArray = Array.from(uniqueBrandIds).map((brandId) => ({
    queryKey: GetOrderDeadline.getQueryKeys({
      brandId,
      season: commonSeason,
      year: commonYear,
    }),
    queryFn: () =>
      GetOrderDeadline.call({
        brandId,
        season: commonSeason,
        year: commonYear,
      }),
  }));

  // fetch data using useQueries
  const { data: orderDeadlines, status: orderDeadlineStatus } = useQueries({
    queries: queryArray,
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  const isLoading = orderDeadlineStatus === "pending";

  return (
    <>
      {showrooms.map((showroom) => {
        const virtualMeetingApps = [
          ...new Set(
            showroom.sellers.flatMap((seller) =>
              seller.virtualMeetingAppLinks
                ? Object.keys(seller.virtualMeetingAppLinks)
                : [],
            ),
          ),
        ];

        // display the order deadlines for each unique brandId
        const orderDeadlineJSX = Array.from(uniqueBrandIds)
          .map((brandId) => {
            const matchingOrderDeadline = orderDeadlines?.find(
              (deadline) => deadline.brandId === brandId,
            );

            if (
              matchingOrderDeadline &&
              matchingOrderDeadline.deadline &&
              !isLoading
            ) {
              return (
                <div key={brandId} className="flex items-center gap-2">
                  <FaRegHourglassHalf className="w-2.5 flex ml-0.5" />
                  <p>{t("Shared.order-deadline.order-deadline")}</p>
                  <p className="flex items-center">
                    {format(
                      new Date(matchingOrderDeadline.deadline),
                      "MMM d, yyyy",
                    )}
                  </p>
                </div>
              );
            }
            return null;
          })
          .filter(Boolean);

        return (
          <div
            key={showroom.name}
            className="w-full p-4 rounded-lg bg-primaryLightElectricBlue"
          >
            <div className="font-bold pb-2">{showroom.name}</div>
            <div className="flex items-center gap-2 pb-2">
              <HiOutlineCalendar />
              {getShowroomOpeningPeriod(
                showroom.openingDays,
                "EEEE, MMMM do, yyyy",
              )}
            </div>
            <div className="flex items-center gap-2 pb-2 flex-wrap">
              {showroom.appointmentFormats.includes("IN_PERSON") && (
                <div className="flex items-center gap-2">
                  <HiOutlineUsers />
                  <div>{t("Booking.dashboard.in-person")}</div>
                </div>
              )}
              {showroom.appointmentFormats.includes("IN_PERSON") &&
                showroom.appointmentFormats.includes("VIRTUAL") &&
                " | "}
              {showroom.appointmentFormats.includes("VIRTUAL") && (
                <div className="flex items-center gap-2">
                  <HiOutlineVideoCamera />
                  <div>
                    {virtualMeetingApps
                      .map((virtualMeetingApp) =>
                        t(`Common.virtual-tool.${virtualMeetingApp}`),
                      )
                      .join(", ")}
                  </div>
                </div>
              )}
            </div>
            {showroom.appointmentFormats.includes("IN_PERSON") && (
              <div className="flex items-center gap-2 pb-2">
                <HiOutlineLocationMarker />
                <div>{showroom.formattedAddress}</div>
              </div>
            )}

            {/* render the order deadline */}
            {orderDeadlineJSX}
          </div>
        );
      })}
    </>
  );
}

export default ShowroomsList;
