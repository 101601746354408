import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";

import InputLabel from "@components/data-display/InputLabel";
import AddressAutocomplete from "@components/data-entry/AddressAutocomplete";
import Button from "@components/data-entry/Button";
import ImageUpload from "@components/data-entry/ImageUpload";
import SingleSelect from "@components/data-entry/SingleSelect";
import TextField from "@components/data-entry/TextField";
import { Address } from "@models/types/Address";
import BreadCrumbItem from "@onboarding/components/BreadCrumbItem";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";
import { CreateLiteOrganization } from "@services/api/organization/CreateLiteOrganization";
import { auth } from "@services/authentication/firebase";
import useAuthentication from "@services/authentication/useAuthentication";
import CrispButton from "@shared/components/CrispButton";

export function useSignupLink(type: "brand" | "agency", id?: string) {
  useEffect(() => {
    if (id) {
      localStorage.setItem(`signup-as-${type}`, id || "");
    }
  }, [id]);

  return [
    localStorage.getItem(`signup-as-${type}`),
    () => localStorage.removeItem(`signup-as-${type}`),
  ] as const;
}

export default function CreateOrganizationPage() {
  const { t } = useTranslation();

  const { currentUser } = useAuthentication();

  const [brandId, removeBrandId] = useSignupLink("brand");
  const [agencyId, removeAgencyId] = useSignupLink("agency");

  const [type, setType] = useState<"BRAND" | "AGENCY">(
    agencyId ? "AGENCY" : "BRAND",
  );
  const [name, setName] = useState("");
  const [address, setAddress] = useState<Address | null>(null);
  const [timezone, setTimezone] = useState<string | null>(null);
  const [firstName, setFirstName] = useState(currentUser?.firstName || "");
  const [lastName, setLastName] = useState(currentUser?.lastName || "");
  const [email, setEmail] = useState(currentUser?.email || "");
  const [banner, setBanner] = useState<File | null>(null);

  const { updateUser } = useAuthentication();
  const navigate = useNavigate();

  const { mutateAsync: createOrganization } = CreateLiteOrganization.useHook();
  const { data: brands = [] } = GetAllBrandsEndpoint.useHook();
  const brandsWithoutOrganizationId = useMemo(
    () => brands.filter((brand) => !brand.organizationId),
    [brands],
  );

  const submit = async () => {
    if (!address) {
      toast.error(t("Toast.address-required"));
      return;
    }

    if (!timezone) {
      toast.error(t("Toast.timezone-required"));
      return;
    }

    if (!name) {
      toast.error(t("Toast.organization-name-required"));
      return;
    }

    const data: CreateLiteOrganization.Input =
      type === "BRAND"
        ? {
            type: "BRAND",
            name,
            address,
            timezone,
            brandId: brandId || undefined,
          }
        : {
            type: "AGENCY",
            name,
            address,
            timezone,
            agencyId: agencyId || undefined,
          };

    const organization = await createOrganization({
      data,
    });
    toast.success(
      t("Toast.organization-{{name}}-created", { name: organization.name }),
    );

    removeBrandId();
    removeAgencyId();

    // refresh the user permissions
    const idTokenResult = await auth.currentUser?.getIdTokenResult();
    if (idTokenResult) {
      await updateUser(idTokenResult.token);
    }

    navigate(
      organization.currentPlan === "PRO"
        ? "/crm/accounts"
        : "/onboarding/upload-crm",
    );
  };

  return (
    <div className="h-screen px-10">
      <div className="flex justify-around pt-12 sticky top-0 right-0 bg-white z-header mb-10">
        <BreadCrumbItem
          step={0}
          currentStep={0}
          label="Organization information"
          page="create-organization"
          allowNavigation={false}
        />
        <BreadCrumbItem
          step={1}
          currentStep={0}
          label="Upload CRM"
          page="upload-crm"
          allowNavigation={false}
        />
        <BreadCrumbItem
          step={2}
          currentStep={1}
          label="Create showroom"
          page="create-showroom"
          allowNavigation={false}
        />
      </div>

      <h1 className="flex items-center gap-2 text-2xl font-bold mb-10">
        {t("CreateOrganizationPage.title")}
      </h1>

      <h5 className="text-lg font-bold">
        {t("Onboarding.CreateOrganizationPage.global-information")}
      </h5>
      <hr className="mb-6 mt-2" />
      <div className="grid grid-cols-12 gap-4 mb-10">
        <div className="col-span-3 flex flex-col gap-4">
          <div>
            <InputLabel>
              {t("Onboarding.CreateOrganizationPage.organization-type")}
            </InputLabel>
            <SingleSelect
              value={{
                label: type === "BRAND" ? "Brand" : "Agency",
                value: type,
              }}
              onChange={(o) => setType(o?.value as "BRAND" | "AGENCY")}
              options={[
                { label: "Brand", value: "BRAND" },
                { label: "Agency", value: "AGENCY" },
              ]}
            />
          </div>
          <div>
            <InputLabel>
              {t("Onboarding.CreateOrganizationPage.organization-name")}
            </InputLabel>
            <CreatableSelect
              noOptionsMessage={() =>
                t("BuyerAccount.crm.create-brand.type-brand-name")
              }
              placeholder={t(
                "Onboarding.CreateOrganizationPage.select-organisation",
              )}
              options={brandsWithoutOrganizationId.map((brand) => ({
                label: brand.name,
                value: brand.id,
              }))}
              onChange={(o) => {
                setName(o?.label || "");
              }}
              onCreateOption={(o) => {
                setName(o);
              }}
              value={name ? { label: name, value: name } : null}
            />
          </div>
          <div>
            <InputLabel>
              {t("Onboarding.CreateOrganizationPage.organization-address")}
            </InputLabel>
            {address && (
              <p className="text-sm text-gray-500 pb-1">
                {t("Onboarding.CreateOrganizationPage.timezone-is-{{tz}}", {
                  tz: timezone,
                })}
              </p>
            )}
            <AddressAutocomplete
              defaultValue={null}
              onChange={(add, tz) => {
                if (add) {
                  setAddress(add);
                  setTimezone(tz || null);
                } else {
                  setAddress(null);
                  setTimezone(null);
                }
              }}
            />
          </div>
        </div>
        <div className="col-span-3">
          <ImageUpload
            imageClassNames="w-[600px] h-[200px]"
            newImage={banner}
            existingImage={null}
            onChange={setBanner}
            labels={{
              upload: t(
                "Organization.OrganizationInformationForm.banner-upload",
              ),
              replace: t(
                "Organization.OrganizationInformationForm.banner-replace",
              ),
              delete: t(
                "Organization.OrganizationInformationForm.banner-delete",
              ),
              subtitle: t(
                "Organization.OrganizationInformationForm.banner-size",
              ),
            }}
            name="bannerFile"
            alt="Organization banner"
          />
        </div>
      </div>

      <h5 className="text-lg font-bold">
        {t("Onboarding.CreateOrganizationPage.main-contact-information")}
      </h5>
      <hr className="mb-6 mt-2" />
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-2">
          <InputLabel>
            {t("Onboarding.CreateOrganizationPage.first-name")}
          </InputLabel>
          <TextField
            id="firstName"
            placeholder={t(
              "Onboarding.CreateOrganizationPage.name-placeholder",
            )}
            value={firstName}
            onChange={setFirstName}
          />
        </div>
        <div className="col-span-2">
          <InputLabel>
            {t("Onboarding.CreateOrganizationPage.last-name")}
          </InputLabel>
          <TextField
            id="lastName"
            placeholder={t(
              "Onboarding.CreateOrganizationPage.last-name-placeholder",
            )}
            value={lastName}
            onChange={setLastName}
          />
        </div>
        <div className="col-span-4">
          <InputLabel>
            {t("Onboarding.CreateOrganizationPage.email")}
          </InputLabel>
          <TextField
            id="email"
            placeholder={t(
              "Onboarding.CreateOrganizationPage.email-placeholder",
            )}
            value={email}
            onChange={setEmail}
          />
        </div>
      </div>

      <div className="flex justify-end">
        <Button
          theme="PRIMARY"
          type="button"
          onClick={submit}
          className="mt-4 r-0"
        >
          {t("Onboarding.CreateOrganizationPage.submit")}
        </Button>
      </div>
      <div className="fixed bottom-4 left-4">
        <CrispButton />
      </div>
    </div>
  );
}
