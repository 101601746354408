import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { addHours, compareAsc, getYear } from "date-fns";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { toggleValue } from "@helpers/Array";
import { generateTime } from "@helpers/Date";
import { openingDayHydratingSchema } from "@models/Showroom";
import { Address, addressSchema } from "@models/types/Address";
import {
  AppointmentFormat,
  AppointmentFormatList,
  MarketCategoryEnum,
  ShowroomSeason,
  ShowroomSeasonList,
  appointmentFormatSchema,
} from "@models/types/enums";
import { useEnhancedForm } from "@shared/hooks/useEnhancedForm";

// schema for default values
export const showroomLiteFormSchema = z.object({
  // global information
  name: z.string(),
  season: z.enum(ShowroomSeasonList).nullable(),
  year: z.number().nullable(),
  address: addressSchema.nullable(),
  timezone: z.string().nullable(),
  openingDays: z.array(openingDayHydratingSchema),
  openingHour: z.date(),
  closingHour: z.date(),
  orderDeadline: z.date().nullable(),
  appointmentFormats: z.array(appointmentFormatSchema),

  // structure
  brandMarketCategories: z.array(
    z.object({
      brandId: z.string(),
      marketCategories: z.array(z.nativeEnum(MarketCategoryEnum)),
    }),
  ),
});

export type ShowroomLiteFormData = z.infer<typeof showroomLiteFormSchema>;

// schema for form data
export const validShowroomLiteFormSchema = z.object({
  // global information
  name: z.string().min(1, { message: "SalesCampaign.showroom.error.required" }),
  season: z.enum(ShowroomSeasonList),
  year: z.number(),
  address: addressSchema
    // to avoid the "Expected object, received null" error
    .nullable()
    .refine((val) => val !== null, {
      message: "SalesCampaign.showroom.error.required",
    }),
  timezone: z.string().min(1),
  openingDays: z
    .array(
      z.object({
        day: z.date(),
        keyAccountsOpeningHour: z.date().nullable(),
        keyAccountsClosingHour: z.date().nullable(),
        customOpeningHour: z.date().nullable().default(null),
        customClosingHour: z.date().nullable().default(null),
      }),
    )
    .nonempty({ message: "SalesCampaign.showroom.error.required" }),
  openingHour: z.date(),
  closingHour: z.date(),
  orderDeadline: z
    .date()
    // to avoid the "Expected object, received null" error
    .nullable()
    // refine the message to be more clear
    .refine((val) => val !== null, {
      message: "SalesCampaign.showroom.error.required",
    }),
  appointmentFormats: z.array(appointmentFormatSchema).nonempty(),

  // structure
  brandMarketCategories: z
    .array(
      z.object({
        brandId: z.string(),
        marketCategories: z.array(z.nativeEnum(MarketCategoryEnum)),
      }),
    )
    .min(1, "At least one market category must be selected"),
});

export type ValidShowroomLiteFormData = z.infer<
  typeof validShowroomLiteFormSchema
>;

function getCurrentSeason(): ShowroomSeason {
  const currentMonth = new Date().getMonth();
  if (currentMonth < 6) {
    return "SS";
  }
  return "AW";
}

export const defaultFormLiteValues: ShowroomLiteFormData = {
  name: "",
  season: getCurrentSeason(),
  year: getYear(new Date()),
  address: null,
  timezone: "",
  appointmentFormats: [AppointmentFormatList[0]],
  openingDays: [],
  openingHour: generateTime(9, 0),
  closingHour: generateTime(19, 0),
  orderDeadline: null,

  // structure
  brandMarketCategories: [],
};
interface FormProps<Data> {
  onSave?: (d: Data) => void;
  onSubmit?: (d: Data) => void;
}

// check a cell
export function toggleMarketCategoryForBrand(
  brandMarketCategories: ShowroomLiteFormData["brandMarketCategories"],
  brandId: string,
  marketCategory: MarketCategoryEnum,
): ShowroomLiteFormData["brandMarketCategories"] {
  const existingBrand = brandMarketCategories.find(
    (brand) => brand.brandId === brandId,
  );
  // if the brand is in the list
  if (existingBrand) {
    // if the market category is in the brand
    if (existingBrand.marketCategories.includes(marketCategory)) {
      const updatedBrands = brandMarketCategories
        .map((currentBrand) => {
          if (currentBrand.brandId === brandId) {
            // if the brand has no market categories, remove the brand from the list
            if (existingBrand.marketCategories.length === 1) {
              return null; // mark for removal
            }
            // remove the market category from the brand
            return {
              ...currentBrand,
              marketCategories: currentBrand.marketCategories.filter(
                (category) => category !== marketCategory,
              ),
            };
          }
          return currentBrand;
        })
        .filter(
          (
            brand,
          ): brand is {
            brandId: string;
            marketCategories: MarketCategoryEnum[];
          } => brand !== null,
        ); // filter out nulls

      return updatedBrands; // return the updated list
    }

    // if the market category is not in the brand, add it
    return brandMarketCategories.map((currentBrand) => {
      if (currentBrand.brandId === brandId) {
        return {
          ...currentBrand,
          marketCategories: [...currentBrand.marketCategories, marketCategory],
        };
      }
      return currentBrand;
    });
  }

  // if the brand is not in the list, add it
  return [
    ...brandMarketCategories,
    { brandId, marketCategories: [marketCategory] },
  ];
}

// check a column/subcolumn
export function toggleMarketCategoriesForAllBrands(
  brandMarketCategories: ShowroomLiteFormData["brandMarketCategories"],
  marketCategories: MarketCategoryEnum[],
  allBrands: { id: string; name: string; organizationId: string | null }[],
) {
  // if the market category is checked everywhere: uncheck it everywhere
  const isCheckedForAllBrands =
    brandMarketCategories.filter((brand) =>
      Array.isArray(marketCategories)
        ? marketCategories.every((category) =>
            brand.marketCategories.includes(category),
          )
        : brand.marketCategories.includes(marketCategories),
    ).length === allBrands.length;

  if (isCheckedForAllBrands) {
    return brandMarketCategories.map((brand) => ({
      ...brand,
      marketCategories: brand.marketCategories.filter(
        (category) => !marketCategories.includes(category),
      ),
    }));
  }

  // if the market category is not checked everywhere: check it everywhere
  const updatedBrands = allBrands.map((row) => {
    const existingBrand = brandMarketCategories.find(
      (brand) => brand.brandId === row.id,
    );

    if (existingBrand) {
      return {
        ...existingBrand,
        marketCategories: [
          ...new Set([
            ...existingBrand.marketCategories,
            ...(Array.isArray(marketCategories)
              ? marketCategories
              : [marketCategories]),
          ]),
        ],
      };
    }

    return {
      brandId: row.id,
      marketCategories: Array.isArray(marketCategories)
        ? marketCategories
        : [marketCategories],
    };
  });

  return updatedBrands;
}

// check a brand (row)
export function toggleAllMarketCategoriesForBrand(
  brandMarketCategories: ShowroomLiteFormData["brandMarketCategories"],
  brandId: string,
  allExistingMarketCategories: MarketCategoryEnum[],
) {
  const existingBrand = brandMarketCategories.find(
    (brand) => brand.brandId === brandId,
  );

  const brandIsCheckedEverywhere =
    existingBrand &&
    allExistingMarketCategories.every((category) =>
      existingBrand.marketCategories.includes(category),
    );

  // if the brand is checked everywhere : uncheck it everywhere
  if (brandIsCheckedEverywhere) {
    return brandMarketCategories.filter((brand) => brand.brandId !== brandId);
  }

  // if a brand is not checked everywhere : check it everywhere
  const newBrandData = {
    brandId,
    marketCategories: allExistingMarketCategories,
  };

  return existingBrand
    ? brandMarketCategories.map((brand) =>
        brand.brandId === brandId ? newBrandData : brand,
      )
    : [...brandMarketCategories, newBrandData];
}

// check all
export function toggleAllMarketCategoriesForAllBrands(
  brandMarketCategories: ShowroomLiteFormData["brandMarketCategories"],
  allExistingMarketCategories: MarketCategoryEnum[],
  allBrands: { id: string; name: string; organizationId: string | null }[],
) {
  const allBrandsAreCheckedEverywhere =
    brandMarketCategories.length === allBrands.length &&
    allBrands.every((brand) => {
      const brandCategories = brandMarketCategories.find(
        (b) => b.brandId === brand.id,
      )?.marketCategories;
      return (
        brandCategories &&
        allExistingMarketCategories.every((category) =>
          brandCategories.includes(category),
        )
      );
    });

  // if all brands are checked everywhere : uncheck all brands everywhere
  if (allBrandsAreCheckedEverywhere) {
    return [];
  }

  // if all brands are not checked everywhere : check all brands everywhere
  return allBrands.map((brand) => ({
    brandId: brand.id,
    marketCategories: allExistingMarketCategories,
  }));
}
interface UseShowroomLiteFormProps extends FormProps<ShowroomLiteFormData> {
  initialStep?: number;
  defaultValues?: Partial<ShowroomLiteFormData>;
  schema: z.ZodObject<any>;
}

export function useShowroomLiteForm({
  defaultValues,
  initialStep = 0,
  onSave = () => {},
  schema,
}: UseShowroomLiteFormProps) {
  const [formStep, setFormStep] = useState(initialStep);

  // fill form data with showroom
  const form = useForm<ShowroomLiteFormData>({
    defaultValues: {
      ...defaultFormLiteValues,
      ...defaultValues,
    },
    resolver: zodResolver(schema),
  });

  const { validate } = useEnhancedForm(form);

  const saveStep = (d: ShowroomLiteFormData, step: number) => {
    setFormStep(step);
    onSave(d);
  };

  const { closingHour, appointmentFormats } = form.watch();

  const setName = (n: string) => form.setValue("name", n);

  const setSeasonYear = (season: ShowroomSeason, year: number) => {
    form.setValue("season", season);
    form.setValue("year", year);
  };

  const setAddress = (a: Address | null) => form.setValue("address", a);

  const setTimezone = (tz: string) => form.setValue("timezone", tz);

  const setOpeningDays = (date: Date | Date[] | null) => {
    let newOpeningDays: ShowroomLiteFormData["openingDays"] = [];

    if (Array.isArray(date)) {
      date.sort(compareAsc);
      newOpeningDays = date.map((d) => ({
        day: d,
        customClosingHour: null,
        customOpeningHour: null,
        keyAccountsClosingHour: null,
        keyAccountsOpeningHour: null,
      }));
    } else if (date !== null) {
      newOpeningDays = [
        {
          day: date,
          customClosingHour: null,
          customOpeningHour: null,
          keyAccountsClosingHour: null,
          keyAccountsOpeningHour: null,
        },
      ];
    }
    validate.array.nonEmpty("openingDays", newOpeningDays);

    form.setValue("openingDays", newOpeningDays);
  };

  const setOrderDeadline = (newOrderDeadline: Date | null) => {
    form.setValue("orderDeadline", newOrderDeadline);
  };
  const setOpeningHour = (newOpeningHour: Date) => {
    if (newOpeningHour >= closingHour) {
      form.setValue("closingHour", addHours(newOpeningHour, 1));
    } else {
      form.setValue("closingHour", closingHour); // refreshes the input
    }
    form.setValue("openingHour", newOpeningHour);
  };

  const setClosingHour = (newClosingHour: Date) =>
    form.setValue("closingHour", newClosingHour);

  const toggleAppointmentFormat = (
    checked: boolean,
    format: AppointmentFormat,
  ) => {
    const value = toggleValue(checked, format, appointmentFormats);
    validate.array.nonEmpty("appointmentFormats", value);
    form.setValue("appointmentFormats", value);
  };

  return {
    form,
    formStep,
    saveStep,
    setFormStep,
    setName,
    setAddress,
    setTimezone,
    setSeasonYear,
    setOpeningDays,
    setOrderDeadline,
    setOpeningHour,
    setClosingHour,
    toggleAppointmentFormat,
  };
}

export default {
  useShowroomLiteForm,
};
