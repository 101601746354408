import React, { useRef, useState } from "react";

import { Portal, Transition } from "@headlessui/react";
import { IoIosClose } from "react-icons/io";
import { useOnClickOutside } from "usehooks-ts";

import { fadeInOutProps } from "@components/transitions/FadeInOut";
import usePopup from "@helpers/Popup";
import { Address } from "@models/types/Address";
import LogService from "@services/log/service";

import addressAutocompleteResultMapper, {
  PlacesAutocompleteSuggestion,
  getDetails,
  getTimezone,
  usePlacesAutocomplete,
} from "./AddressAutocompleteResultMapper";

interface AddressAutocompleteProps {
  placeholder?: string;
  defaultValue: Address | null;
  className?: string;
  id?: string;
  onChange: (
    address: Address | undefined,
    timezone: string | undefined,
  ) => void;
  readonly?: boolean;
}

function AddressAutocomplete(props: AddressAutocompleteProps) {
  const ref = useRef(null);
  const {
    placeholder = "",
    id,
    defaultValue,
    className = "",
    onChange,
    readonly = false,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(
    defaultValue?.formattedAddress || "",
  );
  const popup = usePopup({
    placement: "bottom",
    sameWidth: true,
    hasOffset: [0, 0],
  });
  const { suggestions, clearSuggestions } = usePlacesAutocomplete(value);

  useOnClickOutside(popup.popupElement, () => {
    if (isEditing && suggestions.length > 0) {
      setValue(defaultValue?.formattedAddress || "");
      clearSuggestions();
      setIsEditing(false);
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEditing) {
      setIsEditing(true);
    }
    setValue(e.target.value);
  };

  const handleSelect = async (suggestion: PlacesAutocompleteSuggestion) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    clearSuggestions();

    try {
      const details = await getDetails(suggestion.placePrediction.placeId);

      const newAddress = addressAutocompleteResultMapper(details);

      const timezone = await getTimezone(
        details.location.latitude,
        details.location.longitude,
      );
      setValue(newAddress.formattedAddress);
      onChange(newAddress, timezone.timeZoneId);
    } catch (err: any) {
      LogService.error("error when retrieving address info");
      LogService.error(err);
      setValue("invalid location");
      onChange(undefined, undefined);
    }
    setIsEditing(false);
  };

  const clearField = () => {
    setValue("");
    onChange(undefined, undefined);
  };

  return (
    <div ref={ref} className="relative w-full h-full text-gray-700">
      <div
        className={`flex items-center rounded-lg form-input ${
          readonly ? "bg-primaryLightestGrey" : ""
        } ${className}`}
        ref={popup.setReferenceElement}
      >
        <input
          type="text"
          id={id}
          title="search address here"
          value={value}
          onChange={handleInputChange}
          readOnly={readonly}
          placeholder={placeholder || "start typing your address here"}
          className={`w-full h-6 outline-none ${
            readonly ? "bg-primaryLightestGrey" : ""
          }`}
        />
        {!readonly && (
          <button
            type="button"
            title="clear field"
            disabled={readonly}
            className="cursor-pointer"
            onClick={clearField}
          >
            <IoIosClose className="w-8 h-8 text-gray-400" />
          </button>
        )}
      </div>
      <Portal as="div" className="fixed z-select">
        <div
          ref={popup.setPopupElement}
          style={popup.styles.popper}
          {...popup.attributes}
        >
          <Transition
            show={isEditing && suggestions.length > 0}
            {...fadeInOutProps}
          >
            <nav className="bg-white border border-gray-300 rounded top-10 w-full overflow-hidden max-h-44">
              <ul>
                {suggestions.map((suggestion, index) => {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  const { placePrediction } = suggestion;
                  return (
                    <li
                      className="w-full p-2 cursor-pointer hover:bg-gray-100"
                      tabIndex={index}
                      key={placePrediction.placeId}
                    >
                      <button
                        type="button"
                        onClick={() => handleSelect(suggestion)}
                        title={`select address ${placePrediction.text.text}`}
                      >
                        {placePrediction.text.text}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Transition>
        </div>
      </Portal>
    </div>
  );
}

export default AddressAutocomplete;
