import React, { useState } from "react";

import { cva } from "class-variance-authority";
import Gravatar from "react-gravatar";
import { useTranslation } from "react-i18next";
import { HiCalendar } from "react-icons/hi2";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import modaresaSmallLogo from "@app/assets/logos/moda-resa-small-logo.png";
import modaresaLogo from "@app/assets/logos/moda-resa.svg";
import BuyerOnboardingDone from "@app/modules/buyer/BuyerProfileGuard";
import HTML from "@components/html";
import { User } from "@services/authentication/authentication.types";
import useAuthentication from "@services/authentication/useAuthentication";
import CrispButton from "@shared/components/CrispButton";
import { withBuyerOnly } from "@shared/components/guards/authentication";
import { fullName } from "@shared/helpers/formatters";

const navItem = cva(
  "block rounded-lg text-sm font-medium flex items-center hover:bg-gray-100 gap-x-2",
  {
    variants: {
      folded: {
        false: "px-4 py-2 justify-left",
        true: "w-10 h-10 p-1 justify-center",
      },
      active: {
        false: "text-gray-700",
        true: "bg-gray-200 text-gray-700 hover:bg-gray-300",
      },
    },
  },
);

const navBar = cva(
  "flex h-screen flex-col justify-between border-e bg-white md:col-span-3 lg:col-span-3 xl:col-span-2",
  {
    variants: {
      folded: {
        true: "w-24",
        false: "w-64",
      },
    },
  },
);

function BuyerLayout({ user }: Readonly<{ user: User }>) {
  const navigate = useNavigate();
  const { signOut, stopImpersonation, impersonation } = useAuthentication();
  const { t } = useTranslation();
  const location = useLocation().pathname;
  const [folded] = useState(false);
  return (
    <div className="w-full h-full flex flex-row">
      <HTML.Div className={navBar({ folded })}>
        <div className="flex flex-col items-stretch py-6 px-2">
          <HTML.Img if={folded} src={modaresaSmallLogo} alt="modaresa Logo" />
          <HTML.Img
            if={!folded}
            className="h-5"
            alt="modaresa logo"
            src={modaresaLogo}
          />
          <ul className="mt-6 space-y-1">
            <li>
              <Link
                to="/buyer/campaigns"
                className={navItem({
                  active: location === "/buyer/campaigns",
                  folded,
                })}
              >
                <HiCalendar className="w-4 h-4" />
                {!folded && t("BuyerLayout.campaigns")}
              </Link>
            </li>
            <li>
              <CrispButton className="w-full hover:bg-primaryElectricBlue hover:text-white" />
            </li>
          </ul>
        </div>
        <div className="sticky inset-x-0 bottom-0 border-t border-gray-100">
          <span className="flex items-center gap-2 bg-white p-4 hover:bg-gray-50">
            <Gravatar className="rounded-full w-8" email={user.email} />
            <HTML.Div if={!folded} className="max-w-full overflow-hidden">
              <p className="text-xs">
                <strong className="block font-medium truncate max-w-full">
                  {fullName(user)}
                </strong>
                <span className="block truncate max-w-full">{user.email}</span>
              </p>
            </HTML.Div>
          </span>
          <div className="flex">
            {impersonation && (
              <button
                type="button"
                onClick={() => {
                  stopImpersonation();
                  navigate("/");
                }}
                className="w-full bg-gray-400 hover:bg-red-500 text-white font-medium text-sm py-2"
              >
                {t("Stop impersonation")}
              </button>
            )}
            <button
              type="button"
              onClick={signOut}
              className="w-full bg-gray-400 hover:bg-red-500 text-white font-medium text-sm py-2"
            >
              {t("Sign out")}
            </button>
          </div>
        </div>
      </HTML.Div>
      <div className="w-full">
        <BuyerOnboardingDone>
          <Outlet />
        </BuyerOnboardingDone>
      </div>
    </div>
  );
}

export default withBuyerOnly(BuyerLayout);
