import React from "react";

export interface ComplexGridRow<Value> {
  id: string; // used for keys
  label: string;
  value: Value;
}

export interface ComplexGridSubColumn<Value> {
  id: string; // used for keys
  label: React.ReactNode;
  value: Value;
}

export interface ComplexGridColumn<Value> {
  id: string; // used for keys
  label: string;
  subColumns: ComplexGridSubColumn<Value>[];
}

interface Props<ColumnValue, RowValue> {
  columns: ComplexGridColumn<ColumnValue>[];
  rows: ComplexGridRow<RowValue>[];
  topLeftCornerRenderer?: () => React.ReactNode;
  rowHeaderRenderer?: (row: ComplexGridRow<RowValue>) => React.ReactNode;
  columnHeaderRenderer?: (
    column: ComplexGridColumn<ColumnValue> | ComplexGridSubColumn<ColumnValue>,
  ) => React.ReactNode;
  cellRenderer?: (
    row: ComplexGridRow<RowValue>,
    column: ComplexGridSubColumn<ColumnValue>,
  ) => React.ReactNode;
}

export function ComplexGrid<ColumnValue, RowValue>({
  columns,
  rows,
  topLeftCornerRenderer = () => null,
  rowHeaderRenderer = (row: ComplexGridRow<RowValue>) => row.label,
  columnHeaderRenderer = (
    column: ComplexGridColumn<ColumnValue> | ComplexGridSubColumn<ColumnValue>,
  ) => column.label,
  cellRenderer = () => null,
}: Props<ColumnValue, RowValue>) {
  return (
    <table className="border border-primaryElectricBlue border-separate border-spacing-0 rounded-lg">
      {/* table header */}
      <thead>
        <tr>
          <th
            colSpan={1}
            className="border-r border-r-primaryElectricBlue py-2 px-4 w-48 min-w-[130px] bg-primaryLightElectricBlue rounded-tl-lg"
          >
            <div className="flex items-center gap-2">
              {topLeftCornerRenderer()}
            </div>
          </th>
          {/* MARKET CATEGORY COLUMNS */}
          {columns.map((column) => (
            <th
              key={column.label}
              colSpan={column.subColumns.length}
              className={`border-b border-b-gray-300 p-3 bg-primaryLightElectricBlue ${column.label === "HOME" ? "rounded-tr-lg" : "border-r  border-r-primaryElectricBlue"}`}
            >
              <div className="flex items-center justify-center gap-1">
                {columnHeaderRenderer(column)}
              </div>
            </th>
          ))}
        </tr>
        <tr>
          <th className="border-r border-primaryElectricBlue border-b border-b-gray-300 bg-primaryLightElectricBlue" />
          {/* MARKET CATEGORY SUBCOLUMNS */}
          {columns.map((column) =>
            column.subColumns.map((subColumn, index) => (
              <th
                key={subColumn.id}
                className={`p-2 bg-primaryLightElectricBlue border ${
                  index === column.subColumns.length - 1
                    ? "border-r-primaryElectricBlue"
                    : ""
                }`}
              >
                <div className="flex items-center justify-center">
                  {columnHeaderRenderer(subColumn)}
                  <div>{subColumn.label}</div>
                </div>
              </th>
            )),
          )}
        </tr>
      </thead>

      {/* table body */}
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            <td
              colSpan={1}
              className="border-r border-r-primaryElectricBlue py-1 px-4 border-b border-b-gray-300"
            >
              <div className="flex items-center gap-2">
                {rowHeaderRenderer(row)}
              </div>
            </td>
            {/* MARKET CATEGORY CELLS */}
            {columns.map((column) =>
              column.subColumns.map((subColumn) => (
                <td key={`${row.id}-${subColumn.id}`} className="border">
                  <div>{cellRenderer(row, subColumn)}</div>
                </td>
              )),
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
