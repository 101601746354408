import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { HiOutlineSearch } from "react-icons/hi";
import { HiArrowDown, HiArrowUp, HiOutlinePlusCircle } from "react-icons/hi2";
import { groupBy } from "remeda";

import MultipleBrandForm from "@app/modules/brand/MultipleBrandForm";
import CampaignRowMulti from "@app/modules/buyer/campaigns/CampaignRowMulti";
import CampaignRowSingle from "@app/modules/buyer/campaigns/CampaignRowSingle";
import { extractCampaignRowDataToDisplay } from "@app/modules/buyer/campaigns/ColumnRenderers";
import { PageHeader, PageTitle } from "@components/data-display/Page";
import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import { GetBuyerCrm } from "@services/api/buyer-crm/GetBuyerCrm";
import { withBuyerOnly } from "@shared/components/guards/authentication";
import { translateBuyerStatus } from "@shared/helpers/translater";

function BuyerCampaignsPage() {
  const { t } = useTranslation();
  const inviteBrandsDrawer = useDrawer();

  const { data: brandShowrooms = [] } = GetBuyerCrm.useHook();

  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState<
    "season" | "deadline" | undefined
  >();
  const [sortDirection, setSortDirection] = useState<
    "asc" | "desc" | undefined
  >();

  // cycle through asc -> desc -> undefined
  const toggleSort = (field: "season" | "deadline") => {
    if (sortField === field) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
      } else if (sortDirection === "desc") {
        setSortDirection(undefined);
      } else {
        setSortDirection("asc");
      }
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const filteredBrandShowrooms = brandShowrooms.filter((showroom) => {
    const searchLower = search.toLowerCase();
    const rowData = extractCampaignRowDataToDisplay(showroom);
    return (
      t(translateBuyerStatus(rowData.status))
        ?.toLowerCase()
        .includes(searchLower) ||
      rowData.city?.toLowerCase().includes(searchLower) ||
      rowData.countryCode?.toLowerCase().includes(searchLower) ||
      rowData.host?.toLowerCase().includes(searchLower) ||
      rowData.season?.toLowerCase().includes(searchLower) ||
      rowData.year?.toString().includes(searchLower) ||
      rowData.orderDeadline?.toLocaleDateString().includes(searchLower)
    );
  });

  const sortedBrandShowrooms = filteredBrandShowrooms.toSorted((a, b) => {
    if (sortField === "season") {
      return sortDirection === "asc"
        ? (a.showroom?.season || "").localeCompare(b.showroom?.season || "")
        : (b.showroom?.season || "").localeCompare(a.showroom?.season || "");
    }
    if (sortField === "deadline") {
      return sortDirection === "asc"
        ? new Date(a.showroom?.orderDeadline || "").getTime() -
            new Date(b.showroom?.orderDeadline || "").getTime()
        : new Date(b.showroom?.orderDeadline || "").getTime() -
            new Date(a.showroom?.orderDeadline || "").getTime();
    }
    return 0;
  });

  // group brandShowrooms by organization id
  const groupedShowroomsWithOrganization = groupBy(
    sortedBrandShowrooms || [],
    (showroom) => showroom.organization?.id,
  );

  // all showrooms that don't have an organization id
  const showroomsWithoutOrganization = sortedBrandShowrooms.filter(
    (showroom) => !showroom.organization?.id,
  );

  // group them by brand id or agency id
  const groupedShowroomsWithoutOrganization = groupBy(
    showroomsWithoutOrganization || [],
    (showroom) => showroom.brand?.id || showroom.agency?.id,
  );

  const sortedGroupedRows = Object.entries({
    ...groupedShowroomsWithOrganization,
    ...groupedShowroomsWithoutOrganization,
  }).toSorted(([_a, valuesA], [_b, valuesB]) => {
    if (sortField === "deadline") {
      // sort by the minimum deadline of the organizations
      const minDeadlineA = Math.min(
        ...valuesA
          .filter(
            (o) =>
              o.showroom?.orderDeadline || o.seasonalInformation.orderDeadline,
          )
          .map((o) =>
            new Date(
              o.showroom?.orderDeadline ||
                o.seasonalInformation.orderDeadline ||
                "",
            ).getTime(),
          ),
      );
      const minDeadlineB = Math.min(
        ...valuesB
          .filter(
            (o) =>
              o.showroom?.orderDeadline || o.seasonalInformation.orderDeadline,
          )
          .map((o) =>
            new Date(
              o.showroom?.orderDeadline ||
                o.seasonalInformation.orderDeadline ||
                "",
            ).getTime(),
          ),
      );
      return (minDeadlineA - minDeadlineB) * (sortDirection === "asc" ? 1 : -1);
    }
    if (sortField === "season") {
      // sort by the minimum season of the organizations
      const minSeasonA =
        valuesA
          .filter((o) => o.showroom?.season)
          .map((o) => o.showroom?.season || "")
          .toSorted()[0] || "";
      const minSeasonB =
        valuesB
          .filter((o) => o.showroom?.season)
          .map((o) => o.showroom?.season || "")
          .toSorted()[0] || "";
      return sortDirection === "asc"
        ? minSeasonA.localeCompare(minSeasonB)
        : minSeasonB.localeCompare(minSeasonA);
    }
    return 0;
  });

  return (
    <>
      <PageHeader>
        <div className="flex flex-row">
          <PageTitle>{t("BuyerLayout.campaigns")}</PageTitle>
        </div>
        <div className="pr-4">
          <Button
            type="button"
            theme="PRIMARY"
            className="self-end w-full"
            onClick={inviteBrandsDrawer.openDrawer}
          >
            {t("BuyerAccount.crm.create-brand.add-new-brand")}
            <HiOutlinePlusCircle className="w-6 h-6" />
          </Button>

          <Drawer
            {...inviteBrandsDrawer.props}
            isOpen={inviteBrandsDrawer.isOpen}
            name="invite-brands"
            backdrop
            drawerTitle={
              <h2 className="heading-2-mobile lg:heading-2 mb-6">
                {t("BuyerAccount.crm.create-brand.add-new-brand")}
              </h2>
            }
            size="MEDIUM"
            isScrollable
            headerPadding="px-7"
          >
            <div className="px-7 pt-5 min-h-full relative">
              <MultipleBrandForm
                onSuccess={inviteBrandsDrawer.closeWithoutConfirmation}
              />
            </div>
          </Drawer>
        </div>
      </PageHeader>
      <div className="w-full">
        <div className="px-4 w-1/3">
          <TextField
            label="Search"
            placeholder="Search for a brand, agency, organization, ..."
            onChange={setSearch}
            value={search}
            icon={<HiOutlineSearch className="size-4" />}
          />
        </div>
      </div>
      <div className="mt-5 mx-5">
        <div className="mt-7 mb-2 px-5 grid grid-cols-12 gap-2">
          <p className="pl-16 font-medium text-left text-sm col-span-3">
            {t("BuyerAccount.campaigns.table-header.host")}
            {" / "}
            {t("BuyerAccount.campaigns.table-header.brand")}
          </p>
          <p className="pl-4 font-medium text-left text-sm col-span-2">
            {t("BuyerAccount.campaigns.table-header.status")}
          </p>
          <p className="font-medium text-left text-sm col-span-1">
            <button
              className="flex items-center gap-2 ml-2"
              type="button"
              onClick={() => toggleSort("season")}
            >
              {t("BuyerAccount.campaigns.table-header.season")}
              {sortField === "season" && (
                <>
                  {sortDirection === "asc" && <HiArrowUp />}
                  {sortDirection === "desc" && <HiArrowDown />}
                </>
              )}
            </button>
          </p>
          <p className="font-medium text-left text-sm col-span-2">
            {t("BuyerAccount.campaigns.table-header.opening-days")}
          </p>
          <p className="font-medium text-left text-sm col-span-1">
            {t("BuyerAccount.campaigns.table-header.location")}
          </p>
          <p className="font-medium text-left text-sm col-span-2">
            <div className="inline-block">
              <button
                className="flex items-center gap-2 ml-2"
                type="button"
                onClick={() => toggleSort("deadline")}
              >
                {t("BuyerAccount.campaigns.table-header.order-deadline")}
                {sortField === "deadline" && (
                  <>
                    {sortDirection === "asc" && <HiArrowUp />}
                    {sortDirection === "desc" && <HiArrowDown />}
                  </>
                )}
              </button>
            </div>
          </p>
          <p className="font-medium text-left text-sm col-span-1">
            {t("BuyerAccount.campaigns.table-header.appointments")}
          </p>
        </div>
        <hr />
        {/* check if we have any showrooms to display (either with or without organization) */}
        {sortedGroupedRows.length > 0 ? (
          <>
            {/* 
              Merge both groups of showrooms and map through them:
              - groupedShowroomsWithOrganization: showrooms grouped by their organization.id
              - groupedShowroomsWithoutOrganization: showrooms grouped by their brand.id (when no organization exists)
              
              This ensures we group related showrooms together, whether they're from:
              1. The same organization (e.g., an agency representing multiple brands)
              2. The same brand (who might have multiple showrooms)
            */}
            {sortedGroupedRows.map(([id, campaignRows]) =>
              campaignRows.length > 1 ? (
                <CampaignRowMulti key={id} campaignRows={campaignRows} />
              ) : (
                <CampaignRowSingle key={id} campaignRow={campaignRows[0]} />
              ),
            )}
          </>
        ) : (
          <div className="flex justify-center h-screen">
            <div className="text-center">
              <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
                {t("BuyerAccount.crm.empty.title")}
              </p>
              <p className="text-lg">{t("BuyerAccount.crm.empty.sub-title")}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default withBuyerOnly(BuyerCampaignsPage);
