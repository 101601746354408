import React from "react";

import {
  HiChevronLeft,
  HiChevronRight,
  HiOutlineEye,
  HiOutlineTrash,
} from "react-icons/hi2";
import { MdCheckCircleOutline } from "react-icons/md";

import {
  ShowroomFormData,
  useShowroomForm,
  validShowroomFormDataSchema,
} from "@app/modules/showroom/form/hook";
import Button from "@components/data-entry/Button";
import FilesUploadInput from "@components/data-entry/FilesUploadInput";
import BottomBar from "@components/layout/BottomBar";
import { usePrefixedTranslation } from "@helpers/Translation";

const appointmentsSchema = validShowroomFormDataSchema;

interface Props {
  defaultValues?: Partial<ShowroomFormData>;
  onSubmit: (d: ShowroomFormData) => void;
  onBack: () => void;
  disableSubmit?: boolean;
}

export default function ShowroomAttachmentsForm({
  defaultValues,
  onSubmit,
  onBack,
  disableSubmit = false,
}: Props) {
  const { pt, t } = usePrefixedTranslation("Showroom.ShowroomAttachmentsForm");
  const { form, removeExistingAttachment } = useShowroomForm({
    defaultValues,
    schema: appointmentsSchema,
  });

  const { existingAttachments, newAttachments } = form.watch();

  return (
    <>
      <div className="grow p-4">
        <p className="text-lg font-bold">{pt("email")}</p>
        <FilesUploadInput
          onFileChange={(newFiles) =>
            form.setValue(
              "newAttachments",
              newFiles.map((f) => f.file),
            )
          }
          parentFiles={newAttachments.map((f) => ({
            file: f,
            name: f.name,
            status: "ok",
          }))}
          authorizedFileExtension={[".pdf", ".jpeg", ".png"]}
          label={
            t("SalesCampaign.edit.global-information.file-input.label") ||
            undefined
          }
          description={
            t("SalesCampaign.edit.global-information.file-input.description") ||
            undefined
          }
          disabled={
            Object.keys(existingAttachments).length + newAttachments.length >= 3
          }
          maxFileNumber={3 - Object.keys(existingAttachments).length}
          maxFileSize={25000000}
        />
        {Object.entries(existingAttachments).map(([fileName, fileURL]) => (
          <div
            key={fileName}
            className="flex flex-row items-center justify-between mt-4"
          >
            <span className="flex items-center">
              <MdCheckCircleOutline
                role="img"
                aria-label="ok"
                className="w-4 mr-1 text-statusGreenDark"
              />
              <p className="max-w-md">{fileName}</p>
            </span>
            <span className="flex">
              <a
                href={fileURL}
                target="_blank"
                aria-label={`open file ${fileName}`}
                className="mr-6"
                rel="noreferrer"
              >
                <HiOutlineEye className="text-primaryElectricBlue" />
              </a>
              <Button
                theme="ICON"
                label={`remove file ${fileName}`}
                type="button"
                onClick={() => removeExistingAttachment(fileName)}
              >
                <HiOutlineTrash className="text-statusRedDark" />
              </Button>
            </span>
          </div>
        ))}
      </div>
      <BottomBar>
        <Button onClick={onBack}>
          <HiChevronLeft />
          {t("Common.previous")}
        </Button>
        <Button
          theme="PRIMARY"
          onClick={() => onSubmit(form.getValues())}
          disabled={disableSubmit}
        >
          {t("Showroom.ShowroomAttachmentsForm.save-draft")}
          <HiChevronRight />
        </Button>
      </BottomBar>
    </>
  );
}
