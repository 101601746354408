import { z } from "zod";

import { ShowroomSeasonList } from "./types/enums";

export const seasonInformationRequestSchema = z.object({
  contactId: z.string(),
  brandId: z.string(),
  season: z.enum(ShowroomSeasonList),
  year: z.number(),
});
export type SeasonInformationRequest = z.infer<
  typeof seasonInformationRequestSchema
>;
