import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { BuyerStatusEnum } from "@models/types/enums";
import axiosInstance from "@services/api/config";

import { GetBuyerCrm } from "./GetBuyerCrm";

export namespace UpdateBrandSeasonalInformation {
  export const inputSerializingSchema = z.object({
    city: z.string(),
    countryCode: z.string(),
    formattedAddress: z.string(),
    openingDays: z.array(z.string()),
    orderDeadline: z.string(),
    status: z.nativeEnum(BuyerStatusEnum),
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = z.unknown();
  export type Output = z.output<typeof outputHydratingSchema>;

  interface MutationParams {
    brandId: string;
    season: string;
    year: number;
    seasonalInformation: Input;
  }

  type Params = MutationParams;

  export const path = ({ brandId, season, year }: Params) =>
    `/buyer/crm/brand/${brandId}/season-information/${season}/${year}`;

  export const call = (params: Params) =>
    axiosInstance
      .put(
        path(params),
        inputSerializingSchema.parse(params.seasonalInformation),
      )
      .then((res) => outputHydratingSchema.parse(res.data));

  export function useHook() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) => call(mutationParams),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GetBuyerCrm.getQueryKeys(),
        });
      },
    });
  }
}
