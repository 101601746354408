import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import {
  openingDaySerializingSchema,
  showroomLiteSchema,
} from "@models/Showroom";
import { localTimeSerializingSchema } from "@models/types/Dates";
import { AppointmentFormatList, ShowroomSeasonList } from "@models/types/enums";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";
import { GetShowroomEndpoint } from "./get-showroom";
import { GetShowroomsEndpoint } from "./get-showrooms";

export namespace CreateShowroomLiteEndpoint {
  export const inputSchema = z.object({
    name: z.string(),
    season: z.enum(ShowroomSeasonList),
    year: z.number(),
    formattedAddress: z.string(),
    timezone: z.string(),
    postalCode: z.string(),
    city: z.string(),
    countryCode: z.string().length(2),
    addressComponents: z.any(),
    openingDays: z.array(openingDaySerializingSchema),
    openingHour: localTimeSerializingSchema,
    closingHour: localTimeSerializingSchema,
    orderDeadline: z.date().nullable(),
    appointmentFormats: z.array(z.enum(AppointmentFormatList)),
    brandMarketCategories: z.array(
      z.object({
        brandId: z.string(),
        marketCategories: z.array(z.string()),
      }),
    ),
  });

  export type Input = z.input<typeof inputSchema>;

  // todo: FIX This, shworoomLiteSchema is not finished
  export const outputSchema = showroomLiteSchema.pick({
    id: true,
  });

  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams {
    data: Input;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/showrooms/lite`;

  export const call = (params: Params) => {
    const { data } = params;

    const body = inputSchema.parse(data);

    return axiosInstance
      .post(path(params), body)
      .then((res) => outputSchema.parse(res.data));
  };

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: (result) => {
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            GetShowroomEndpoint.path({
              organizationId: hookParams.organizationId,
              showroomId: result.id,
            }),
          ),
        });
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            GetShowroomsEndpoint.path({
              organizationId: hookParams.organizationId,
            }),
          ),
        });
      },
    });
  }
}
