import React from "react";

import { IoIosClose } from "react-icons/io";
import { twMerge } from "tailwind-merge";

import { cn } from "@app/lib/utils";
import Button from "@components/data-entry/Button";

function Dot({ color }: { color: string }) {
  return <span className={`${color} inline-block w-2 h-2 rounded-full`} />;
}

export interface TagProps {
  children: React.ReactNode;
  size?: "xs" | "sm" | "lg";
  closable?: boolean;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: React.ReactNode;
  theme?:
    | "PRIMARY"
    | "PRIMARY-REVERSED"
    | "GREY"
    | "RED"
    | "GREEN"
    | "BLUE"
    | "PINK"
    | "ORANGE"
    | "PURPLE"
    | "PRIMARY-ELECTRIC-BLUE"
    | "BLUE-BACKGROUND";
  type?: "default" | "dotted" | "number";
  withCount?: number;
  className?: string;
  disabled?: boolean;
  capitalize?: boolean;
  title?: string;
  maxWidth?: string;
  contentClassName?: string;
  justify?: "center" | "between";
}

function Tag({
  children,
  closable,
  size = "lg",
  onClose,
  icon,
  theme,
  type = "default",
  withCount,
  className,
  disabled = false,
  capitalize = false,
  title,
  maxWidth = "w-max-full",
  contentClassName = "",
  justify,
}: TagProps) {
  let padding = "py-1 px-3";
  let sizeClasses = "";
  let disabledClasses = "";
  let border = "";
  let textColor = "text-primaryBlack";
  let bgColor = "bg-primaryLightElectricBlue";
  let dotColor = "bg-black";
  let iconColor = "text-primaryBlack";

  switch (theme) {
    case "PRIMARY":
      bgColor = "bg-primaryElectricBlue";
      textColor = "text-white";
      break;
    case "PRIMARY-REVERSED":
      bgColor = "bg-white";
      textColor = "text-primaryElectricBlue";
      break;
    case "GREY":
      bgColor = "bg-primaryLightestGrey";
      dotColor = "bg-primaryDarkGrey";
      iconColor = "text-primaryDarkGrey";
      break;
    case "GREEN":
      bgColor = "bg-statusGreenLight";
      dotColor = "bg-statusGreenDark";
      iconColor = "text-statusGreenDark";
      break;
    case "RED":
      bgColor = "bg-statusRedLight";
      dotColor = "bg-statusRedDark";
      iconColor = "text-statusRedDark";
      break;
    case "BLUE":
      bgColor = "bg-secondaryLightBlue";
      dotColor = "bg-secondaryBlue";
      iconColor = "text-secondaryBlue";
      break;
    case "PINK":
      bgColor = "bg-secondaryLightPink";
      dotColor = "bg-secondaryPink";
      iconColor = "text-secondaryPink";
      break;
    case "ORANGE":
      bgColor = "bg-statusOrangeLight";
      dotColor = "bg-statusOrangeDark";
      iconColor = "text-statusOrangeDark";
      break;
    case "PURPLE":
      bgColor = "bg-secondaryLightPurple";
      dotColor = "bg-secondaryPurple";
      iconColor = "text-secondaryPurple";
      break;
    case "PRIMARY-ELECTRIC-BLUE":
      bgColor = "bg-primaryElectricBlue";
      dotColor = "bg-primaryElectricBlue";
      iconColor = "text-white";
      break;
    case "BLUE-BACKGROUND":
      border = "border border-primaryElectricBlue";
      break;
    default:
      border = "border border-white";
      break;
  }

  if (size === "xs") {
    padding = "px-1 py-0";
    sizeClasses = "h-5 text-sm";
  } else if (size === "sm") {
    if (type === "number") {
      padding = "px-2";
      sizeClasses = "h-5 w-5 text-xs";
    } else {
      padding = "py-0 px-2";
    }
  } else if (type === "number") {
    padding = "px-3";
    sizeClasses = "h-7 w-7";
  }
  if (disabled && type === "default") {
    bgColor = "bg-primaryLightGrey";
    disabledClasses = "opacity-50 cursor-not-allowed";
  }

  return (
    <div
      title={title}
      className={cn(
        `rounded-full flex items-center justify-${
          justify || (type === "number" ? "center" : "between")
        } gap-2 ${padding} ${textColor} ${bgColor} ${disabledClasses} ${border} ${sizeClasses}`,
        className,
        {
          "w-44": maxWidth === "w-44",
        },
      )}
    >
      <span className="flex items-center gap-2">
        {type === "dotted" && <Dot color={dotColor} />}
        {icon && <span className={`${iconColor}`}>{icon}</span>}
        <span
          className={twMerge(
            `flex items-center truncate ${
              capitalize && "capitalize"
            } ${maxWidth} ${contentClassName}`,
          )}
        >
          {children}
        </span>
      </span>
      {withCount && (
        <Tag type="number" size="sm" className={`${dotColor} text-xs px-0`}>
          {withCount}
        </Tag>
      )}
      {closable && (
        <Button
          type="button"
          theme="ICON"
          label={`close tag ${children}`}
          onClick={onClose}
          disabled={disabled}
          className={disabledClasses}
        >
          <IoIosClose className="text-primaryBlack text-2xl" />
        </Button>
      )}
    </div>
  );
}

export default Tag;
