import React from "react";

import { BlueRow } from "@components/data-display/BlueRow";
import Tooltip from "@components/data-display/Tooltip";
import { BuyerStatusEnum } from "@models/types/enums";

import { CampaignRow } from "../type";
import {
  RenderAppointments,
  RenderHostName,
  RenderKeyClientIcon,
  RenderLocation,
  RenderModaresaLogo,
  RenderOpeningDays,
  RenderOrderDeadline,
  RenderStatus,
} from "./ColumnRenderers";
import { useBuyerCrmRowHook } from "./hook";

type Props = {
  campaignRow: CampaignRow;
};

export default function CampaignRowSingle({ campaignRow }: Props) {
  const rowData = useBuyerCrmRowHook({ campaignRows: [campaignRow] });

  return (
    <BlueRow
      key={campaignRow.organization?.id ?? campaignRow.brand?.id}
      colsClass="grid-cols-12"
      className="group hover:bg-gray-100 px-5 py-2 gap-2"
    >
      <div className="col-span-3 flex items-center justify-left gap-2 pr-2">
        {/* Key client icon */}
        <RenderKeyClientIcon campaignRow={campaignRow} />

        {/* Host name + Modaresa logo */}
        <RenderHostName campaignRow={campaignRow} />
        <RenderModaresaLogo campaignRow={campaignRow} />
      </div>

      {/* Showroom status */}
      <div className="col-span-2 flex items-center">
        {campaignRow.status ? (
          <RenderStatus
            value={rowData.seasonalInformation.status || campaignRow.status}
            onChange={(value) => {
              rowData.setSeasonalInformation({
                ...rowData.seasonalInformation,
                status: value as BuyerStatusEnum,
              });
            }}
          />
        ) : (
          "-"
        )}
      </div>
      {/* Showroom seasons */}
      <div className="col-span-1 flex items-center">
        {campaignRow.showroom?.season && campaignRow.showroom?.year
          ? `${campaignRow.showroom.season} ${campaignRow.showroom.year}`
          : "-"}
      </div>
      {/* Showroom opening days */}
      <div className="col-span-2 flex items-center">
        <RenderOpeningDays
          campaignRow={campaignRow}
          seasonalInformation={rowData.seasonalInformation}
          onChange={(dates) => {
            rowData.setSeasonalInformation({
              ...rowData.seasonalInformation,
              openingDays: dates,
            });
          }}
        />
      </div>
      {/* Showroom locations */}
      <div className="col-span-1 flex items-center">
        <Tooltip
          content={campaignRow.showroom?.formattedAddress}
          fallbackProp="children"
          renderIf={campaignRow.showroom?.city === null}
        >
          <RenderLocation
            campaignRow={campaignRow}
            seasonalInformation={rowData.seasonalInformation}
            onChange={(value) => {
              rowData.setSeasonalInformation({
                ...rowData.seasonalInformation,
                ...value,
              });
            }}
          />
        </Tooltip>
      </div>
      {/* Showroom order deadlines */}
      <div className="col-span-2 flex items-center">
        <RenderOrderDeadline
          campaignRow={campaignRow}
          seasonalInformation={rowData.seasonalInformation}
          onChange={(date) => {
            rowData.setSeasonalInformation({
              ...rowData.seasonalInformation,
              orderDeadline: date.toISOString(),
            });
          }}
        />
      </div>
      {/* Showroom appointments */}
      <div className="col-span-1 flex items-center">
        <RenderAppointments
          nbAppointments={campaignRow.showroom?.appointments?.length ?? 0}
          invitationId={campaignRow.invitationId ?? ""}
        />
      </div>
    </BlueRow>
  );
}
