import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";

import {
  useAccountImport,
  useContactImport,
} from "@app/modules/crm/components/SpreadsheetImporter";
import Button from "@components/data-entry/Button";
import BottomBar from "@components/layout/BottomBar";
import BreadCrumbItem from "@onboarding/components/BreadCrumbItem";
import { ImportOrganizationAccounts } from "@services/api/accounts/ImportOrganizationAccounts";
import { useGetFullAccounts } from "@services/api/accounts/get-full-accounts";
import { ImportOrganizationContacts } from "@services/api/contacts/ImportOrganizationContacts";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import AccountsTable from "@shared/accounts/AccountsTable";

export default function UploadCRMOnboarding() {
  const { organization } = useOrganizationAppContext();
  const organizationId = organization.id;

  // SHARED STATE
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: accounts = [], refetch: refetchAccounts } =
    useGetFullAccounts(organizationId);

  // COMPONENT STATE
  const [uploadingAccounts, setUploadingAccounts] = useState(false);
  const [uploadingContacts, setUploadingContacts] = useState(false);
  const { fields: accountFields, translations: accountTranslations } =
    useAccountImport();
  const {
    fields: contactsFields,
    translations: contactsTranslations,
    rowHook,
  } = useContactImport({ accounts });

  // MUTATIONS
  const { mutateAsync: importAccounts } = ImportOrganizationAccounts.useHook({
    organizationId,
  });
  const { mutateAsync: importContacts } = ImportOrganizationContacts.useHook({
    organizationId,
  });

  // ACTIONS
  async function uploadAccounts(
    acc: ImportOrganizationAccounts.Input["accounts"],
  ) {
    await importAccounts({ accounts: acc }); // import the accounts
    setUploadingAccounts(false); // close the modal
    refetchAccounts(); // refetch the accounts to get the ones uploaded
    setUploadingContacts(true); // open the contacts modal
  }

  async function uploadContacts(
    contacts: ImportOrganizationContacts.Input["contacts"],
  ) {
    console.log("uploading contacts", contacts);
    await importContacts({ contacts }); // import the contacts
    setUploadingContacts(false); // close the modal
  }

  function handleNext() {
    navigate("/onboarding/create-showroom");
  }

  // RENDER
  return (
    <div className="h-screen px-10">
      <div className="flex justify-around pt-12 sticky top-0 right-0 bg-white z-header mb-10">
        <BreadCrumbItem
          step={0}
          currentStep={1}
          label="Organization information"
          page="create-organization"
          allowNavigation={false}
        />
        <BreadCrumbItem
          step={1}
          currentStep={1}
          label="Upload CRM"
          page="upload-crm"
          allowNavigation={false}
        />
        <BreadCrumbItem
          step={2}
          currentStep={1}
          label="Create showroom"
          page="create-showroom"
          allowNavigation={false}
        />
      </div>
      <h1 className="flex items-center gap-2 text-2xl font-bold mb-10">
        {t("Onboarding.UploadCRMOnboarding.title")}
      </h1>
      <div className="flex gap-2 my-4">
        <Button theme="PRIMARY" onClick={() => setUploadingAccounts(true)}>
          {t("Onboarding.UploadCRMOnboarding.upload-accounts")}
        </Button>
        <Button theme="PRIMARY" onClick={() => setUploadingContacts(true)}>
          {t("Onboarding.UploadCRMOnboarding.upload-contacts")}
        </Button>
      </div>
      <ReactSpreadsheetImport
        isOpen={uploadingAccounts}
        onClose={() => setUploadingAccounts(false)}
        fields={accountFields}
        translations={accountTranslations}
        onSubmit={(data) =>
          uploadAccounts(
            data.validData as ImportOrganizationAccounts.Input["accounts"],
          )
        }
        rowHook={(row) => ({ ...row, isKeyClient: !!row.isKeyClient })}
      />
      <ReactSpreadsheetImport
        isOpen={uploadingContacts}
        onClose={() => setUploadingContacts(false)}
        fields={contactsFields}
        translations={contactsTranslations}
        onSubmit={(data) =>
          uploadContacts(
            data.validData.map((d) => ({
              ...d,
              accountId: accounts.find((a) => a.name === d.accountId)!.id,
              markets: d.markets?.toString().split(",") || [],
            })) as ImportOrganizationContacts.Input["contacts"],
          )
        }
        rowHook={rowHook}
      />
      <AccountsTable
        accounts={accounts.map((acc) => ({ ...acc, canBeSelected: false }))}
        selectedAccounts={[]}
        onSelectionChange={() => {}}
      />
      <BottomBar>
        <Button onClick={() => handleNext()}>
          {t("Onboarding.UploadCRMOnboarding.next")}
        </Button>
      </BottomBar>
    </div>
  );
}
