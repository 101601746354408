import React, { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  colsClass: string;
  className?: string;
  marginBetweenRows?: string;
}

export function BlueRow({
  children,
  colsClass,
  className,
  marginBetweenRows = "mb-2 mt-2",
}: Props) {
  return (
    <div
      className={`bg-primaryLightElectricBlue ${marginBetweenRows} rounded-lg grid ${colsClass} ${className}`}
    >
      {children}
    </div>
  );
}
