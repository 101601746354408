import React, { useEffect } from "react";

import { toast } from "react-toastify";

import {
  ShowroomFormData,
  ValidShowroomFormData,
  showroomFormSchema,
} from "@app/modules/showroom/form/hook";
import Loading from "@components/feedback/Loading";
import { combineDateAndTime, localTimeToUnzonedTime } from "@helpers/Date";
import { usePrefixedTranslation } from "@helpers/Translation";
import { GetShowroomEndpoint } from "@services/api/showroom/get-showroom";
import { ShowroomUpdateEndpoint } from "@services/api/showroom/update-showroom";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { fullName } from "@shared/helpers/formatters";

import ShowroomForm from "./ShowroomForm";

export default function ShowroomEditPRO({
  onSubmit,
  showroomId,
}: {
  onSubmit: () => void;
  showroomId: string;
}) {
  const [formData, setFormData] = React.useState<ShowroomFormData>();

  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();
  const { t } = usePrefixedTranslation("Showroom.ShowroomEditPage");
  const { mutateAsync: updateShowroom, status: updateShowroomStatus } =
    ShowroomUpdateEndpoint.useHook({
      organizationId,
      showroomId,
    });

  const { data: showroom } = GetShowroomEndpoint.useHook({
    showroomId,
    organizationId,
  });

  useEffect(() => {
    if (showroom) {
      const lastDayWithKeyAccountHour = showroom.openingDays.findLast(
        (openingDay) => openingDay.keyAccountsClosingHour !== null,
      );

      const extractedFormData = {
        ...showroom,
        sellers: showroom.sellers.map((seller) => ({
          ...seller,
          name: fullName(seller),
          appointmentTypes: seller.appointmentTypes.map((type) => type),
        })),
        existingAttachments: showroom.attachments || {},
        newAttachments: [],
        keyAccountDatetime: lastDayWithKeyAccountHour
          ? combineDateAndTime(
              lastDayWithKeyAccountHour.day,
              lastDayWithKeyAccountHour.keyAccountsClosingHour as Date,
            )
          : null,
        appointmentDurations: showroom.appointmentTypesDuration,
        increment: showroom.appointmentIncrement,
        appointmentDeadline: showroom.lastAllowedBookingDate,
        collections: showroom.collections,
        lunchStartingHour: showroom.lunchBreakStartingHour,
        lunchEndingHour: showroom.lunchBreakEndingHour,
        openingDays: showroom.openingDays.map((day) => ({
          day: day.day.toISOString(),
          customOpeningHour: day.customOpeningHour
            ? localTimeToUnzonedTime(day.customOpeningHour).toISOString()
            : null,
          customClosingHour: day.customClosingHour
            ? localTimeToUnzonedTime(day.customClosingHour).toISOString()
            : null,
        })),
        directions: showroom.directions || "",
        address: {
          formattedAddress: showroom.formattedAddress,
          postalCode: showroom.postalCode,
          city: showroom.city,
          countryCode: showroom.countryCode,
          addressComponents: showroom.addressComponents,
        },
      };

      setFormData(showroomFormSchema.parse(extractedFormData));
    }
  }, [showroom]);

  const handleUpdateShowroom = async (data: ValidShowroomFormData) => {
    updateShowroom({
      files: data.newAttachments,
      data: {
        ...data,
        formattedAddress: data.address.formattedAddress,
        postalCode: data.address.postalCode,
        city: data.address.city,
        countryCode: data.address.countryCode,
        addressComponents: data.address.addressComponents,
        lunchBreakStartingHour: data.lunchStartingHour,
        lunchBreakEndingHour: data.lunchEndingHour,
        appointmentIncrement: data.increment,
        lastAllowedBookingDate: data.appointmentDeadline,
        appointmentTypesDuration: data.appointmentDurations,
        id: showroomId,
      },
    }).then(() => {
      toast.success(t("Toast.showroom-update-success"));
      onSubmit();
    });
  };

  return formData ? (
    <ShowroomForm
      onStepChange={() => {}}
      defaultValues={formData}
      onSubmit={handleUpdateShowroom}
      submitStatus={updateShowroomStatus}
    />
  ) : (
    <Loading type="screen" />
  );
}
