import React from "react";

import { UseMutationResult } from "@tanstack/react-query";

import Tabs from "@components/data-display/Tabs";
import { OrganizationType } from "@models/Organization";

import ShowroomLiteGlobalInformationForm from "./form-lite/GlobalInformationLite";
import ShowroomLiteStructureForm from "./form-lite/StructureLite";
import {
  ShowroomLiteFormData,
  ValidShowroomLiteFormData, // ValidShowroomLiteFormData,
  defaultFormLiteValues,
  validShowroomLiteFormSchema,
} from "./form-lite/hook";

type Props = {
  defaultValues: Partial<ShowroomLiteFormData>;
  onSubmit: (data: ValidShowroomLiteFormData) => Promise<void>;
  submitStatus: UseMutationResult["status"];
  organizationType: OrganizationType;
};

export default function ShowroomLiteForm({
  defaultValues,
  onSubmit,
  submitStatus,
  organizationType,
}: Props) {
  const [formStep, setFormStep] = React.useState<number>(0);
  const [formData, setFormData] = React.useState<ShowroomLiteFormData>({
    ...defaultFormLiteValues,
    ...defaultValues,
  });

  const saveAndGoToStep = (step: number) => (data: ShowroomLiteFormData) => {
    setFormData(data);
    setFormStep(step);
  };

  const gotoStep = (step: number) => (): void => {
    setFormStep(step);
  };

  // handle form submission
  const handleSubmit = (submittedData: ShowroomLiteFormData) => {
    const validation = validShowroomLiteFormSchema.safeParse(submittedData);
    if (validation.success) {
      onSubmit(validation.data);
    } else {
      console.log(validation.error);
    }
  };

  return (
    <div className="flex flex-col min-h-full">
      <Tabs
        tabWidth={120}
        items={[
          {
            label: "Global information",
            tab: 0,
          },
          {
            label: "Structure",
            tab: 1,
          },
        ]}
        handleClick={(item) => {
          gotoStep(parseInt(`${item.tab}`, 10))();
        }}
        tab={formStep}
      />
      {formStep === 0 && (
        <ShowroomLiteGlobalInformationForm
          defaultValues={formData}
          onSubmit={saveAndGoToStep(1)}
        />
      )}

      {formStep === 1 && (
        <ShowroomLiteStructureForm
          defaultValues={formData}
          onBack={gotoStep(0)}
          onSubmit={handleSubmit}
          disableSubmit={submitStatus === "pending"}
          organizationType={organizationType}
        />
      )}
    </div>
  );
}
