import React from "react";

import { Calendar, CalendarProps } from "@components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover";

type Props = {
  children: React.ReactNode;
  calendarProps: CalendarProps;
  onOpen?: () => void;
  onClose?: () => void;
};

export default function CalendarPopover({
  children,
  calendarProps,
  onClose,
  onOpen,
}: Props) {
  const onOpenChange = (open: boolean) => {
    if (!open) {
      onClose?.();
    } else {
      onOpen?.();
    }
  };

  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger className="group" asChild>
        {children}
      </PopoverTrigger>
      <PopoverContent>
        <Calendar {...calendarProps} />
      </PopoverContent>
    </Popover>
  );
}
