import React from "react";

import { useTranslation } from "react-i18next";
import {
  HiChevronRight,
  HiOutlineCalendar,
  HiOutlineUsers,
} from "react-icons/hi2";

import InputLabel from "@components/data-display/InputLabel";
import TogglePanel from "@components/data-display/TogglePanel";
import AddressAutocomplete from "@components/data-entry/AddressAutocomplete";
import Button from "@components/data-entry/Button";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import SeasonSelect from "@components/data-entry/SeasonSelect";
import TextField from "@components/data-entry/TextField";
import TimePicker from "@components/data-entry/TimePicker";
import BottomBar from "@components/layout/BottomBar";

import {
  ShowroomLiteFormData,
  useShowroomLiteForm,
  validShowroomLiteFormSchema,
} from "./hook";

const globalInformationLiteSchema = validShowroomLiteFormSchema
  .pick({
    name: true,
    season: true,
    year: true,
    address: true,
    openingDays: true,
    openingHour: true,
    closingHour: true,
    orderDeadline: true,
    appointmentFormats: true,
  })
  .passthrough();

interface Props {
  defaultValues?: Partial<ShowroomLiteFormData>;
  onSubmit: (d: ShowroomLiteFormData) => void;
}

export default function ShowroomLiteGlobalInformationForm({
  defaultValues,
  onSubmit,
}: Props) {
  const {
    form,
    setName,
    setSeasonYear,
    setAddress,
    setOpeningDays,
    setOpeningHour,
    setClosingHour,
    toggleAppointmentFormat,
    setTimezone,
    setOrderDeadline,
  } = useShowroomLiteForm({
    defaultValues,
    schema: globalInformationLiteSchema,
  });

  const { t } = useTranslation();

  const {
    name,
    season,
    year,
    address,
    openingDays,
    orderDeadline,
    openingHour,
    closingHour,
    appointmentFormats,
  } = form.watch();

  // TODO: uncomment later
  const handleSubmit = form.handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <>
      <div className="grow p-4 space-y-4 mt-6">
        {/* showroom name + showroom season */}
        <div className="grid grid-cols-2 gap-4">
          {/* showroom name */}
          <div>
            <InputLabel
              htmlFor="showroom-name"
              error={form.formState.errors.name?.message}
            >
              {t("Showroom.ShowroomGlobalInformationsForm.showroom-name-label")}
            </InputLabel>
            <TextField
              hasError={!!form.formState.errors.name}
              id="showroom-name"
              value={name}
              onChange={(value) => {
                setName(value);
              }}
            />
          </div>
          {/* showroom season */}
          <div>
            <InputLabel
              id="showroom-season-year"
              error={
                form.formState.errors.season?.message ||
                form.formState.errors.year?.message
              }
            >
              {t(
                "Showroom.ShowroomGlobalInformationsForm.showroom-season-label",
              )}
            </InputLabel>
            <SeasonSelect
              aria-labelledby="showroom-season-year"
              value={{ season, year }}
              onChange={setSeasonYear}
              aria-label={t(
                "Showroom.ShowroomGlobalInformationsForm.showroom-season-label",
              )}
            />
          </div>
        </div>

        {/* showroom address */}
        <div>
          <InputLabel
            htmlFor="showroom-address"
            error={form.formState.errors.address?.message}
          >
            {t(
              "Showroom.ShowroomGlobalInformationsForm.showroom-address-label",
            )}
          </InputLabel>
          <AddressAutocomplete
            className={form.formState.errors.address ? "border-primaryRed" : ""}
            placeholder={t(
              "Showroom.ShowroomGlobalInformationsForm.showroom-address-placeholder",
            )}
            id="showroom-address"
            defaultValue={address}
            onChange={(a, tz) => {
              setAddress(a || null);
              setTimezone(tz || "");
            }}
          />
        </div>

        {/* opening days + order deadline */}
        <div className="grid grid-cols-2 gap-4">
          {/* opening days */}
          <div>
            <InputLabel
              htmlFor="showroom-opening-days"
              error={form.formState.errors.openingDays?.message}
              description={t(
                "Showroom.ShowroomGlobalInformationsForm.showroom-opening-days-description",
              )}
            >
              {t(
                "Showroom.ShowroomGlobalInformationsForm.showroom-opening-days-label",
              )}
            </InputLabel>
            <CustomDatePicker
              id="showroom-opening-days"
              placeholder={
                <div className="flex items-center gap-2 w-74">
                  <HiOutlineCalendar />{" "}
                  <span className="text-grey">
                    {t(
                      "Showroom.ShowroomGlobalInformationsForm.showroom-opening-days-placeholder",
                    )}
                  </span>
                </div>
              }
              value={openingDays.map((v) => v.day)}
              multiple
              onChange={setOpeningDays}
              intlOptions={{
                month: "numeric",
                day: "numeric",
                year: "numeric",
              }}
              minDate={Math.min(
                Date.now(),
                ...(openingDays.map((d) => d.day) || []).map((d) =>
                  d.getTime(),
                ),
              )}
            />
          </div>
          {/* order deadline */}
          <div>
            <InputLabel
              htmlFor="showroom-order-deadline"
              error={form.formState.errors.orderDeadline?.message}
              description={t(
                "Showroom.ShowroomGlobalInformationsForm.showroom-order-deadline-description",
              )}
            >
              {t(
                "Showroom.ShowroomGlobalInformationsForm.showroom-order-deadline-label",
              )}
            </InputLabel>
            <CustomDatePicker
              id="deadline"
              value={orderDeadline || null}
              placeholder={
                <div className="flex items-center gap-2 w-74">
                  <HiOutlineCalendar />{" "}
                  <span className="text-grey">
                    {t("Shared.order-deadline.selected-date-placeholder")}
                  </span>
                </div>
              }
              minDate={new Date()}
              onChange={(date) => {
                setOrderDeadline(date as Date | null); // Assert that date is Date or null, since there are no multiple dates (Date[])
              }}
              intlOptions={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }}
            />
          </div>
        </div>

        {/* opening and closing hour */}
        <div className="grid grid-cols-2 gap-4">
          <label id="showroom-opening-hour">
            {t(
              "Showroom.ShowroomGlobalInformationsForm.showroom-opening-hour-label",
            )}
          </label>
          <label id="showroom-closing-hour">
            {t(
              "Showroom.ShowroomGlobalInformationsForm.showroom-closing-hour-label",
            )}
          </label>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {/* opening hour */}
          <TimePicker
            aria-labelledby="showroom-opening-hour"
            className="inline w-34"
            placeholder={t(
              "Showroom.ShowroomGlobalInformationsForm.showroom-openingHour-placeholder",
            )}
            name="showroomOpeningHour"
            options={{
              firstHourOfDay: 6,
              totalHours: 16,
              timeStep: 15,
            }}
            onChange={(newOpeningHour) => {
              if (!newOpeningHour) {
                return; // should not be able to unselect openingHour
              }
              setOpeningHour(newOpeningHour);
            }}
            defaultValue={openingHour}
          />
          {/* closing hour */}
          <TimePicker
            aria-labelledby="showroom-closing-hour"
            className="inline w-34"
            placeholder={t(
              "Showroom.ShowroomGlobalInformationsForm.showroom-closing-hour-placeholder",
            )}
            name="showroomClosingHour"
            onChange={setClosingHour}
            minTime={openingHour}
            options={{
              firstHourOfDay: 6,
              totalHours: 16,
              timeStep: 15,
            }}
            defaultValue={closingHour}
          />
        </div>

        {/* appointment format */}
        <div>
          <InputLabel
            error={
              form.formState.errors.appointmentFormats
                ? t(
                    "Showroom.ShowroomAppointmentsForm.appointment-format-required",
                  )
                : undefined
            }
          >
            {t("Showroom.ShowroomAppointmentsForm.appointment-formats-title")}
          </InputLabel>
          <div className="pt-3">
            <div className="mb-3">
              <TogglePanel
                initiallyOpened={appointmentFormats.includes("IN_PERSON")}
                title={t("SalesCampaign.edit.appointments.in-person")}
                subtitle={t(
                  "SalesCampaign.edit.appointments.in-person-description",
                )}
                id="toggle-in-person"
                icon={<HiOutlineUsers className="w-4 h-4" />}
                onOn={() => toggleAppointmentFormat(true, "IN_PERSON")}
                onOff={() => toggleAppointmentFormat(false, "IN_PERSON")}
              />
            </div>
            <div>
              <TogglePanel
                initiallyOpened={appointmentFormats.includes("VIRTUAL")}
                title={t("SalesCampaign.edit.appointments.virtual")}
                subtitle={t(
                  "SalesCampaign.edit.appointments.virtual-description",
                )}
                id="toggle-virtual"
                icon={<HiOutlineUsers className="w-4 h-4" />}
                onOn={() => toggleAppointmentFormat(true, "VIRTUAL")}
                onOff={() => toggleAppointmentFormat(false, "VIRTUAL")}
              />
            </div>
          </div>
        </div>
      </div>
      <BottomBar alignment="center">
        <Button theme="PRIMARY" onClick={handleSubmit}>
          {t("Common.next")}
          <HiChevronRight />
        </Button>
      </BottomBar>
    </>
  );
}
