import React from "react";

export default function Shoes({ className }: { className?: string }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 -26.3 93.417 93.417"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        id="Group_31"
        data-name="Group 31"
        transform="translate(-316.957 -993.616)"
      >
        <path
          id="Path_151"
          data-name="Path 151"
          d="M361.122,1033.3H322.533a1.344,1.344,0,0,1-.843-.3l-4.226-3.38a1.349,1.349,0,0,1-.416-1.539c.182-.476,4.732-11.664,27.816-16.009l30.495-18.185a1.339,1.339,0,0,1,.264-.121,3.451,3.451,0,0,1,1.051-.155,3.4,3.4,0,0,1,2.486,1.055c1.017,1.067,1.454,2.779,1.338,5.234-.153,3.19-.134,5.017-.089,6,.866.056,2.156.119,3.571.119,4.114,0,6.075-.506,7-.932,1.146-.528,2.649-2.218,4.1-3.852,2.273-2.554,4.419-4.968,6.725-4.968a3.234,3.234,0,0,1,2.2.879c3.606,3.278,6.635,14.648,6.356,23.856l.019,4.46a1.352,1.352,0,0,1-1.17,1.344l-47.9,6.478A1.366,1.366,0,0,1,361.122,1033.3Zm-38.115-2.7h38.024l46.637-6.308-.013-3.322c.275-9.1-2.769-19.364-5.473-21.822a.528.528,0,0,0-.38-.176c-1.093,0-3.182,2.348-4.706,4.063-1.713,1.925-3.33,3.743-4.988,4.509-1.7.783-4.432,1.18-8.128,1.18-2.671,0-4.854-.208-4.945-.217a1.351,1.351,0,0,1-1.191-1.065c-.056-.264-.32-1.872-.045-7.666.108-2.251-.379-3.018-.594-3.242a.672.672,0,0,0-.531-.218c-.045,0-.086,0-.12.006l-30.514,18.2a1.393,1.393,0,0,1-.446.168c-11.347,2.1-17.829,5.914-21.269,8.742a19.182,19.182,0,0,0-4.307,4.778Z"
          fill="currentColor"
        />
        <path
          id="Path_152"
          data-name="Path 152"
          d="M407.6,1034.428H379.43a1.351,1.351,0,0,1-1.248-.835l-1.409-3.412a1.352,1.352,0,0,1,1.068-1.854l31-4.193a1.35,1.35,0,0,1,1.508,1.587l-1.426,7.605A1.35,1.35,0,0,1,407.6,1034.428Zm-27.263-2.7h26.143l.875-4.666-27.414,3.708Z"
          fill="currentColor"
        />
        <path
          id="Path_153"
          data-name="Path 153"
          d="M361.123,1033.3a1.347,1.347,0,0,1-1.031-.476l-15.773-18.59a1.35,1.35,0,1,1,2.059-1.748l15.773,18.59a1.35,1.35,0,0,1-1.028,2.224Z"
          fill="currentColor"
        />
        <path
          id="Path_154"
          data-name="Path 154"
          d="M375.965,1008.653q-1.012,0-2.246-.118c-5.528-.527-7.456-2.848-7.658-3.11a1.35,1.35,0,0,1,2.143-1.644h0s1.451,1.653,5.771,2.066c2.787.264,3.794-.041,4.058-.153a1.351,1.351,0,0,1,2.463.969C380.265,1007.986,378.744,1008.653,375.965,1008.653Z"
          fill="currentColor"
        />
        <path
          id="Path_155"
          data-name="Path 155"
          d="M357.922,1029.529H324.707a1.351,1.351,0,1,1,0-2.7h33.122l46.292-6.384a1.351,1.351,0,1,1,.37,2.676l-46.385,6.4A1.411,1.411,0,0,1,357.922,1029.529Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
