import React from "react";

export default function Accessories({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="16px"
      height="16px"
      viewBox="0 -23.74 87.75 87.75"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Group_33"
        data-name="Group 33"
        transform="translate(-315.908 -889.312)"
      >
        <path
          id="Path_156"
          data-name="Path 156"
          d="M359.783,929.591c-.613,0-1.236-.031-1.851-.092a18.25,18.25,0,1,1,1.851.092Zm0-33.41a15.183,15.183,0,1,0,1.557.078C360.823,896.207,360.3,896.181,359.784,896.181Z"
          fill="currentColor"
        />
        <g id="Group_32" data-name="Group 32">
          <path
            id="Path_157"
            data-name="Path 157"
            d="M345.994,920.028H323.9a4.777,4.777,0,0,1-4.991-4.517v-8.246a4.778,4.778,0,0,1,4.991-4.518h22.28a15.9,15.9,0,0,1,2.752-2.716H323.9c-4.406,0-7.991,3.245-7.991,7.234v8.246c0,3.989,3.585,7.233,7.991,7.233h24.726A15.856,15.856,0,0,1,345.994,920.028Z"
            fill="currentColor"
          />
          <path
            id="Path_158"
            data-name="Path 158"
            d="M395.668,900.031H370.622a16.1,16.1,0,0,1,2.755,2.716h22.291a4.777,4.777,0,0,1,4.99,4.518v8.246a4.776,4.776,0,0,1-4.99,4.517H373.589a15.734,15.734,0,0,1-2.611,2.716h24.69c4.406,0,7.99-3.244,7.99-7.233v-8.246C403.658,903.276,400.074,900.031,395.668,900.031Z"
            fill="currentColor"
          />
        </g>
        <path
          id="Path_159"
          data-name="Path 159"
          d="M354.51,919.574a1.5,1.5,0,0,1-1.077-2.544l4.85-5.008V899.754a1.5,1.5,0,0,1,3,0v12.875a1.5,1.5,0,0,1-.423,1.044l-5.273,5.444A1.5,1.5,0,0,1,354.51,919.574Z"
          fill="currentColor"
        />
        <path
          id="Path_160"
          data-name="Path 160"
          d="M365.07,892.045h-9.955a1.37,1.37,0,0,1-1.366-1.366h0a1.371,1.371,0,0,1,1.366-1.367h9.955a1.37,1.37,0,0,1,1.366,1.367h0A1.37,1.37,0,0,1,365.07,892.045Z"
          fill="currentColor"
        />
        <path
          id="Path_161"
          data-name="Path 161"
          d="M362.366,912.4a2.583,2.583,0,1,1-2.583-2.582A2.582,2.582,0,0,1,362.366,912.4Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
