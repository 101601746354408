import React, { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  colsClass: string;
  className?: string;
}

export function WhiteSubRow({ children, colsClass, className }: Props) {
  return (
    <div className={`bg-white my-0 grid ${colsClass} ${className}`}>
      {children}
    </div>
  );
}
