import React, { useEffect, useState } from "react";

import { cva } from "class-variance-authority";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover";
import LogService from "@services/log/service";

import addressAutocompleteResultMapper from "./AddressAutocompleteResultMapper";

async function getDetails(placeId: string) {
  return fetch(
    `https://places.googleapis.com/v1/places/${placeId}?fields=address_components,formattedAddress`,
    {
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": "AIzaSyD8aE-5Twhlv7xce5EdGNsvXA2_uD7y2cU",
      },
    },
  ).then((res) => res.json());
}

type Suggestion = {
  placePrediction: {
    placeId: string;
    text: { text: string };
  };
};

function useSuggestions(inputValue: string) {
  const [suggestions, setSuggestions] = useState<
    {
      placePrediction: {
        placeId: string;
        text: { text: string };
      };
    }[]
  >([]);
  const fetchSuggestions = async (input: string) =>
    fetch(`https://places.googleapis.com/v1/places:autocomplete`, {
      method: "POST",
      body: JSON.stringify({
        input,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": "AIzaSyD8aE-5Twhlv7xce5EdGNsvXA2_uD7y2cU",
      },
    }).then((res) => res.json());

  useEffect(() => {
    const timer = setTimeout(
      () =>
        fetchSuggestions(inputValue).then((data) => {
          setSuggestions(data.suggestions || []);
        }),
      1000,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  return { suggestions, clearSuggestions: () => setSuggestions([]) };
}

export type CityValue = {
  city: string;
  countryCode: string;
  formattedAddress: string;
};

type Props = {
  variant?: "default" | "compact";
  value: CityValue;
  onChange: (value: CityValue) => void;
};

const inputClassname = cva("form-input", {
  variants: {
    variant: {
      default: "px-4 py-2 rounded-md",
      compact: "px-1 py-px rounded-md",
    },
  },
});

export default function CitySelect({
  value,
  onChange,
  variant = "default",
}: Props) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value.city);

  const { suggestions: data, clearSuggestions } = useSuggestions(inputValue);

  useEffect(() => {
    if (data.length > 0) {
      setOpen(true);
    }
  }, [data]);

  const handleSelect = async (suggestion: Suggestion) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    clearSuggestions();

    try {
      const details = await getDetails(suggestion.placePrediction.placeId);
      const newAddress = addressAutocompleteResultMapper(details);
      onChange({
        city: newAddress.city,
        countryCode: newAddress.countryCode,
        formattedAddress: newAddress.formattedAddress,
      });
      setInputValue(newAddress.formattedAddress);
    } catch (err: any) {
      LogService.error("error when retrieving city info");
      LogService.error(err);
      setInputValue("");
      onChange({
        city: "",
        countryCode: "",
        formattedAddress: "",
      });
    }
    setOpen(false);
  };

  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <input
          className={inputClassname({ variant, className: "w-full" })}
          aria-label="city selection"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </PopoverTrigger>
      <PopoverContent>
        <ul>
          {data.map((suggestion) => (
            <li key={suggestion.placePrediction.placeId}>
              <button type="button" onClick={() => handleSelect(suggestion)}>
                {suggestion.placePrediction.text.text}
              </button>
            </li>
          ))}
        </ul>
      </PopoverContent>
    </Popover>
  );
}
