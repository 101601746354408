import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetAllBrandsEndpoint {
  export const outputSchema = z.array(
    brandSchema
      .pick({
        id: true,
        name: true,
        organizationId: true,
      })
      .extend({
        organization: z
          .object({
            collections: z.array(
              collectionSchema.pick({ id: true, name: true }),
            ),
          })
          .nullable(),
      }),
  );
  export type Output = z.infer<typeof outputSchema>;
  export type OutputItem = Output[number];

  export function path() {
    return `/brands`;
  }

  export function call() {
    return axiosInstance
      .get<Output>(path())
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook() {
    return useQuery({
      queryKey: getAPIQueryKey(path()),
      queryFn: () => call(),
    });
  }
}
