import React from "react";

export default function Bags({ className }: { className?: string }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="-8.59 0 82.042 82.042"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        id="Group_6"
        data-name="Group 6"
        transform="translate(-51.905 -438.76)"
      >
        <path
          id="Path_25"
          data-name="Path 25"
          d="M103.344,520.8H65.317A13.428,13.428,0,0,1,51.9,507.39v-.134l.024-.132,5.179-28.482c1.756-8.854,6.134-13.147,13.389-13.147H97.3c7.713,0,11.968,4.177,13.388,13.143l6.065,28.6v.156A13.428,13.428,0,0,1,103.344,520.8ZM54.872,507.519a10.457,10.457,0,0,0,10.445,10.317h38.027a10.459,10.459,0,0,0,10.446-10.3l-6.022-28.4c-1.474-9.383-5.9-10.677-10.464-10.677H70.5c-5.85,0-8.983,3.211-10.476,10.735Z"
          fill="currentColor"
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M84.331,500.624a7.475,7.475,0,1,1,7.475-7.475A7.484,7.484,0,0,1,84.331,500.624Zm0-11.984a4.509,4.509,0,1,0,4.509,4.509A4.514,4.514,0,0,0,84.331,488.64Z"
          fill="currentColor"
        />
        <g id="Group_5" data-name="Group 5">
          <path
            id="Path_27"
            data-name="Path 27"
            d="M79.7,489.347l-20.874-9.754a1.483,1.483,0,0,0-1.256,2.687l20.876,9.756A5.973,5.973,0,0,1,79.7,489.347Z"
            fill="currentColor"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M111.462,482.368a1.484,1.484,0,0,0-1.926-.831l-20.363,8.092a5.951,5.951,0,0,1,1.1,2.756l20.362-8.091A1.484,1.484,0,0,0,111.462,482.368Z"
            fill="currentColor"
          />
        </g>
        <path
          id="Path_29"
          data-name="Path 29"
          d="M108.819,510.19h-49.3a1.521,1.521,0,0,1,0-2.966h49.3a1.522,1.522,0,0,1,0,2.966Z"
          fill="currentColor"
        />
        <path
          id="Path_30"
          data-name="Path 30"
          d="M68.894,467.061c-.031-.612-.052-1.228-.052-1.853,0-12.948,6.966-23.482,15.528-23.482S99.9,452.26,99.9,465.208c0,.658-.023,1.307-.058,1.951a11.144,11.144,0,0,1,2.921.835c.066-.92.1-1.848.1-2.786a33.078,33.078,0,0,0-5.24-18.488c-3.5-5.133-8.206-7.96-13.255-7.96s-9.757,2.827-13.255,7.96a33.077,33.077,0,0,0-5.239,18.488q0,1.279.083,2.539A11.481,11.481,0,0,1,68.894,467.061Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
