import React, { useState } from "react";

import { cva } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { HiChevronDown } from "react-icons/hi2";

import Tag, { TagProps } from "@components/data-display/Tag";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";

const DEFAULT_COLOR: TagProps["theme"] = "BLUE";

export type TagSelectOption<T> = {
  label: string;
  value: T | null;
  icon?: React.ReactNode;
  color?: TagProps["theme"];
};

export type TagSelectProps<T> = {
  value: T | null;
  options: TagSelectOption<T>[];
  onChange: (value: T | null) => void;
  placeholder: string;
  size?: "xs" | "sm" | "md" | "lg";
};

function getOption<T>(value: T | null, options: TagSelectOption<T>[]) {
  return options.find((option) => option.value === value);
}

const tagSelectControlTheme = cva(
  "h-10 w-full group cursor-pointer justify-between flex gap-2 items-center group-hover:bg-white rounded-md px-2 py-1 group-hover:border group-hover:border-gray-200 transition-all duration-200",
  {
    variants: {
      isOpen: {
        true: "bg-white border border-gray-200",
        false: "bg-transparent border-none",
      },
      size: {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-md",
        lg: "text-lg",
      },
    },
  },
);

const tagSelectIndicatorTheme = cva(
  "bg-white z-select size-4 group-data-[is-open=true]:rotate-180 group-data-[is-open=true]:opacity-100 group-hover:opacity-100 opacity-0 transition-all duration-200 group-data-[is-open=true]:translate-x-0 group-hover:translate-x-0 translate-x-4",
  {
    variants: {
      isOpen: { true: "rotate-180", false: "translate-x-4" },
    },
  },
);

export default function TagSelect<T extends string>({
  value,
  options,
  onChange,
  placeholder,
  size = "md",
}: TagSelectProps<T>) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const placeholderOption: TagSelectOption<T> = {
    label: placeholder,
    value: null,
    color: "GREY",
  };
  const selectedOption = getOption(value, options) || placeholderOption;

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal>
      <DropdownMenuTrigger asChild>
        <div
          data-is-open={isOpen}
          className={tagSelectControlTheme({ isOpen, size })}
        >
          <Tag theme={selectedOption.color} icon={selectedOption.icon}>
            {t(selectedOption.label)}
          </Tag>
          <HiChevronDown className={tagSelectIndicatorTheme({ isOpen })} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="">
        {options.map((option) => (
          <DropdownMenuItem
            key={option.value}
            asChild
            onClick={() => {
              onChange(option.value);
              setIsOpen(false);
            }}
          >
            <div className="flex gap-2 items-center">
              <Tag
                className="flex gap-2 items-center"
                theme={option.color || DEFAULT_COLOR}
                icon={option.icon}
              >
                {t(option.label)}
              </Tag>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
