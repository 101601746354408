import { useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BuyerStatusEnum } from "@models/types/enums";
import { InviteBuyerBrandOrAgency } from "@services/api/brands/InviteBuyerBrand";
import { UpdateAgencySeasonalInformation } from "@services/api/buyer-crm/UpdateAgencySeasonalInformation";
import { UpdateBrandSeasonalInformation } from "@services/api/buyer-crm/UpdateBrandSeasonalInformation";

import { getNextFourFashionShows } from "../../crm/helpers/find-season";
import { CampaignRow } from "../type";

export type CampaignRowState = {
  city: string;
  countryCode: string;
  formattedAddress: string;
  openingDays: Date[];
  orderDeadline: string;
  status: BuyerStatusEnum;
  appointments: Date[];
};

const infoFromCampaignRows = (rows: CampaignRow[]) => {
  const city = rows[0].seasonalInformation.city ?? rows[0].showroom?.city ?? "";
  const countryCode =
    rows[0].seasonalInformation.countryCode ??
    rows[0].showroom?.countryCode ??
    "";
  const formattedAddress =
    rows[0].seasonalInformation.formattedAddress ??
    rows[0].showroom?.formattedAddress ??
    "";
  const openingDays =
    rows[0].seasonalInformation.openingDays ??
    rows[0].showroom?.openingDays.map((d) => d.day) ??
    [];
  const orderDeadline =
    rows[0].seasonalInformation.orderDeadline ??
    rows[0].showroom?.orderDeadline ??
    "";
  const status =
    rows[0].seasonalInformation.status ??
    rows[0].status ??
    BuyerStatusEnum.INFO_NOT_ANNOUNCED;
  const appointments =
    rows[0].seasonalInformation.appointments ??
    rows[0].showroom?.appointments.map((appt) => new Date(appt.startTime)) ??
    [];
  return {
    city,
    countryCode,
    formattedAddress,
    openingDays,
    orderDeadline,
    status,
    appointments,
  };
};

export function useBuyerCrmRowHook({
  campaignRows,
}: {
  campaignRows: CampaignRow[];
}) {
  const { t } = useTranslation();
  // create invite buyer brand request
  const { mutateAsync: inviteBuyerBrand } = InviteBuyerBrandOrAgency.useHook();

  const { mutateAsync: updateBrandSeasonInformation } =
    UpdateBrandSeasonalInformation.useHook();
  const { mutateAsync: updateAgencySeasonInformation } =
    UpdateAgencySeasonalInformation.useHook();

  const fashionShow = getNextFourFashionShows(new Date())[0];
  const season = campaignRows[0].showroom?.season || fashionShow.season;
  const year = campaignRows[0].showroom?.year || fashionShow.year;

  const defaultValues = infoFromCampaignRows(campaignRows);

  const [seasonalInformation, setSeasonalInformation] =
    useState<CampaignRowState>(defaultValues);
  const [edit, setEdit] = useState<boolean>(false);

  const handleInviteBuyerBrand = ({
    type,
    brandId,
    mainContactEmail,
    mainContactFirstname,
    mainContactLastname,
    name,
  }: {
    type: "BRAND" | "AGENCY";
    brandId: string;
    mainContactEmail: string;
    mainContactFirstname: string;
    mainContactLastname: string;
    // brand name
    name: string;
  }) => {
    inviteBuyerBrand({
      data: {
        type,
        brandOrAgencyId: brandId,
        mainContactEmail,
        mainContactFirstname,
        mainContactLastname,
        name,
      },
    });
    toast.success(t("BuyerAccount.crm.toast.order-deadline-requested"));
  };

  const save = (seasonalInformationToSave: CampaignRowState) => {
    if (campaignRows[0].agency) {
      updateAgencySeasonInformation({
        agencyId: campaignRows[0].agency.id,
        season,
        year,
        seasonalInformation: {
          city: seasonalInformationToSave.city,
          countryCode: seasonalInformationToSave.countryCode,
          formattedAddress: seasonalInformationToSave.formattedAddress,
          openingDays: seasonalInformationToSave.openingDays.map((day) =>
            day.toISOString(),
          ),
          orderDeadline: seasonalInformationToSave.orderDeadline,
          status: seasonalInformationToSave.status,
        },
      });
    } else if (campaignRows[0].brand) {
      updateBrandSeasonInformation({
        brandId: campaignRows[0].brand.id,
        season,
        year,
        seasonalInformation: {
          city: seasonalInformationToSave.city,
          countryCode: seasonalInformationToSave.countryCode,
          formattedAddress: seasonalInformationToSave.formattedAddress,
          openingDays: seasonalInformationToSave.openingDays.map((day) =>
            day.toISOString(),
          ),
          orderDeadline: seasonalInformationToSave.orderDeadline,
          status: seasonalInformationToSave.status,
        },
      });
    }

    setEdit(false);
  };

  return {
    seasonalInformation,
    setSeasonalInformation: (si: CampaignRowState) => {
      setSeasonalInformation(si); // update local state
      save(si); // save with endpoint
    },
    edit,
    save,
    toggleEdit: () => setEdit(!edit),
    handleInviteBuyerBrand,
  };
}
