import React, { ChangeEvent, HTMLProps } from "react";

import { useTranslation } from "react-i18next";
import { HiCheck, HiMinusSm } from "react-icons/hi";

function getStyles(
  type: CheckboxProps["type"],
  checkboxState: CheckboxState,
  disabled: boolean,
) {
  const label = `flex flex-col ${
    disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
  }`;
  let labelSpan = "pl-2 w-full flex items-center";
  let buttonWrapper = "";

  const hoverStyle = disabled ? "" : "hover:bg-primaryLightElectricBlue";

  if (type === "button") {
    const borderStyle =
      checkboxState === "mixed" || checkboxState
        ? "border-primaryElectricBlue"
        : "border-grey";
    const backgroundStyle =
      checkboxState === "mixed" || checkboxState
        ? "bg-primaryLightElectricBlue"
        : "bg-white";
    const textStyle =
      checkboxState === "mixed" || checkboxState ? "font-bold" : "";
    buttonWrapper += ` p-4 pl-6 rounded-lg border ${hoverStyle} ${backgroundStyle} ${borderStyle}`;
    labelSpan += ` ${textStyle} pl-4`;
  }

  return {
    label,
    labelSpan,
    buttonWrapper,
  };
}

type CheckboxState = true | false | "mixed";

interface CheckboxMarkProps {
  state: CheckboxState;
  borderColor?: string;
}

export function CheckboxMark({ state, borderColor }: CheckboxMarkProps) {
  return (
    <>
      {state === false && (
        <i
          className={`inline-block w-4 h-4 border ${
            borderColor || "border-PrimaryGrey"
          } rounded`}
        />
      )}
      {state === true && (
        <i className="inline-block w-4 h-4 bg-primaryElectricBlue rounded text-white">
          <HiCheck />
        </i>
      )}
      {state === "mixed" && (
        <i className="inline-block w-4 h-4 bg-primaryElectricBlue rounded text-white">
          <HiMinusSm />
        </i>
      )}
    </>
  );
}

interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, "label"> {
  name: string;
  checked: boolean;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  id: string;
  value?: string;
  indeterminate?: boolean;
  type?: "default" | "button";
  children?: React.ReactNode;
  borderColor?: string;
}

export default function Checkbox({
  name,
  checked,
  onChange,
  label,
  id,
  value,
  indeterminate = false,
  type = "default",
  children,
  className,
  disabled = false,
  borderColor,
  ...rest
}: CheckboxProps) {
  const { t } = useTranslation();
  let state: CheckboxState = checked;
  if (indeterminate) {
    state = "mixed";
  }

  const styles = getStyles(type, state, disabled);

  return (
    <>
      <input
        type="checkbox"
        onChange={onChange}
        id={id}
        name={name}
        checked={checked}
        className="hidden"
        value={value}
        disabled={disabled}
        {...rest}
      />

      <label
        htmlFor={id}
        className={`${styles.label} ${styles.buttonWrapper} ${className}`}
        title={disabled ? t("Common.unavailable-option") : ""}
      >
        <div className="flex items-center">
          <CheckboxMark state={state} borderColor={borderColor} />
          {label && <span className={styles.labelSpan}>{label}</span>}
        </div>
        {children && <div className="pt-2">{children}</div>}
      </label>
    </>
  );
}
