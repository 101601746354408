import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { organizationAccountSchema } from "@models/OrganizationAccount";
import axiosInstance from "@services/api/config";

import { getAPIQueryKey, handleParsingError } from "../helper";
import { getFullAccountsEndpoint } from "./get-full-accounts";

export namespace ImportOrganizationAccounts {
  // @todo add input schema
  export const inputSerializingSchema = z.object({
    accounts: z.array(
      organizationAccountSchema.pick({
        name: true,
        type: true,
        isKeyClient: true,
        city: true,
        countryCode: true,
        continentCode: true,
      }),
    ),
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = z.any();
  export type Output = z.output<typeof outputHydratingSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams extends Input {}

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/accounts/import`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSerializingSchema.parse(params))
      .then((res) =>
        handleParsingError(
          outputHydratingSchema,
          res.data,
          "Error in the account import response",
        ),
      );

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getAPIQueryKey(
            getFullAccountsEndpoint.path(hookParams.organizationId),
          ),
        });
      },
    });
  }
}
