import { z } from "zod";

import {
  localDateHydratingSchema,
  localDateSerializingSchema,
  localTimeHydratingSchema,
  localTimeSerializingSchema,
} from "@models/types/Dates";
import {
  AppointmentFormatList,
  AppointmentTypeEnum,
  AppointmentTypeList,
  MarketCategoryList,
  ShowroomSeasonList,
  appointmentTypeSchema,
  showroomStatusSchema,
} from "@models/types/enums";

import { brandSchema } from "./Brand";
import { organizationSchema } from "./Organization";

export const openingDayHydratingSchema = z.object({
  day: localDateHydratingSchema,
  keyAccountsOpeningHour: localTimeHydratingSchema.nullish().default(null),
  keyAccountsClosingHour: localTimeHydratingSchema.nullish().default(null),
  customOpeningHour: localTimeHydratingSchema.nullable().default(null),
  customClosingHour: localTimeHydratingSchema.nullable().default(null),
});
export type OpeningDay = z.infer<typeof openingDayHydratingSchema>;
export const openingDaySerializingSchema = z.object({
  day: localDateSerializingSchema,
  keyAccountsOpeningHour: localTimeSerializingSchema.nullish().default(null),
  keyAccountsClosingHour: localTimeSerializingSchema.nullish().default(null),
  customOpeningHour: localTimeSerializingSchema.nullable().default(null),
  customClosingHour: localTimeSerializingSchema.nullable().default(null),
});

export const walkthroughAppointmentTypeDurationSchema = z.object({
  type: z.enum([AppointmentTypeEnum.WALKTHROUGH]),
  duration: z.number(),
});
export type WalkthroughAppointmentTypeDuration = z.infer<
  typeof walkthroughAppointmentTypeDurationSchema
>;

export const buyingAppointmentTypeDurationSchema = z.object({
  type: z.enum([AppointmentTypeEnum.BUYING_APPOINTMENT]),
  duration: z.number(),
  collectionId: z.string(),
});
export type BuyingAppointmentTypeDuration = z.infer<
  typeof buyingAppointmentTypeDurationSchema
>;

export const showroomSchema = z.object({
  id: z.string(),
  organizationId: organizationSchema.shape.id,
  name: z.string(),
  formattedAddress: z.string(),
  timezone: z.string(),
  directions: z.string().nullable(),
  openingHour: localTimeHydratingSchema,
  closingHour: localTimeHydratingSchema,
  season: z.enum(ShowroomSeasonList),
  year: z.number(),
  status: showroomStatusSchema,
  attachments: z.record(z.string(), z.string()).nullish(),
  lunchBreakStartingHour: localTimeHydratingSchema.nullable(),
  lunchBreakEndingHour: localTimeHydratingSchema.nullable(),
  openingDays: z.array(openingDayHydratingSchema),
  appointmentTypes: z.array(z.enum(AppointmentTypeList)),
  appointmentFormats: z.array(z.enum(AppointmentFormatList)),
  appointmentIncrement: z.number().nullable(),
  specialRequestsAllowed: z.boolean(),
  city: z.string(),
  postalCode: z.string(),
  countryCode: z.string(),
  addressComponents: z.string().optional(),
  lastAllowedBookingDate: localDateHydratingSchema.nullable(),
  appointmentTypesDuration: z.array(
    z.union([
      walkthroughAppointmentTypeDurationSchema,
      buyingAppointmentTypeDurationSchema,
    ]),
  ),
  numberOfSeats: z.number().nullable(),
  brandMarketCategories: z.array(
    z
      .object({
        marketCategories: z.array(z.enum(MarketCategoryList)),
      })
      .extend({
        brand: brandSchema.pick({
          id: true,
          name: true,
        }),
      }),
  ),
});

export type Showroom = z.infer<typeof showroomSchema>;

export const showroomLiteSchema = z.object({
  id: z.string(),
  organizationId: organizationSchema.shape.id,
  name: z.string(),
  season: z.enum(ShowroomSeasonList),
  year: z.number(),
  formattedAddress: z.string(),
  openingDays: z.array(openingDaySerializingSchema),
  openingHour: localTimeSerializingSchema,
  closingHour: localTimeSerializingSchema,
  orderDeadline: z.date().nullable(),
  appointmentTypes: z.array(appointmentTypeSchema).nonempty(),
});

export type ShowroomLite = z.infer<typeof showroomLiteSchema>;
