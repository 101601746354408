import React from "react";

import { AdminOnboardingStep, OnboardingStep } from "@models/old/Organization";

interface BreadCrumbItemProps {
  label: string;
  page: string;
  step: OnboardingStep | AdminOnboardingStep;
  currentStep: OnboardingStep | AdminOnboardingStep;
  allowNavigation?: boolean;
}

function BreadCrumbItem(props: BreadCrumbItemProps) {
  const { label, page, step, currentStep, allowNavigation = true } = props;

  return (
    <>
      {/* previous step */}
      {currentStep > step && allowNavigation && (
        <a
          href={page}
          className="w-full py-2 mt-2 border-t-2 border-primaryElectricBlue"
        >{`${step + 1}/ ${label}`}</a>
      )}
      {currentStep > step && !allowNavigation && (
        <div className="w-full py-2 mt-2 border-t-2 border-primaryElectricBlue">
          {`${step + 1}/ ${label}`}
        </div>
      )}
      {/* current or next step not clickable */}
      {currentStep <= step && (
        <div
          className={`w-full mt-2 py-2 ${
            step !== currentStep
              ? "text-primaryLightGrey font-medium"
              : "text-primaryElectricBlue border-t-2 font-neueMontreal-Bold border-gradient-to-r from-primaryElectricBlue"
          }`}
          style={{
            borderImage: "linear-gradient(to right, #5858FF, white) 1",
          }}
        >{`${step + 1}/ ${label}`}</div>
      )}
    </>
  );
}

export default BreadCrumbItem;
