import { z } from "zod";

import { TagProps } from "@components/data-display/Tag";
import { AccountStatus } from "@models/OrganizationAccount";

export enum AppointmentTypeEnum {
  BUYING_APPOINTMENT = "BUYING_APPOINTMENT",
  WALKTHROUGH = "WALKTHROUGH",
  BUSY = "BUSY",
}

export enum InvitationStatusEnum {
  FAILED = "FAILED",
  NOT_INVITED = "NOT_INVITED",
  INVITED = "INVITED",
  FOLLOWED_UP = "FOLLOWED_UP",
  BOOKED = "BOOKED",
  BOOKED_BY_ORGANIZATION = "BOOKED_BY_ORGANIZATION",
  CANCELLED = "CANCELLED",
  CANCELLED_BY_ORGANIZATION = "CANCELLED_BY_ORGANIZATION",
  JOKER = "JOKER",
}

export enum BuyerStatusEnum {
  NOT_INVITED = "NOT_INVITED",
  INVITED = "INVITED",
  BOOKED = "BOOKED",
  CANCELLED = "CANCELLED",
  INVITED_TO_CONNECT = "INVITED_TO_CONNECT",
  INFO_NOT_ANNOUNCED = "INFO_NOT_ANNOUNCED",
  INFO_REQUESTED = "INFO_REQUESTED",
  NOT_CONNECTED = "NOT_CONNECTED",
  INFO_PUBLISHED = "INFO_PUBLISHED",
  SPECIAL_REQUEST = "SPECIAL_REQUEST",
  SLOT_REQUESTED = "SLOT_REQUESTED",
}

export enum MailDeliveryStatusEnum {
  PROCESSED = "PROCESSED",
  DELIVERED = "DELIVERED",
  OPENED = "OPENED",
  CLICKED = "CLICKED",
  BOUNCED = "BOUNCED",
  MARKED_AS_SPAM = "MARKED_AS_SPAM",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  RESUBSCRIBED = "RESUBSCRIBED",
  NOT_SENT = "NOT_SENT",
}

export const VirtualMeetingAppsList = [
  "ZOOM",
  "GOOGLE_MEET",
  "MICROSOFT_TEAMS",
] as const;
export type VirtualMeetingApps = (typeof VirtualMeetingAppsList)[number];
export const virtualMeetingAppSchema = z.enum(VirtualMeetingAppsList);

export const VirtualMeetingAppsChoiceList = [
  "ANY",
  ...VirtualMeetingAppsList,
] as const;
export type VirtualMeetingAppChoice =
  (typeof VirtualMeetingAppsChoiceList)[number];
export const virtualMeetingAppWithANYSchema = z.enum(
  VirtualMeetingAppsChoiceList,
);

export const AppointmentFormatList = ["IN_PERSON", "VIRTUAL"] as const;
export type AppointmentFormat = (typeof AppointmentFormatList)[number];
export const appointmentFormatSchema = z.enum(AppointmentFormatList);

export const AppointmentFormatWithMeetingAppList =
  AppointmentFormatList.flatMap((format) =>
    format === "VIRTUAL"
      ? VirtualMeetingAppsList.map(
          (app) => `${format}_${app}` as `VIRTUAL_${VirtualMeetingApps}`,
        )
      : format,
  );
export type AppointmentFormatWithMeetingApp =
  (typeof AppointmentFormatWithMeetingAppList)[number];

export const BusyAppointmentTypeList = [AppointmentTypeEnum.BUSY] as const;
export const AccountAppointmentTypeList = [
  AppointmentTypeEnum.BUYING_APPOINTMENT,
  AppointmentTypeEnum.WALKTHROUGH,
] as const;
export const AppointmentTypeList = [
  ...AccountAppointmentTypeList,
  ...BusyAppointmentTypeList,
] as const;
export const appointmentTypeSchema = z.enum(AppointmentTypeList);

export const CollectionRelatedAppointmentTypeList: NonEmptyArray<AppointmentTypeEnum> =
  [AppointmentTypeEnum.BUYING_APPOINTMENT];
export const CollectionAgnosticAppointmentTypeList: NonEmptyArray<AppointmentTypeEnum> =
  [AppointmentTypeEnum.WALKTHROUGH];

export function isCollectionRelatedAppointmentType(
  type: AppointmentTypeEnum,
): type is (typeof CollectionRelatedAppointmentTypeList)[number] {
  return CollectionRelatedAppointmentTypeList.includes(type);
}

export const UserRoleList = [
  "MODARESA_ADMIN",
  "ORGANIZATION_MAIN_ADMIN",
  "ORGANIZATION_ADMIN",
  "ORGANIZATION_MANAGER",
  "AGENT",
  "FREELANCE",
  "CONTACT_BUYER",
] as const;
export type UserRole = (typeof UserRoleList)[number];

export const OrganizationRepresentativeRoleList: UserRole[] = [
  "ORGANIZATION_MAIN_ADMIN",
  "ORGANIZATION_ADMIN",
  "ORGANIZATION_MANAGER",
  "AGENT",
  "FREELANCE",
];
export type OrganizationRepresentativeRole =
  (typeof OrganizationRepresentativeRoleList)[number];

export enum AdminOnboardingStep {
  ORGANIZATION_CREATION,
  ACCOUNTS_UPLOAD,
  CONTACTS_UPLOAD,
  REPRESENTATIVES_UPLOAD,
}
export enum OnboardingStep {
  ORGANIZATION_CONFIRMATION,
  SHOWROOM_STRUCTURE,
  ACCOUNT_PORTFOLIO,
}

export const MailDeliveryStatusList = [
  MailDeliveryStatusEnum.PROCESSED,
  MailDeliveryStatusEnum.DELIVERED,
  MailDeliveryStatusEnum.OPENED,
  MailDeliveryStatusEnum.CLICKED,
  MailDeliveryStatusEnum.BOUNCED,
  MailDeliveryStatusEnum.MARKED_AS_SPAM,
  MailDeliveryStatusEnum.UNSUBSCRIBED,
  MailDeliveryStatusEnum.RESUBSCRIBED,
  MailDeliveryStatusEnum.NOT_SENT,
] as const;
export type MailDeliveryStatus = (typeof MailDeliveryStatusList)[number];

export const MarketTypesList = ["CHILDREN", "HOME", "MEN", "WOMEN"] as const;
export type MarketType = (typeof MarketTypesList)[number];

export const InvitationStatusList = [
  InvitationStatusEnum.FAILED,
  InvitationStatusEnum.NOT_INVITED,
  InvitationStatusEnum.INVITED,
  InvitationStatusEnum.FOLLOWED_UP,
  InvitationStatusEnum.BOOKED,
  InvitationStatusEnum.BOOKED_BY_ORGANIZATION,
  InvitationStatusEnum.CANCELLED,
  InvitationStatusEnum.CANCELLED_BY_ORGANIZATION,
  InvitationStatusEnum.JOKER,
] as const;
export type InvitationStatus = (typeof InvitationStatusList)[number];

export const BuyerStatusList = [
  BuyerStatusEnum.NOT_INVITED,
  BuyerStatusEnum.INVITED,
  BuyerStatusEnum.BOOKED,
  BuyerStatusEnum.CANCELLED,
  BuyerStatusEnum.INVITED_TO_CONNECT,
  BuyerStatusEnum.INFO_NOT_ANNOUNCED,
  BuyerStatusEnum.INFO_REQUESTED,
  BuyerStatusEnum.NOT_CONNECTED,
  BuyerStatusEnum.INFO_PUBLISHED,
  BuyerStatusEnum.SPECIAL_REQUEST,
] as const;
export type BuyerStatus = (typeof BuyerStatusList)[number];

export const EmailStatusList = [
  "VALID",
  "BOUNCED",
  "MARKED_AS_SPAM",
  "UNSUBSCRIBED",
] as const;
export type EmailStatus = (typeof EmailStatusList)[number];

export const AvailabilityStatusList = [
  "PREFERRED",
  "AVAILABLE",
  "LANGUAGE_MISMATCH",
  "BOOKED",
] as const;
export type AvailabilityStatus = (typeof AvailabilityStatusList)[number];

export const AccountsStatusColors: Record<AccountStatus, TagProps["theme"]> = {
  ACTIVE: "GREEN",
  PROSPECT: "BLUE",
  NOT_ACCESSIBLE: "RED",
};

export const ShowroomSeasonList = ["PRE_AW", "AW", "PRE_SS", "SS"] as const;
export type ShowroomSeason = (typeof ShowroomSeasonList)[number];

export const RepresentativeInvitationStatusList = [
  "ADDED",
  "INVITED",
  "ACCEPTED",
] as const;
export type RepresentativeInvitationStatus =
  (typeof RepresentativeInvitationStatusList)[number];

export const ShowroomStatusList = [
  "DRAFT",
  "READY_TO_LAUNCH",
  "SENDING_INVITATIONS",
  "SHOWROOM_ONGOING",
  "PAST",
] as const;
export const showroomStatusSchema = z.enum(ShowroomStatusList);
export type ShowroomStatus = z.infer<typeof showroomStatusSchema>;

export enum BookedThroughEnum {
  BUYER_JOURNEY = "BUYER_JOURNEY",
  CALENDAR = "CALENDAR",
  SPECIAL_REQUEST = "SPECIAL_REQUEST",
}

export const BookedThroughList = [
  "BUYER_JOURNEY",
  "CALENDAR",
  "SPECIAL_REQUEST",
] as const;

export enum MarketCategoryEnum {
  WOMEN_READY_TO_WEAR = "WOMEN_READY_TO_WEAR",
  WOMEN_BAGS = "WOMEN_BAGS",
  WOMEN_SHOES = "WOMEN_SHOES",
  WOMEN_ACCESSORIES = "WOMEN_ACCESSORIES",
  WOMEN_JEWELLERY = "WOMEN_JEWELLERY",

  MEN_READY_TO_WEAR = "MEN_READY_TO_WEAR",
  MEN_BAGS = "MEN_BAGS",
  MEN_SHOES = "MEN_SHOES",
  MEN_ACCESSORIES = "MEN_ACCESSORIES",
  MEN_JEWELLERY = "MEN_JEWELLERY",

  CHILDREN_READY_TO_WEAR = "CHILDREN_READY_TO_WEAR",
  CHILDREN_BAGS = "CHILDREN_BAGS",
  CHILDREN_SHOES = "CHILDREN_SHOES",
  CHILDREN_ACCESSORIES = "CHILDREN_ACCESSORIES",
  CHILDREN_JEWELLERY = "CHILDREN_JEWELLERY",

  HOME = "HOME",
}

export const MarketCategoryList = [
  MarketCategoryEnum.WOMEN_READY_TO_WEAR,
  MarketCategoryEnum.WOMEN_BAGS,
  MarketCategoryEnum.WOMEN_SHOES,
  MarketCategoryEnum.WOMEN_ACCESSORIES,
  MarketCategoryEnum.WOMEN_JEWELLERY,

  MarketCategoryEnum.MEN_READY_TO_WEAR,
  MarketCategoryEnum.MEN_BAGS,
  MarketCategoryEnum.MEN_SHOES,
  MarketCategoryEnum.MEN_ACCESSORIES,
  MarketCategoryEnum.MEN_JEWELLERY,

  MarketCategoryEnum.CHILDREN_READY_TO_WEAR,
  MarketCategoryEnum.CHILDREN_BAGS,
  MarketCategoryEnum.CHILDREN_SHOES,
  MarketCategoryEnum.CHILDREN_ACCESSORIES,
  MarketCategoryEnum.CHILDREN_JEWELLERY,

  MarketCategoryEnum.HOME,
] as const;

export enum MarketCategoryGroupEnum {
  WOMEN = "WOMEN",
  MEN = "MEN",
  CHILDREN = "CHILDREN",
  HOME = "HOME",
  READY_TO_WEAR = "READY_TO_WEAR",
  BAGS = "BAGS",
  SHOES = "SHOES",
  ACCESSORIES = "ACCESSORIES",
  JEWELLERY = "JEWELLERY",
}

export const MarketCategoryGroupList = [
  MarketCategoryGroupEnum.WOMEN,
  MarketCategoryGroupEnum.MEN,
  MarketCategoryGroupEnum.CHILDREN,
  MarketCategoryGroupEnum.HOME,
  MarketCategoryGroupEnum.READY_TO_WEAR,
  MarketCategoryGroupEnum.BAGS,
  MarketCategoryGroupEnum.SHOES,
  MarketCategoryGroupEnum.ACCESSORIES,
  MarketCategoryGroupEnum.JEWELLERY,
] as const;

export enum OrganizationPlanEnum {
  PRO = "PRO",
  LITE = "LITE",
}

export const OrganizationPlanList = [
  OrganizationPlanEnum.PRO,
  OrganizationPlanEnum.LITE,
] as const;
