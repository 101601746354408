import React from "react";

import { useTranslation } from "react-i18next";
import { HiOutlineStar, HiStar } from "react-icons/hi2";

import Tooltip from "@components/data-display/Tooltip";
import { WhiteSubRow } from "@components/data-display/WhiteSubRow";
import Button from "@components/data-entry/Button";
import { BuyerStatusEnum } from "@models/types/enums";
import { ToggleFavoriteBrand } from "@services/api/buyer-crm/ToggleFavoriteBrand";

import { CampaignRow } from "../type";
import {
  RenderAppointments,
  RenderLocation,
  RenderOpeningDays,
  RenderOrderDeadline,
  RenderStatus,
} from "./ColumnRenderers";
import { useBuyerCrmRowHook } from "./hook";

type Props = {
  campaignRow: CampaignRow;
};

export default function CampaignSubRow({ campaignRow }: Props) {
  const { t } = useTranslation();

  const { mutateAsync: toggleFavoriteBrand } = ToggleFavoriteBrand.useHook();

  const rowHook = useBuyerCrmRowHook({
    campaignRows: [campaignRow],
  });

  return (
    <WhiteSubRow
      colsClass="grid-cols-12 gap-2"
      className="group hover:bg-gray-100 px-5 py-2 bg-statusRedLight m-0"
    >
      {/* Brand name */}
      <div className="col-span-3 flex items-center">
        <div className="col-span-1 flex items-center mr-2">
          {/* Show the key account icon only if the organization is a brand within an agency,
          to avoid displaying duplicate icons for the same brand across different showrooms in each subrow. */}
          {campaignRow.brand && campaignRow.agency ? (
            <Tooltip
              placement="bottom-start"
              content={
                <div className="min-w-[5rem] truncate">
                  {campaignRow.isKeyClient
                    ? t("CRM.accounts.unset-is-key-account")
                    : t("CRM.accounts.set-is-key-account")}
                </div>
              }
            >
              <Button
                theme="NONE"
                label={`${
                  campaignRow.isKeyClient ? "un" : ""
                }set-key-account-${campaignRow.brand?.id}`}
                className="flex items-center justify-center h-full"
                onClick={() => {
                  // sub rows should always refer to a brand, even in an agency
                  if (campaignRow.brand) {
                    toggleFavoriteBrand({
                      brandId: campaignRow.brand.id,
                    });
                  }
                }}
              >
                {campaignRow.isKeyClient ? (
                  <HiStar
                    aria-label={`${campaignRow.brand?.name}-isKeyClient`}
                    className="text-secondaryCalendulaGold"
                  />
                ) : (
                  <HiOutlineStar
                    aria-label={`${campaignRow.brand?.name}-isNotKeyClient`}
                    className="text-primaryGrey"
                  />
                )}
              </Button>
            </Tooltip>
          ) : (
            <div className="w-4" />
          )}
        </div>
        <div className="flex items-center">
          <div className="col-span-1">{campaignRow.brand?.name}</div>
        </div>
      </div>
      {/* Showroom status */}
      <div className="col-span-1 flex">
        <RenderStatus
          value={rowHook.seasonalInformation.status || campaignRow.status}
          onChange={(value) => {
            rowHook.setSeasonalInformation({
              ...rowHook.seasonalInformation,
              status: value as BuyerStatusEnum,
            });
          }}
        />
      </div>
      {/* Showroom season */}
      <div className="col-span-1 flex items-center">
        {campaignRow.showroom?.season && campaignRow.showroom?.year
          ? `${campaignRow.showroom.season} ${campaignRow.showroom.year}`
          : "-"}
      </div>
      {/* Opening days */}
      <div className="col-span-2 flex items-center">
        <RenderOpeningDays
          campaignRow={campaignRow}
          seasonalInformation={rowHook.seasonalInformation}
          onChange={(dates) => {
            rowHook.setSeasonalInformation({
              ...rowHook.seasonalInformation,
              openingDays: dates,
            });
          }}
        />
      </div>
      {/* Address */}
      <div className="col-span-1 flex items-center">
        <RenderLocation
          campaignRow={campaignRow}
          seasonalInformation={rowHook.seasonalInformation}
          onChange={(value) => {
            rowHook.setSeasonalInformation({
              ...rowHook.seasonalInformation,
              city: value.city,
              countryCode: value.countryCode,
            });
          }}
        />
      </div>
      {/* Order deadline */}
      <div className="col-span-2 flex items-center">
        <RenderOrderDeadline
          campaignRow={campaignRow}
          seasonalInformation={rowHook.seasonalInformation}
          onChange={(date) => {
            rowHook.setSeasonalInformation({
              ...rowHook.seasonalInformation,
              orderDeadline: date.toISOString(),
            });
          }}
        />
      </div>
      {/* Appointments */}
      <div className="col-span-1 flex items-center">
        <RenderAppointments
          nbAppointments={campaignRow.showroom?.appointments?.length ?? 0}
          invitationId={campaignRow.invitationId ?? ""}
        />
      </div>
    </WhiteSubRow>
  );
}
