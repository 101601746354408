import React from "react";

export default function ReadyToWear({ className }: { className?: string }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 -11.71 113.169 113.169"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        id="Group_15"
        data-name="Group 15"
        transform="translate(-34.813 -868.471)"
      >
        <path
          id="Path_72"
          data-name="Path 72"
          d="M59.058,918.65a1.6,1.6,0,0,1-.743-.182L35.57,906.5a1.4,1.4,0,0,1-.723-.91,1.294,1.294,0,0,1,.252-1.1l24.327-30.915a1.578,1.578,0,0,1,1.868-.432,1.347,1.347,0,0,1,.79,1.619l-1.526,4.985v37.519a1.366,1.366,0,0,1-.747,1.2A1.6,1.6,0,0,1,59.058,918.65ZM38.509,904.867l19.049,10.02V880.659Z"
          fill="currentColor"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M123.737,918.65a1.6,1.6,0,0,1-.753-.187,1.366,1.366,0,0,1-.747-1.2V879.748l-1.525-4.985a1.347,1.347,0,0,1,.79-1.619,1.579,1.579,0,0,1,1.868.432L147.7,904.491a1.294,1.294,0,0,1,.252,1.1,1.4,1.4,0,0,1-.723.91L124.48,918.468A1.6,1.6,0,0,1,123.737,918.65Zm1.5-37.992v34.229l19.049-10.02Z"
          fill="currentColor"
        />
        <path
          id="Path_74"
          data-name="Path 74"
          d="M106.844,868.652c-.257,7.7-7.118,13.9-15.536,13.9s-15.278-6.2-15.535-13.9h-3c.258,9.227,8.464,16.664,18.535,16.664s18.278-7.437,18.536-16.664Z"
          fill="currentColor"
        />
        <path
          id="Path_75"
          data-name="Path 75"
          d="M124.6,879.557c0-6.257-5.06-11.086-11.849-11.086H69.871c-6.789,0-12.275,4.829-12.275,11.086v77.49c0,.172.02.341.028.511v.663h66.947v-.661c.007-.171.025-.34.025-.513Zm-54.725-8.086h42.876c5.134,0,8.849,3.354,8.849,8.086v75.664h-61V879.557C60.6,874.825,64.736,871.471,69.871,871.471Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
