import React, { useState } from "react";

import { flag } from "country-emoji";
import { differenceInDays, format, formatDistance, isSameDay } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  HiCalendar,
  HiOutlineCalendar,
  HiOutlineCheckCircle,
  HiOutlineClock,
  HiOutlineInformationCircle,
  HiOutlineStar,
  HiOutlineXCircle,
  HiStar,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import modaResaLogo from "@app/assets/logos/moda-resa-small-logo.png";
import Tag, { TagProps } from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import CalendarPopover from "@components/data-entry/CalendarPopover";
import CitySelect from "@components/data-entry/CitySelect";
import TagSelect, { TagSelectOption } from "@components/data-entry/TagSelect";
import { BuyerStatusEnum, MarketCategoryEnum } from "@models/types/enums";
import { ToggleFavoriteAgency } from "@services/api/buyer-crm/ToggleFavoriteAgency";
import { ToggleFavoriteBrand } from "@services/api/buyer-crm/ToggleFavoriteBrand";

import { CampaignRow } from "../type";
import { CampaignRowState } from "./hook";

export function extractCampaignRowDataToDisplay(campaignRow: CampaignRow) {
  const { seasonalInformation, showroom } = campaignRow;

  // host
  const host =
    campaignRow.organization?.name ??
    campaignRow.brand?.name ??
    campaignRow.agency?.name;

  // status
  const status =
    seasonalInformation.status ??
    campaignRow.status ??
    BuyerStatusEnum.INFO_NOT_ANNOUNCED;

  // city
  const city = seasonalInformation.city ?? showroom?.city ?? "";
  // country
  const countryCode =
    seasonalInformation.countryCode ?? showroom?.countryCode ?? "";

  // season
  const season = showroom?.season ?? null;
  // year
  const year = showroom?.year ?? null;

  // opening days
  let openingDays: Date[] = [];
  if (
    seasonalInformation &&
    seasonalInformation.openingDays &&
    seasonalInformation.openingDays.length > 0
  ) {
    openingDays = seasonalInformation.openingDays;
  } else if (
    showroom &&
    showroom.openingDays &&
    showroom.openingDays.length > 0
  ) {
    openingDays = showroom.openingDays.map((day) => new Date(day.day));
  }

  // order deadline
  const orderDeadlineString =
    seasonalInformation.orderDeadline ?? showroom?.orderDeadline;
  let orderDeadline: Date | null = null;
  if (orderDeadlineString) {
    orderDeadline = new Date(orderDeadlineString);
  }

  return {
    host,
    isMr: !!campaignRow.organization?.id,
    isKey: campaignRow.isKeyClient,
    status,
    season,
    year,
    formattedAddress: `${city} ${flag(countryCode)}`,
    city,
    countryCode,
    openingDays,
    orderDeadline,
  };
}

export function RenderOrderDeadline({
  campaignRow,
  seasonalInformation,
  onChange,
}: {
  campaignRow: CampaignRow;
  seasonalInformation: CampaignRowState;
  onChange: (date: Date) => void;
}) {
  const { showroom } = campaignRow;

  // check if the order deadline is filled in the seasonal information, if not, check if the showroom has an order deadline, if not, display "-"
  const deadlineString =
    seasonalInformation.orderDeadline ?? showroom?.orderDeadline;
  const now = new Date();
  const deadlineDate = deadlineString ? new Date(deadlineString) : undefined;

  let theme: TagProps["theme"] = "GREEN";

  // handle the duration display
  if (deadlineDate) {
    const daysLeft = differenceInDays(deadlineDate, now);
    if (daysLeft <= 0) {
      theme = "RED";
    } else if (daysLeft <= 5) {
      theme = "ORANGE";
    }
  }

  return (
    <CalendarPopover
      calendarProps={{
        mode: "single",
        selected: deadlineDate,
        onSelect: (date) => {
          if (date) {
            onChange(date);
          }
        },
      }}
    >
      <div className="flex items-center gap-2">
        <Tag
          className={`group-hover:min-h-10 group-hover:flex group-hover:gap-2 group-hover:items-center ${theme} cursor-pointer group-hover:rounded-md group-hover:bg-white group-hover:border group-hover:border-gray-200`}
          theme={theme}
          icon={
            <>
              <HiOutlineCalendar className="group-hover:block hidden size-4" />
              <HiOutlineClock className="group-hover:hidden block size-4" />
            </>
          }
        >
          <span className="group-hover:hidden">
            {deadlineDate
              ? formatDistance(deadlineDate, new Date(), {
                  addSuffix: true,
                })
              : "-"}
          </span>
          <span className="group-hover:block hidden">
            {deadlineDate ? format(deadlineDate, "MMM d, yyyy") : "-"}
          </span>
        </Tag>
      </div>
    </CalendarPopover>
  );
}

const statusOptions: TagSelectOption<BuyerStatusEnum>[] = [
  {
    label: "Common.buyer-brand-status.invited-to-connect",
    value: BuyerStatusEnum.INVITED_TO_CONNECT,
    color: "BLUE",
    icon: <HiOutlineInformationCircle className="size-4" />,
  },
  {
    label: "Common.buyer-brand-status.info-not-announced",
    value: BuyerStatusEnum.INFO_NOT_ANNOUNCED,
    color: "BLUE",
    icon: <HiOutlineInformationCircle className="size-4" />,
  },
  {
    label: "Common.buyer-brand-status.info-requested",
    value: BuyerStatusEnum.INFO_REQUESTED,
    color: "BLUE",
    icon: <HiOutlineInformationCircle className="size-4" />,
  },
  {
    label: "Common.buyer-brand-status.slot-requested",
    value: BuyerStatusEnum.SLOT_REQUESTED,
    color: "BLUE",
    icon: <HiOutlineInformationCircle className="size-4" />,
  },
  {
    label: "Common.buyer-brand-status.invited",
    value: BuyerStatusEnum.INVITED,
    color: "ORANGE",
    icon: <HiOutlineInformationCircle className="size-4" />,
  },
  {
    label: "Common.buyer-brand-status.not-invited",
    value: BuyerStatusEnum.NOT_INVITED,
    color: "ORANGE",
    icon: <HiOutlineInformationCircle className="size-4" />,
  },
  {
    label: "Common.buyer-brand-status.booked",
    value: BuyerStatusEnum.BOOKED,
    color: "GREEN",
    icon: <HiOutlineCheckCircle className="size-4" />,
  },
  {
    label: "Common.buyer-brand-status.cancelled",
    value: BuyerStatusEnum.CANCELLED,
    color: "RED",
    icon: <HiOutlineXCircle className="size-4" />,
  },
];

export function RenderLocation({
  campaignRow,
  seasonalInformation,
  onChange,
}: {
  campaignRow: CampaignRow;
  seasonalInformation: CampaignRowState;
  onChange: (value: { city: string; countryCode: string }) => void;
}) {
  const [edit, setEdit] = useState(false);

  // use seasonal information if filled, or showroom information if filled, or display "-"
  const formattedAddress =
    seasonalInformation.formattedAddress ??
    campaignRow.showroom?.formattedAddress ??
    "";
  const city = seasonalInformation.city ?? campaignRow.showroom?.city ?? "";
  const countryCode =
    seasonalInformation.countryCode ?? campaignRow.showroom?.countryCode ?? "";

  return edit ? (
    <CitySelect
      value={{
        city,
        countryCode,
        formattedAddress,
      }}
      onChange={(value) => {
        onChange(value);
        setEdit(false);
      }}
      variant="compact"
    />
  ) : (
    <button
      className="min-h-10 w-full rounded-md group-hover:bg-white group-hover:border group-hoverborder-dotted border-gray-200 p-1"
      type="button"
      onClick={() => setEdit(true)}
    >
      {city !== "" && countryCode !== "" ? `${city} ${flag(countryCode)}` : "-"}
    </button>
  );
}

export function RenderStatus({
  value,
  onChange,
}: {
  value: BuyerStatusEnum | null;
  onChange: (value: BuyerStatusEnum | null) => void;
}) {
  return (
    <TagSelect<BuyerStatusEnum>
      size="sm"
      value={value}
      options={statusOptions}
      onChange={onChange}
      placeholder="Select a status"
    />
  );
}

export function RenderOpeningDays({
  campaignRow,
  seasonalInformation,
  onChange,
}: {
  campaignRow: CampaignRow;
  seasonalInformation: CampaignRowState;
  onChange: (dates: Date[]) => void;
}) {
  const { showroom } = campaignRow;

  // get the value from the seasonal information if it exists, otherwise use the showroom informations, otherwise it is empty
  let selected: Date[] = [];
  if (
    seasonalInformation &&
    seasonalInformation.openingDays &&
    seasonalInformation.openingDays.length > 0
  ) {
    selected = seasonalInformation.openingDays;
  } else if (
    showroom &&
    showroom.openingDays &&
    showroom.openingDays.length > 0
  ) {
    selected = showroom.openingDays.map((day) => new Date(day.day));
  }

  const isOngoing = selected.find((day) => isSameDay(day, new Date()));

  let formattedDates = "-";

  if (selected.length > 0) {
    const from = selected[0];
    const to = selected[selected.length - 1];
    const isSameYear = from.getFullYear() === to.getFullYear();
    const isSameMonth = from.getMonth() === to.getMonth();

    if (isSameYear) {
      formattedDates = isSameMonth
        ? `${format(from, "MMM d")} - ${format(to, "d, yyyy")}`
        : `${format(from, "MMM d")} - ${format(to, "MMM d, yyyy")}`;
    } else {
      formattedDates = isSameMonth
        ? `${format(from, "MMM d, yyyy")} - ${format(to, "d, yyyy")}`
        : `${format(from, "MMM d, yyyy")} - ${format(to, "MMM d, yyyy")}`;
    }
  }

  return (
    <CalendarPopover
      calendarProps={{
        onSelect: (dates) => {
          onChange(dates?.toSorted((a, b) => a.getTime() - b.getTime()) || []);
        },
        mode: "multiple",
        selected,
      }}
    >
      <span className="min-h-10 w-full flex gap-2 items-center text-center cursor-pointer rounded-md group-hover:bg-white group-hover:border group-hoverborder-dotted border-gray-200 p-1">
        <HiCalendar className="group-hover:block hidden size-4" />
        {isOngoing && (
          <span className="bg-green-500 inline-block w-2 h-2 rounded-full" />
        )}{" "}
        {formattedDates}
      </span>
    </CalendarPopover>
  );
}

export function RenderMarketCategories({
  marketCategories,
}: {
  marketCategories: MarketCategoryEnum[];
}) {
  const { t } = useTranslation();
  if (!marketCategories || marketCategories.length === 0) return "-";

  const maxVisibleTags = 1;
  const visibleTags = marketCategories.slice(0, maxVisibleTags);
  const remainingTags = marketCategories.slice(maxVisibleTags);
  const remainingTagsCount = marketCategories.length - maxVisibleTags;

  return (
    <div className="flex flex-col items-start">
      {visibleTags.map((marketCategory) => (
        <Tag
          size="xs"
          key={marketCategory}
          theme="BLUE-BACKGROUND"
          className="m-1"
        >
          {t(`Common.market-category.${marketCategory}`)}
        </Tag>
      ))}
      {remainingTagsCount > 0 && (
        <Tooltip
          content={remainingTags
            .map((marketCategory) =>
              t(`Common.market-category.${marketCategory}`),
            )
            .join(", ")}
          fallbackProp="children"
          renderIf={remainingTagsCount > 0}
        >
          <Tag
            size="xs"
            theme="PRIMARY-REVERSED"
            className="m-1 border border-primaryElectricBlue"
          >
            <span className="m-1">+{remainingTagsCount} more</span>
          </Tag>
        </Tooltip>
      )}
    </div>
  );
}

export function RenderAppointments({
  nbAppointments,
  invitationId,
}: {
  nbAppointments: number;
  invitationId: string;
}) {
  const navigate = useNavigate();

  if (!invitationId && nbAppointments === 0) return "-";

  // if there are no appointments and there is an invitation id, navigate to the booking page
  if (nbAppointments === 0 && invitationId)
    return (
      <Button
        theme="PRIMARY"
        onClick={() => navigate(`/booking/${invitationId}`)}
      >
        Book
      </Button>
    );

  return (
    <a
      href={`/buyer/appointments/${invitationId}`}
      className="text-primaryElectricBlue"
    >
      <div>{nbAppointments} appointments</div>
    </a>
  );
}

export function RenderKeyClientIcon({
  campaignRow,
}: {
  campaignRow: CampaignRow;
}) {
  const { t } = useTranslation();
  const { mutateAsync: toggleFavoriteBrand } = ToggleFavoriteBrand.useHook();
  const { mutateAsync: toggleFavoriteAgency } = ToggleFavoriteAgency.useHook();

  return (
    <Tooltip
      placement="bottom-start"
      content={
        <div className="min-w-[5rem] truncate">
          {campaignRow.isKeyClient
            ? t("CRM.accounts.unset-is-key-account")
            : t("CRM.accounts.set-is-key-account")}
        </div>
      }
    >
      <Button
        theme="NONE"
        label={`${
          campaignRow.isKeyClient ? "un" : ""
        }set-key-account-${campaignRow.brand?.id}`}
        className="flex items-center justify-center h-full"
        onClick={() => {
          if (campaignRow.brand) {
            toggleFavoriteBrand({
              brandId: campaignRow.brand.id,
            });
          } else if (campaignRow.agency) {
            toggleFavoriteAgency({
              agencyId: campaignRow.agency.id,
            });
          }
        }}
      >
        {campaignRow.isKeyClient ? (
          <HiStar
            aria-label={`${campaignRow.brand?.name}-isKeyClient`}
            className="size-5 text-secondaryCalendulaGold"
          />
        ) : (
          <HiOutlineStar
            aria-label={`${campaignRow.brand?.name}-isNotKeyClient`}
            className="size-5 text-primaryGrey"
          />
        )}
      </Button>
    </Tooltip>
  );
}

export function RenderModaresaLogo({
  campaignRow,
}: {
  campaignRow: CampaignRow;
}) {
  const { t } = useTranslation();
  return (
    campaignRow.organization?.id && (
      <div>
        <Tooltip
          content={t("BuyerAccount.crm.has_organization_account")}
          fallbackProp="children"
        >
          <img
            alt="modaresa logo"
            width="40"
            src={modaResaLogo}
            className="ml-3"
          />
        </Tooltip>
      </div>
    )
  );
}
export function RenderHostName({ campaignRow }: { campaignRow: CampaignRow }) {
  return (
    <div className="flex items-center">
      {/* Host name */}
      {/* if there is no organization, show the brand name. If there is no brand, show the agency name */}
      <div>
        {campaignRow.organization?.name ??
          campaignRow.brand?.name ??
          campaignRow.agency?.name}
      </div>
    </div>
  );
}

export function RenderBuyingStatus({
  buyingStatuses,
}: {
  buyingStatuses: {
    brandId: string;
    buyingStatus: "ACTIVE" | "PROSPECT" | "INACTIVE";
    marketCategory: MarketCategoryEnum;
  }[];
}) {
  const { t } = useTranslation();
  // if there is at least one active => active
  const aggregatedBuyingStatus = buyingStatuses.reduce((acc, status) => {
    if (status.buyingStatus === "ACTIVE") return "ACTIVE";
    if (status.buyingStatus === "PROSPECT" && acc !== "ACTIVE")
      return "PROSPECT";
    return acc;
  }, "INACTIVE");

  let tagTheme: "GREEN" | "BLUE" | "RED" = "BLUE"; // set to blue by default
  if (aggregatedBuyingStatus === "ACTIVE") {
    tagTheme = "GREEN";
  } else if (aggregatedBuyingStatus === "PROSPECT") {
    tagTheme = "BLUE";
  } else if (aggregatedBuyingStatus === "INACTIVE") {
    tagTheme = "RED";
  }

  return (
    <Tag
      type="dotted"
      theme={tagTheme}
      className="mb-1 cursor-pointer w-fit h-fit"
    >
      {buyingStatuses.length === 0
        ? "N/A"
        : t(
            `BuyerAccount.campaigns.table-content.buying-status.${aggregatedBuyingStatus}`,
          )}
    </Tag>
  );
}
