import React, { useState } from "react";

import { useQueries } from "@tanstack/react-query";
import { flag } from "country-emoji";
import { format } from "date-fns";
import { Trans } from "react-i18next";

import MultiSelect from "@components/data-entry/MultiSelect";
import CalloutBox from "@components/feedback/CalloutBox";
import { NumberFormatter } from "@helpers/String";
import { usePrefixedTranslation } from "@helpers/Translation";
import { AppointmentTypeEnum, ShowroomSeason } from "@models/types/enums";
import { BookingSaveBuyerOtbsEndpoint } from "@services/api/booking/save-buyer-otbs";
import { GetOrderDeadline } from "@services/api/order-deadline/GetOrderDeadline";
import { fullName } from "@shared/helpers/formatters";
import { translateAppointmentType } from "@shared/helpers/translater";

import BookingCardPanel from "./appointment/card";
import { useBookingContext } from "./hook";

type Props = {
  invitationId: string;
  onNext: () => void;
  onPrevious: () => void;
  colleagues: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
  budgets: {
    otb: number | null;
    collectionId: string;
    season: ShowroomSeason;
    year: number;
  }[];
  showrooms: {
    city: string;
    countryCode: string;
    id: string;
    name: string;
    season: string;
    year: number;
    collections: {
      id: string;
      name: string;
      brand: {
        id: string;
        name: string;
      };
    }[];
  }[];
};

function selectShowroom(showroomId: string, showrooms: Props["showrooms"]) {
  return showrooms.find((s) => s.id === showroomId);
}

function selectCollection(collectionId: string, showrooms: Props["showrooms"]) {
  return showrooms
    .flatMap((s) => s.collections)
    .find((c) => c.id === collectionId);
}

function selectBudget(collectionId: string, budgets: Props["budgets"]) {
  return budgets.find((b) => b.collectionId === collectionId);
}

export default function AttendeesOtbFill({
  invitationId,
  budgets,
  colleagues,
  showrooms,
  onNext,
  onPrevious,
}: Props) {
  const { t, pt, pk } = usePrefixedTranslation("Booking.AttendeesOtbFill");
  const { state, dispatch } = useBookingContext();
  const everyBuyer = [...colleagues];

  // array of IDs of the collections the buyer wants to book for
  const collectionIds = state.appointments
    .filter((appt) => "collection" in appt)
    .map((appt) =>
      "collection" in appt ? appt.collection.id : null,
    ) as string[];

  // use previous array and map to object with otb value (from budget or null)
  const initialOtbs = collectionIds.map((c) => ({
    collectionId: c,
    otb: selectBudget(c, budgets)?.otb || null,
  }));

  const [buyerOtbs, setBuyerOtbs] =
    useState<{ collectionId: string; otb: number | null }[]>(initialOtbs);

  // function to update an OTB
  const updateOtb = (collectionId: string, value: string) => {
    const otb = NumberFormatter.fromString(value) || null;
    const newState = buyerOtbs.map((o) =>
      o.collectionId === collectionId ? { collectionId, otb } : o,
    );
    setBuyerOtbs(newState);
  };

  // function to find the current OTB
  const selectOtb = (collectionId: string) =>
    buyerOtbs.find((o) => o.collectionId === collectionId)?.otb || null;

  const { mutateAsync: saveBuyerOtbs } = BookingSaveBuyerOtbsEndpoint.useHook({
    invitationId,
  });

  //* order deadline

  // extract season and year (assuming they are the same across all showrooms)
  const commonSeason = showrooms[0].season;
  const commonYear = showrooms[0].year;

  // create a set of unique brand IDs across all showrooms
  const uniqueBrandIds = new Set<string>();
  showrooms.forEach((showroom) => {
    showroom.collections.forEach((collection) => {
      uniqueBrandIds.add(collection.brand.id);
    });
  });

  // create a query array with the unique brand IDs and the common season and year
  const queryArray = Array.from(uniqueBrandIds).map((brandId) => ({
    queryKey: GetOrderDeadline.getQueryKeys({
      brandId,
      season: commonSeason,
      year: commonYear,
    }),
    queryFn: () =>
      GetOrderDeadline.call({
        brandId,
        season: commonSeason,
        year: commonYear,
      }),
  }));

  // fetch data using useQueries
  const { data: orderDeadlines } = useQueries({
    queries: queryArray,
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  return (
    <BookingCardPanel
      title={pt("title")}
      step={2}
      onNext={() => {
        saveBuyerOtbs({
          data: {
            buyerOtbs,
          },
        }).then(() => onNext());
      }}
      onPrevious={onPrevious}
    >
      <div className="grow flex flex-col gap-4">
        <CalloutBox type="INFORMATIVE">
          <Trans i18nKey={pk("callout-please-provide-otb")} />
        </CalloutBox>
        <div
          className={`font-bold grid ${orderDeadlines ? "grid-cols-9" : "grid-cols-8"} gap-4 even:bg-primaryLightestGrey`}
        >
          <div>{pt("table.headers.showroom")}</div>
          <div>{pt("table.headers.brand")}</div>
          <div>{pt("table.headers.collection")}</div>
          <div>{pt("table.headers.location")}</div>
          {orderDeadlines && <div>{pt("table.headers.order-deadline")}</div>}
          <div className="col-span-3">{pt("table.headers.attendees")}</div>
          <div>{pt("table.headers.otb")}</div>
        </div>
        {state.appointments.map((appt) => {
          const collection =
            "collection" in appt
              ? selectCollection(appt.collection.id, showrooms)
              : null;
          const showroom = selectShowroom(appt.showroomId, showrooms);

          const orderDeadlineJSX = Array.from(uniqueBrandIds)
            .map((brandId) => {
              const matchingOrderDeadline = orderDeadlines?.find(
                (deadline) => deadline.brandId === brandId,
              );

              if (matchingOrderDeadline) {
                return (
                  <div key={brandId} className="flex items-center gap-2">
                    <p className="flex items-center">
                      {matchingOrderDeadline.deadline
                        ? format(
                            new Date(matchingOrderDeadline.deadline),
                            "MMM d, yyyy",
                          )
                        : "-"}
                    </p>
                  </div>
                );
              }

              return null;
            })
            .filter(Boolean);

          return (
            <div
              className={`grid ${orderDeadlines ? "grid-cols-9" : "grid-cols-8"} gap-4 even:bg-primaryLightestGrey items-center`}
              key={`${appt.showroomId}-${appt.type}-${
                "collection" in appt ? appt.collection.id : ""
              }`}
            >
              <div>{showroom?.name}</div>
              <div>
                {collection?.brand.name ||
                  t(translateAppointmentType(appt.type))}
              </div>
              <div>{collection?.name || "-"}</div>
              <div>
                {flag(showroom?.countryCode || "fr")} {showroom?.city}
              </div>
              {orderDeadlines &&
                (appt.type !== AppointmentTypeEnum.WALKTHROUGH ? (
                  <div> {orderDeadlineJSX}</div>
                ) : (
                  <div>-</div>
                ))}

              <div className="col-span-3">
                <MultiSelect
                  value={appt.attendees.map((a) => ({
                    label: fullName(a),
                    value: a.id,
                  }))}
                  onChange={(v) => {
                    dispatch({
                      type: "SET_APPOINTMENT_ATTENDEES",
                      attendees: everyBuyer.filter((b) =>
                        v.map((a) => a.value).includes(b.id),
                      ),
                      showroomId: appt.showroomId,
                      collectionId:
                        "collection" in appt ? appt.collection.id : undefined,
                      appointmentType: appt.type,
                    });
                  }}
                  options={everyBuyer.map((c) => ({
                    label: fullName(c),
                    value: c.id,
                  }))}
                />
              </div>
              <div className="flex">
                {collection ? (
                  <input
                    type="text"
                    aria-label="specify otb"
                    className="w-full border border-primaryLightestGrey pl-2"
                    value={NumberFormatter.toString(
                      selectOtb(collection.id) || 0,
                    )}
                    onChange={(e) => updateOtb(collection.id, e.target.value)}
                  />
                ) : (
                  <>-</>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </BookingCardPanel>
  );
}
