import { useMutation } from "@tanstack/react-query";
import { t } from "i18next";
import { toast } from "react-toastify";
import { z } from "zod";

import { agencySchema } from "@models/Agency";
import { brandSchema } from "@models/Brand";
import { organizationSchema } from "@models/Organization";
import axiosInstance from "@services/api/config";
import useApplicationContext from "@services/application/useApplicationContext";

export namespace CreateLiteOrganization {
  export const createBrandOrganizationSchema = z.object({
    name: organizationSchema.shape.name,
    type: z.literal("BRAND"),
    address: organizationSchema.shape.address,
    timezone: organizationSchema.shape.timezone,
    brandId: z.string().uuid().optional(),
  });
  export const createAgencyOrganizationSchema = z.object({
    name: organizationSchema.shape.name,
    type: z.literal("AGENCY"),
    address: organizationSchema.shape.address,
    timezone: organizationSchema.shape.timezone,
    agencyId: z.string().uuid().optional(),
  });
  export const inputSerializingSchema = z.discriminatedUnion("type", [
    createBrandOrganizationSchema,
    createAgencyOrganizationSchema,
  ]);

  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = organizationSchema
    .pick({
      id: true,
      name: true,
      type: true,
      timezone: true,
      address: true,
      banner: true,
      onboardingStatus: true,
      accountPortfolioType: true,
      currentPlan: true,
    })
    .extend({
      agency: agencySchema
        .pick({
          id: true,
          name: true,
          organizationId: true,
        })
        .optional(),
      brand: brandSchema
        .pick({
          id: true,
          name: true,
          organizationId: true,
        })
        .nullable(),
      partnerBrands: z.array(
        brandSchema.pick({
          id: true,
          name: true,
          organizationId: true,
        }),
      ),
    });
  export type Output = z.output<typeof outputHydratingSchema>;

  interface MutationParams {
    data: Input;
  }

  type Params = MutationParams;

  export const path = () => `/organizations/lite`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(), inputSerializingSchema.parse(params.data))
      .then((res) => outputHydratingSchema.parse(res.data))
      .catch((error) => {
        if (error.statusCode === 500) {
          toast.error(
            t("Toast.duplicate-organization-name", {
              brand_name: params.data.name,
            }),
          );
        }
        throw error;
      });

  export function useHook() {
    const { setOrganization } = useApplicationContext();
    return useMutation({
      mutationFn: call,
      onSuccess: (organization) => {
        setOrganization(organization);
      },
    });
  }
}
