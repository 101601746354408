import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { organizationSchema } from "@models/Organization";
import { openingDayHydratingSchema, showroomSchema } from "@models/Showroom";
import { dateHydratingSchema } from "@models/types/Dates";
import { BuyerStatusEnum, MarketCategoryEnum } from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey, handleParsingError } from "@services/api/helper";

export namespace GetBuyerCrm {
  const baseOutputSchema = z.object({
    status: z.nativeEnum(BuyerStatusEnum),
    isConnected: z.boolean(),
    isKeyClient: z.boolean(),
    organization: organizationSchema.pick({ id: true, name: true }).nullable(),
    invitationId: z.string().nullable(),
    marketCategories: z.array(z.nativeEnum(MarketCategoryEnum)),
    buyingStatuses: z.array(
      z.object({
        brandId: z.string().uuid(),
        marketCategory: z.nativeEnum(MarketCategoryEnum),
        buyingStatus: z.enum(["ACTIVE", "PROSPECT", "INACTIVE"]),
      }),
    ),
    seasonalInformation: z.object({
      status: z.nativeEnum(BuyerStatusEnum).nullable(),
      city: z.string().nullable(),
      countryCode: z.string().nullable(),
      openingDays: z.array(dateHydratingSchema),
      orderDeadline: z.string().datetime().nullable(),
      appointments: z.array(dateHydratingSchema),
      formattedAddress: z.string().nullable(),
    }),
    showroom: showroomSchema
      .pick({
        id: true,
        formattedAddress: true,
        season: true,
        year: true,
        city: true,
        countryCode: true,
      })
      .extend({
        orderDeadline: z.string().datetime(),
        openingDays: z.array(openingDayHydratingSchema.pick({ day: true })),
        appointments: z.array(
          z.object({
            id: z.string().uuid(),
            startTime: z.string().datetime(),
            endTime: z.string().datetime(),
          }),
        ),
      })
      .nullable(),
  });
  const agencyRowSchema = baseOutputSchema.extend({
    brand: brandSchema.pick({ id: true, name: true }).nullable(),
    agency: z.object({ id: z.string().uuid(), name: z.string() }),
  });
  export type AgencyRow = z.infer<typeof agencyRowSchema>;
  const brandRowSchema = baseOutputSchema.extend({
    brand: brandSchema.pick({ id: true, name: true }),
    agency: z.null(),
  });
  export type BrandRow = z.infer<typeof brandRowSchema>;

  export const outputSchema = z.array(
    z.union([brandRowSchema, agencyRowSchema]),
  );
  export type APIOutput = z.input<typeof outputSchema>;
  export type Output = z.infer<typeof outputSchema>;

  export function path() {
    return `/buyer/crm`;
  }

  export const getQueryKeys = () => getAPIQueryKey(path());

  export function call() {
    return axiosInstance
      .get<Output>(path())
      .then((res) =>
        handleParsingError(
          outputSchema,
          res.data,
          "Failed to fetch buyer brands",
        ),
      );
  }

  export function useHook() {
    return useQuery({
      queryKey: getQueryKeys(),
      queryFn: () => call(),
    });
  }
}
