import React from "react";

import { useParams } from "react-router-dom";

import modaresaLogo from "@app/assets/logos/moda-resa.svg";
import SignUpForm from "@app/modules/guest/SignUpForm";
import { useSignupLink } from "@pages/onboarding/create-organization";
import { GuestOnly } from "@shared/components/guards/authentication";

export default function BrandSignUpPage() {
  const { brandId } = useParams();
  useSignupLink("brand", brandId); // store the brandId in local storage
  return (
    <GuestOnly>
      <div className="h-screen w-screen px-44 bg-[url('/signin-bg.png')] bg-cover">
        <div className="bg-white rounded-b px-16 flex flex-col pb-16">
          <img
            src={modaresaLogo}
            alt="Modaresa"
            className="w-80 mx-auto py-10"
          />
          <div className="flex">
            <div className="border-r border-primaryLightGrey flex flex-col items-center pr-12 w-1/4">
              <p className="text-lg text-primaryDarkGrey">
                Already on Modaresa
              </p>
              <ul className="grid grid-cols-2 gap-2">
                {[
                  "Jacquemus",
                  "Loewe",
                  "Axel Arigato",
                  "Extreme Cashmere",
                  "Officine Générale",
                  "MISBHV",
                  "EW",
                  "Axel Arigato",
                  "Erdem",
                  "Oakley",
                  "Christian Wijnants",
                  "Victoria Beckham",
                ].map((brand) => (
                  <li className="w-24 h-24 uppercase border border-primaryLightGrey rounded-sm flex items-center justify-center text-center">
                    {brand}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div className="flex flex-col gap-4 items-center px-12 w-2/3 mx-auto">
                <p className="text-3xl font-bold">Hi ! 👋</p>
                <p className="text-xl text-primaryDarkGrey">
                  You have been invited to join Modaresa LITE for free.
                </p>
                <p className="text-xl text-primaryDarkGrey">
                  With modaresa, you can import your clients , launch your
                  showroom, schedule all your appointments, and collect
                  essential information in a personalized, live dashboard - and
                  much more !🚀
                </p>
              </div>
              <hr className="my-12" />
              <div className="flex flex-col gap-4 items-center px-12">
                <p className="text-3xl font-bold">Sign Up to ModaResa LITE</p>
                <SignUpForm hideContextSwitch hideSignInLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </GuestOnly>
  );
}
