import React, { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { isString } from "remeda";
import { z } from "zod";

import AddressAutocomplete from "@components/data-entry/AddressAutocomplete";
import ImageUpload from "@components/data-entry/ImageUpload";
import PhoneNumberInput, {
  isPhoneValid,
} from "@components/data-entry/PhoneNumberInput";
import Select from "@components/data-entry/Select";
import SingleSelect from "@components/data-entry/SingleSelect";
import TextField from "@components/data-entry/TextField";
import {
  OrganizationType,
  organizationTypeSchema,
  organizationTypes,
} from "@models/Organization";
import { addressSchema } from "@models/types/Address";
import LogService from "@services/log/service";
import TimezoneAutocomplete from "@shared/components/form/timezone-autocomplete";
import ConditionalRender from "@shared/components/on";
import getOptionMaker from "@shared/helpers/option-maker";
import { translateOrganizationType } from "@shared/helpers/translater";

export const organizationInformationFormDataSchema = z.object({
  id: z.string().uuid().optional(),
  type: organizationTypeSchema,
  existingBrandId: z.string().uuid().nullable(),
  name: z.string(),
  address: addressSchema,
  timezone: z.string(),
  mainContact: z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phoneNumber: z.string().nullable(),
  }),
  /**
   * The state representing the file input
   * Filling this input will set "organization.banner" to this file
   * Leaving this input empty will let "currentBanner" decide on the "organization.banner" value to submit
   */
  bannerFile: z.instanceof(File).nullable(), // File instance
  /**
   * The state representing the current organization's banner
   * Setting it to null will set "organization.banner" to null
   */
  banner: z.string().nullable(),
});

export type OrganizationInformationFormData = z.infer<
  typeof organizationInformationFormDataSchema
>;

const defaultFormData: OrganizationInformationFormData = {
  type: "BRAND",
  existingBrandId: null,
  name: "",
  address: {
    city: "",
    countryCode: "",
    formattedAddress: "",
    postalCode: "",
    addressComponents: "",
  },
  banner: null,
  mainContact: {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  },
  bannerFile: null,
  timezone: "",
  id: undefined,
};

interface UseOrganizationInformationForm {
  onSubmit: (
    organizationInformation: OrganizationInformationFormData,
  ) => Promise<void>;
  existing?: OrganizationInformationFormData;
}

export function useOrganizationInformationForm(
  props: UseOrganizationInformationForm,
) {
  const { onSubmit, existing = defaultFormData } = props;
  const [updating, setUpdating] = useState<boolean>(false);
  const [onSubmitError, setOnSubmitError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
    resetField,
  } = useForm<OrganizationInformationFormData>({
    defaultValues: existing,
    mode: "onChange",
  });

  const onSubmitForm = (data: OrganizationInformationFormData) => {
    setUpdating(true);
    onSubmit(data)
      .catch((error: any) => {
        toast.error("Submitting organization information failed");
        if (error.message) {
          toast.error(error.message);
          setOnSubmitError(error.messag);
        } else if (isString(error)) {
          toast.error(error);
          setOnSubmitError(error);
        }
        LogService.error(error);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const currentBanner = watch("banner");

  return {
    watch,
    resetField,
    updating,
    setUpdating,
    onSubmitError,
    setOnSubmitError,
    control,
    setValue,
    currentBanner,
    handleSubmit,
    errors,
    isValid,
    onSubmitForm,
  };
}

interface OrganizationInformationFormProps
  extends ReturnType<typeof useOrganizationInformationForm> {
  children?: React.ReactNode;
  readonly?: boolean;
  className?: string;
  admin?: boolean;
  brandsOptions: {
    label: string;
    value: string;
  }[];
}

function OrganizationInformationForm(props: OrganizationInformationFormProps) {
  const {
    resetField,
    children,
    className,
    control,
    handleSubmit,
    onSubmitForm,
    setValue,
    currentBanner,
    onSubmitError,
    errors,
    readonly = false,
    admin = false,
    watch,
    brandsOptions,
  } = props;

  const { t } = useTranslation();

  // helper to make options
  const organizationTypeToOption = getOptionMaker<OrganizationType>((type) =>
    t(translateOrganizationType(type)),
  );

  const currentType = watch("type");
  const currentBrandId = watch("existingBrandId");
  const selectedBrandOption = brandsOptions.find(
    (b) => b.value === currentBrandId,
  );

  return (
    <form
      className="w-full"
      onSubmit={handleSubmit(onSubmitForm)}
      autoComplete="off"
    >
      <input type="hidden" value={currentType} />
      <div className={`flex flex-col gap-10 ${className || ""}`}>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <h3 className="text-base font-medium">Global information</h3>
            <hr />
          </div>

          <ConditionalRender renderIf={admin}>
            <div>
              <label className="text-sm font-medium" htmlFor="organizationName">
                Organization type
              </label>
              <div className="max-w-sm mt-2">
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: "Organization type is required" }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      name="type"
                      defaultValue={organizationTypeToOption(value)}
                      onChange={onChange}
                      options={[
                        {
                          key: "first group",
                          label: "first group",
                          options: organizationTypes.map(
                            organizationTypeToOption,
                          ),
                        },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </ConditionalRender>

          <div className="flex gap-4 max-w-sm mt-2">
            <ConditionalRender
              renderIf={
                admin && currentType === "BRAND" && brandsOptions.length > 0
              }
            >
              <div>
                <label
                  className="text-sm font-medium"
                  htmlFor="organizationName"
                >
                  Select an existing brand
                </label>
                <div>
                  <Controller
                    name="existingBrandId"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <SingleSelect
                        isClearable
                        name="existingBrandId"
                        options={brandsOptions}
                        defaultValue={selectedBrandOption}
                        onChange={(o) => {
                          onChange(o?.value);
                          if (o) {
                            setValue("name", o.label);
                          } else {
                            setValue("name", "");
                          }
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </ConditionalRender>

            <div>
              <label className="text-sm font-medium" htmlFor="organizationName">
                Your organization name :
              </label>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Organization name is required" }}
                render={({
                  fieldState: { error },
                  field: { onChange, value },
                }) => (
                  <TextField
                    id="organizationName"
                    placeholder="ex: Jacquemus"
                    value={value}
                    onChange={onChange}
                    readOnly={readonly || !!currentBrandId}
                    hasError={!!error}
                  />
                )}
              />
              {errors.name?.message && (
                <p className="text-xs italic text-primaryRed">
                  {`${errors.name?.message}`}
                </p>
              )}
            </div>
          </div>
          <div>
            <div>
              <label className="text-sm font-medium" htmlFor="organizationName">
                Email banner
              </label>
              <div className="max-w-sm mt-2">
                <Controller
                  control={control}
                  name="bannerFile"
                  render={({ field: { onChange, value } }) => (
                    <ImageUpload
                      imageClassNames="w-[600px] h-[200px]"
                      newImage={value}
                      existingImage={currentBanner}
                      onChange={(v) => {
                        onChange(v);
                        if (v === null) {
                          setValue("banner", null);
                        }
                      }}
                      labels={{
                        upload: t(
                          "Organization.OrganizationInformationForm.banner-upload",
                        ),
                        replace: t(
                          "Organization.OrganizationInformationForm.banner-replace",
                        ),
                        delete: t(
                          "Organization.OrganizationInformationForm.banner-delete",
                        ),
                        subtitle: t(
                          "Organization.OrganizationInformationForm.banner-size",
                        ),
                      }}
                      name="bannerFile"
                      alt="Organization banner"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div>
            <label
              className="text-sm font-medium"
              htmlFor="organizationAddress"
            >
              Organization address
            </label>
            <div className="max-w-lg mt-2">
              <Controller
                name="address"
                control={control}
                rules={{ required: "Organization address is required" }}
                render={({ field: { onChange, value } }) => (
                  <AddressAutocomplete
                    readonly={readonly}
                    placeholder="ex: 12 Rue du Loup 75000 Paris"
                    id="organizationAddress"
                    defaultValue={value}
                    onChange={(address, timezone) => {
                      if (address) {
                        onChange(address);
                        if (timezone) {
                          setValue("timezone", timezone);
                        }
                      } else {
                        resetField("address");
                      }
                    }}
                  />
                )}
              />
              {errors.address?.message && (
                <p className="text-xs italic text-primaryRed">
                  {`${errors.address?.message}`}
                </p>
              )}
            </div>
          </div>

          <div>
            <label
              className="text-sm font-medium"
              htmlFor="organizationTimezone"
            >
              Timezone
            </label>
            <div className="max-w-lg mt-2 ">
              <Controller
                name="timezone"
                control={control}
                rules={{ required: "Timezone is required" }}
                render={({ field: { onChange, value } }) => (
                  <TimezoneAutocomplete
                    readonly={readonly}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.timezone?.message && (
                <p className="text-xs italic text-primaryRed">
                  {`${errors.timezone?.message}`}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <h3 className="text-base font-medium">Main contact information</h3>
            <hr />
          </div>

          <div>
            <label className="text-sm font-medium" htmlFor="contactFirstName">
              Main contact first name
            </label>
            <div className="max-w-sm mt-2">
              <Controller
                name="mainContact.firstName"
                control={control}
                rules={{ required: "First name is required" }}
                render={({
                  fieldState: { error },
                  field: { onChange, value },
                }) => (
                  <TextField
                    readOnly={readonly}
                    id="contactFirstName"
                    placeholder="ex: Joe"
                    value={value}
                    onChange={onChange}
                    hasError={!!error}
                  />
                )}
              />
              {errors.mainContact?.firstName?.message && (
                <p className="text-xs italic text-primaryRed">
                  {`${errors.mainContact.firstName?.message}`}
                </p>
              )}
            </div>
          </div>

          <div>
            <label className="text-sm font-medium" htmlFor="contactLastName">
              Main contact last name
            </label>
            <div className="max-w-sm mt-2">
              <Controller
                name="mainContact.lastName"
                control={control}
                rules={{ required: "Last name is required" }}
                render={({
                  fieldState: { error },
                  field: { onChange, value },
                }) => (
                  <TextField
                    readOnly={readonly}
                    id="contactLastName"
                    placeholder="ex: Natan"
                    value={value}
                    onChange={onChange}
                    hasError={!!error}
                  />
                )}
              />
              {errors.mainContact?.lastName?.message && (
                <p className="text-xs italic text-primaryRed">
                  {`${errors.mainContact.lastName?.message}`}
                </p>
              )}
            </div>
          </div>

          <div>
            <label className="text-sm font-medium" htmlFor="contactEmail">
              Main contact email
            </label>
            <div className="max-w-lg mt-2">
              <Controller
                name="mainContact.email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                }}
                render={({
                  fieldState: { error },
                  field: { onChange, value },
                }) => (
                  <TextField
                    readOnly={readonly}
                    id="contactEmail"
                    placeholder="ex: joe@jacquemus.com"
                    value={value}
                    onChange={onChange}
                    hasError={!!error}
                  />
                )}
              />
              {errors.mainContact?.email?.message && (
                <p className="text-xs italic text-primaryRed">
                  {`${errors.mainContact.email?.message}`}
                </p>
              )}
            </div>
          </div>

          <div>
            <label className="text-sm font-mediume" htmlFor="contactPhone">
              Main contact phone number (optional)
            </label>
            <div className="max-w-sm mt-2">
              <Controller
                name="mainContact.phoneNumber"
                control={control}
                rules={{
                  validate: (value) => {
                    const valid = value ? isPhoneValid(value) : true;
                    return valid ? undefined : "Phone number is invalid";
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneNumberInput
                    readonly={readonly}
                    id="contactPhone"
                    placeholder="ex: +33 6 01 02 03 04"
                    value={value || undefined}
                    onChange={onChange}
                  />
                )}
              />
              {errors.mainContact?.phoneNumber?.message && (
                <p className="text-xs italic text-primaryRed">
                  {`${errors.mainContact.phoneNumber?.message}`}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {onSubmitError && <div className="text-primaryRed">{onSubmitError}</div>}
      {children}
    </form>
  );
}

export default OrganizationInformationForm;
