import React from "react";

import { Navigate } from "react-router-dom";

import useApplicationContext from "@services/application/useApplicationContext";

export default function OnboardingDone({
  children,
}: Readonly<{
  children: React.JSX.Element;
}>) {
  const { organization } = useApplicationContext();

  if (!organization) {
    console.log("no organization");
    return <Navigate to="/onboarding/create-organization" />;
  }

  if (organization.onboardingStatus !== "DONE") {
    if (organization.onboardingStatus === "UPLOADING_CRM") {
      // depending on where the user is in the onboarding process, we redirect him to the right place
      return <Navigate to="/onboarding/upload-crm" />;
    }
    if (organization.onboardingStatus === "CREATING_SHOWROOM") {
      return <Navigate to="/onboarding/create-showroom" />;
    }
    return <Navigate to="/onboarding/information" />;
  }

  return children;
}
